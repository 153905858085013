import { StateModel } from '../../models/auxiliary/state.model';
import {
  createPosDeviceSuccessAction,
  deletePosDeviceSuccessAction,
  getPosDevicesAction,
  getPosDevicesErrorAction,
  getPosDevicesSuccessAction,
  unpairPosDeviceSuccessAction,
} from './pos-devices.actions';
import { PosDeviceTo } from '../../models/api/pos-devices.model';
import { createReducer, on } from '@ngrx/store';

export type PosDevicesState = StateModel<PosDeviceTo[]>;

const INITIAL_STATE: PosDevicesState = {
  errors: null,
  data: null,
  loading: false,
  initial: true,
};

export const posDevicesReducer = createReducer(
  INITIAL_STATE,
  on(getPosDevicesAction, (state) => ({
    ...state,
    loading: true,
    initial: false,
  })),
  on(getPosDevicesSuccessAction, (state, { posDevices }) => ({
    ...state,
    data: posDevices,
    loading: false,
  })),
  on(getPosDevicesErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
  on(unpairPosDeviceSuccessAction, deletePosDeviceSuccessAction, createPosDeviceSuccessAction, () => ({
    ...INITIAL_STATE,
  }))
);
