/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { UserActivationModel } from '../models/api/user-activation.model';
import { ErrorModel } from '../models/api/error.model';

export enum UserActivationActionTypes {
  GetUserActivationStatus = 'userActivation/GET_USER_ACTIVATION_STATUS_REQUEST',
  GetUserActivationStatusSuccess = 'userActivation/GET_USER_ACTIVATION_STATUS_SUCCESS',
  GetUserActivationStatusError = 'userActivation/GET_USER_ACTIVATION_STATUS_ERROR',

  ActivateUserWithPassword = 'userActivation/POST_ACTIVATE_USER_WITH_PASSWORD_REQUEST',
  ActivateUserWithPasswordSuccess = 'userActivation/POST_ACTIVATE_USER_WITH_PASSWORD_SUCCESS',
  ActivateUserWithPasswordError = 'userActivation/POST_ACTIVATE_USER_WITH_PASSWORD_ERROR',
}

export const getUserActivationStatusAction = createAction(
  UserActivationActionTypes.GetUserActivationStatus,
  props<{ hash: string }>()
);

export const getUserActivationStatusSuccessAction = createAction(
  UserActivationActionTypes.GetUserActivationStatusSuccess,
  props<{ userActivation: UserActivationModel }>()
);

export const getUserActivationStatusErrorAction = createAction(
  UserActivationActionTypes.GetUserActivationStatusError,
  props<{ errors: ErrorModel[] }>()
);

export const activateUserWithPasswordAction = createAction(
  UserActivationActionTypes.ActivateUserWithPassword,
  props<{ hash: string; password: string }>()
);

export const activateUserWithPasswordSuccessAction = createAction(
  UserActivationActionTypes.ActivateUserWithPasswordSuccess,
  props<{ userActivation: UserActivationModel }>()
);

export const activateUserWithPasswordErrorAction = createAction(
  UserActivationActionTypes.ActivateUserWithPasswordError,
  props<{ errors: ErrorModel[] }>()
);
