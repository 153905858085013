import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { ApiService } from '../services/api.service';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  getMerchantPopupFormAction,
  getMerchantPopupFormErrorAction,
  getMerchantPopupFormSuccessAction,
  saveBusinessInfoAction,
  saveBusinessInfoErrorAction,
  saveBusinessInfoSuccessAction,
  startMerchantVerificationAdminAction,
  startMerchantVerificationAdminErrorAction,
  startMerchantVerificationAdminSuccessAction,
  updateMerchantProfileAdminAction,
  updateMerchantProfileAdminErrorAction,
  updateMerchantProfileAdminSuccessAction,
} from '../actions/merchant.actions';

export const MERCHANT_BUSINESS_INFO_ENDPOINT = `/merchant/business-info`;
export const MERCHANT_UPDATE_PROFILE_ADMIN_ENDPOINT = `/admin/merchants/merchant-profile`;
export const START_MERCHANT_VERIFICATION_ADMIN_ENDPOINT = `/admin/verification/start/{merchantId}`;

@Injectable()
export class MerchantEffects {
  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveBusinessInfoAction),
      mergeMap(({ businessInfoModel }) =>
        this.api.put(MERCHANT_BUSINESS_INFO_ENDPOINT, businessInfoModel).pipe(
          map((businessInfoModel) => saveBusinessInfoSuccessAction({ businessInfoModel })),
          catchError((errors) => observableOf(saveBusinessInfoErrorAction(errors)))
        )
      )
    )
  );

  updateMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantProfileAdminAction),
      mergeMap(({ merchantProfile }) =>
        this.api.put(MERCHANT_UPDATE_PROFILE_ADMIN_ENDPOINT, merchantProfile).pipe(
          map((merchantProfile) => updateMerchantProfileAdminSuccessAction({ merchantProfile })),
          catchError((errors) => observableOf(updateMerchantProfileAdminErrorAction(errors)))
        )
      )
    )
  );

  startMerchantVerificationAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startMerchantVerificationAdminAction),
      mergeMap(({ merchantHashId, merchantProfileType }) =>
        this.api
          .post(START_MERCHANT_VERIFICATION_ADMIN_ENDPOINT.replace('{merchantId}', merchantHashId), {
            profileType: merchantProfileType,
          })
          .pipe(
            map(() => startMerchantVerificationAdminSuccessAction()),
            catchError((errors) => observableOf(startMerchantVerificationAdminErrorAction(errors)))
          )
      )
    )
  );

  getMerchantPopupForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantPopupFormAction),
      mergeMap(({ hashId }) =>
        this.api.get(`/merchants/${hashId}/popup-form`).pipe(
          map((popupFormFilled) => getMerchantPopupFormSuccessAction({ popupFormFilled })),
          catchError((errors) => observableOf(getMerchantPopupFormErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
