/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { CryptoWalletCreateTo, CryptoWalletSummaryTo, CryptoWalletUpdateTo } from '../models/api/crypto-wallets.model';

export enum cryptoWalletsActions {
  GET_REQUEST = 'cryptoWallets/GET_REQUEST',
  GET_SUCCESS = 'cryptoWallets/GET_SUCCESS',
  GET_ERROR = 'cryptoWallets/GET_ERROR',
  PATCH_REQUEST = 'cryptoWallets/PATCH_REQUEST',
  PATCH_SUCCESS = 'cryptoWallets/PATCH_SUCCESS',
  PATCH_ERROR = 'cryptoWallets/PATCH_ERROR',
  CREATE_CRYPTO_WALLET_REQUEST = 'cryptoWallets/CREATE_CRYPTO_WALLET_REQUEST',
  CREATE_CRYPTO_WALLET_SUCCESS = 'cryptoWallets/CREATE_CRYPTO_WALLET_SUCCESS',
  CREATE_CRYPTO_WALLET_ERROR = 'cryptoWallets/CREATE_CRYPTO_WALLET_ERROR',
}

export const getCryptoWalletsAction = createAction(cryptoWalletsActions.GET_REQUEST);

export const getCryptoWalletsSuccessAction = createAction(
  cryptoWalletsActions.GET_SUCCESS,
  props<{ wallets: CryptoWalletSummaryTo[] }>()
);

export const getCryptoWalletsErrorAction = createAction(
  cryptoWalletsActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateCryptoWalletAction = createAction(
  cryptoWalletsActions.PATCH_REQUEST,
  props<{ walletId: number; wallet: CryptoWalletUpdateTo }>()
);

export const updateCryptoWalletSuccessAction = createAction(
  cryptoWalletsActions.PATCH_SUCCESS,
  props<{ wallet: CryptoWalletSummaryTo }>()
);

export const updateCryptoWalletErrorAction = createAction(
  cryptoWalletsActions.PATCH_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createCryptoWalletAction = createAction(
  cryptoWalletsActions.CREATE_CRYPTO_WALLET_REQUEST,
  props<{ wallet: CryptoWalletCreateTo }>()
);

export const createCryptoWalletSuccessAction = createAction(
  cryptoWalletsActions.CREATE_CRYPTO_WALLET_SUCCESS,
  props<{ wallet: CryptoWalletSummaryTo }>()
);

export const createCryptoWalletErrorAction = createAction(
  cryptoWalletsActions.CREATE_CRYPTO_WALLET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
