import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService, get2FaHeaders } from '../services/api.service';
import { API_KEYS_ENDPOINT } from './api-key-list.effect';
import {
  createApiKeyAction,
  createApiKeyErrorAction,
  createApiKeySuccessAction,
  deleteApiKeyAction,
  deleteApiKeyErrorAction,
  deleteApiKeySuccessAction,
  getApiKeyAction,
  getApiKeyErrorAction,
  getApiKeySuccessAction,
  updateApiKeyAction,
  updateApiKeyErrorAction,
  updateApiKeySuccessAction,
} from '../actions/api-key-detail.actions';

export const API_KEY_DETAIL_ENDPOINT = '/merchants/{merchantHashId}/api-keys/{apiKeyHashId}';

@Injectable()
export class ApiKeyDetailEffect {
  getApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getApiKeyAction),
      mergeMap(({ keyId, merchantHashId }) =>
        this.api
          .get(API_KEY_DETAIL_ENDPOINT.replace('{merchantHashId}', merchantHashId).replace('{apiKeyHashId}', keyId))
          .pipe(
            map((apiKey) => getApiKeySuccessAction({ apiKey })),
            catchError((errors) => of(getApiKeyErrorAction(errors)))
          )
      )
    )
  );

  createApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createApiKeyAction),
      mergeMap(({ key, merchantHashId, twoFACode }) =>
        this.api
          .post(
            API_KEYS_ENDPOINT.replace('{merchantHashId}', merchantHashId),
            {
              name: key.name,
              payoutPermissions: key.payoutPermissions,
              ipAddressRanges: key.ipAddressRanges,
            },
            get2FaHeaders(twoFACode)
          )
          .pipe(
            map((apiKey) => createApiKeySuccessAction({ apiKey })),
            catchError((errors) => of(createApiKeyErrorAction(errors)))
          )
      )
    )
  );

  updateApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApiKeyAction),
      mergeMap(({ key, merchantHashId, twoFACode }) =>
        this.api
          .put(
            API_KEY_DETAIL_ENDPOINT.replace('{merchantHashId}', merchantHashId).replace('{apiKeyHashId}', key.hash_id),
            {
              name: key.name,
              payoutPermissions: key.payoutPermissions,
              ipAddressRanges: key.ipAddressRanges,
            },
            get2FaHeaders(twoFACode)
          )
          .pipe(
            map((apiKey) => updateApiKeySuccessAction({ apiKey })),
            catchError((errors) => of(updateApiKeyErrorAction(errors)))
          )
      )
    )
  );

  deleteApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteApiKeyAction),
      mergeMap(({ id, merchantHashId, twoFACode }) =>
        this.api
          .delete(`${API_KEYS_ENDPOINT.replace('{merchantHashId}', merchantHashId)}/${id}`, get2FaHeaders(twoFACode))
          .pipe(
            map(() => deleteApiKeySuccessAction()),
            catchError((errors) => of(deleteApiKeyErrorAction(errors)))
          )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
