import { StateModel } from '../models/auxiliary/state.model';
import { InvoiceModel } from '../models/api/invoice.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { createReducer, on } from '@ngrx/store';
import {
  clearPaymentButtonInvoicesAction,
  getEmailInvoiceInvoicesErrorAction,
  getEmailInvoiceInvoicesSuccessAction,
  getInvoicesErrorAction,
  getInvoicesSuccessAction,
  getPaymentButtonInvoicesAction,
  getPaymentButtonInvoicesErrorAction,
  getPaymentButtonInvoicesSuccessAction,
  getSelectedInvoicesErrorAction,
  getSelectedInvoicesSuccessAction,
  getSubscriptionInvoicesAction,
  getSubscriptionInvoicesErrorAction,
  getSubscriptionInvoicesSuccessAction,
} from '../actions/invoice-page.actions';

export type InvoicePageState = StateModel<PagingModel<InvoiceModel>>;

export interface GetEmailInvoiceInvoicesActionModel {
  isAdmin: boolean;
  emailInvoiceId: string;
}

const INITIAL_STATE: InvoicePageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
};

export const invoicePageReducer = createReducer(
  INITIAL_STATE,
  on(clearPaymentButtonInvoicesAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getPaymentButtonInvoicesAction, (state) => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(getSubscriptionInvoicesAction, (state) => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(
    getInvoicesSuccessAction,
    getSelectedInvoicesSuccessAction,
    getEmailInvoiceInvoicesSuccessAction,
    getPaymentButtonInvoicesSuccessAction,
    getSubscriptionInvoicesSuccessAction,
    (state, { invoices }) => ({
      errors: null,
      data: invoices,
      loading: false,
    })
  ),
  on(
    getInvoicesErrorAction,
    getSelectedInvoicesErrorAction,
    getEmailInvoiceInvoicesErrorAction,
    getPaymentButtonInvoicesErrorAction,
    getSubscriptionInvoicesErrorAction,
    (state, { errors }) => ({
      errors: errors,
      loading: false,
    })
  )
);
