import {
  createMerchantEmailInvoiceAction,
  emailInvoiceErrorAction,
  emailInvoiceSuccessAction,
  getEmailInvoiceAction,
} from '../actions/email-invoice.actions';
import { EmailInvoiceModel } from '../models/api/email-invoice.model';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';

export type EmailInvoiceState = StateModel<EmailInvoiceModel>;

const INITIAL_STATE: EmailInvoiceState = {
  errors: null,
  data: null,
  loading: false,
};

export const emailInvoiceReducer = createReducer(
  INITIAL_STATE,
  on(getEmailInvoiceAction, createMerchantEmailInvoiceAction, (state) => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(emailInvoiceSuccessAction, (state, { emailInvoice }) => ({
    ...state,
    errors: null,
    data: emailInvoice,
    loading: false,
  })),
  on(emailInvoiceErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
