<form [formGroup]="personalAccountForm" (ngSubmit)="updatePersonalAccount()">
  <div class="heading-container">
    <ng-container *ngIf="!isAdmin">
      <h2 class="bp-subheading-new merchant-verification-heading">Personal information</h2>
    </ng-container>
  </div>
  <div class="container margin-bottom">
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'firstName', placeholder: 'Name', autocomplete: 'given-name' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'lastName', placeholder: 'Surname', autocomplete: 'family-name' }
        "
      ></ng-container>
    </div>
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="
          formFieldDate;
          context: { controlName: 'birthDate', placeholder: 'Date of birth', autocomplete: 'bday' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="formFieldInput; context: { controlName: 'birthPlace', placeholder: 'Place of birth' }"
      ></ng-container>
    </div>
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="
          formFieldSelectCountries;
          context: { controlName: 'nationalityCountryCode', placeholder: 'Nationality', countries: countries }
        "
      ></ng-container>
    </div>
  </div>
  <div class="heading-container">
    <h2 class="bp-subheading-new merchant-verification-heading">Business Address</h2>
  </div>
  <div class="container margin-bottom">
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'street', placeholder: 'Street', grow: true, autocomplete: 'street-address' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'city', placeholder: 'City', grow: true, autocomplete: 'address-level2' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'zip', placeholder: 'ZIP', autocomplete: 'postal-code' }
        "
      ></ng-container>
    </div>
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="
          formFieldSelectCountries;
          context: { controlName: 'countryCode', placeholder: 'Country', countries: countries }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'phone', placeholder: 'Mobile number', phone: true, autocomplete: 'tel' }
        "
      ></ng-container>
    </div>
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="formFieldInput; context: { controlName: 'businessId', placeholder: 'Business identifier' }"
      ></ng-container>
    </div>
  </div>
  <div class="margin-left">
    <button
      *ngIf="!isAdmin"
      class="confirmo-button confirmo-button__blue merchant-verification-button"
      type="submit"
      [disabled]="personalAccountForm.invalid"
    >
      Continue
    </button>
  </div>

  <ng-template
    #formFieldInput
    let-controlName="controlName"
    let-placeholder="placeholder"
    let-phone="phone"
    let-grow="grow"
    let-autocomplete="autocomplete"
  >
    <div class="text-input-group nopadding" [ngClass]="grow ? 'col-wider' : 'col'">
      <label class="label-text">
        {{ placeholder }}
      </label>
      <div class="verification-form-input-tooltip-container">
        <input
          [formControlName]="controlName"
          class="confirmo-platform-input verification-input"
          type="text"
          [placeholder]="placeholder"
          [autocomplete]="autocomplete"
        />
        <div
          class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
          *ngIf="personalAccountFieldMissing(controlName)"
        >
          Required
        </div>
        <div class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip" *ngIf="phone && phoneInvalid">
          Invalid format
        </div>
        <div
          class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
          *ngIf="containsWhitespaceAtStartOrEnd(controlName)"
        >
          Can't contain whitespace at the start or the end
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #formFieldSelectCountries
    let-controlName="controlName"
    let-placeholder="placeholder"
    let-countries="countries"
  >
    <div class="text-input-group col nopadding">
      <label class="label-text">
        {{ placeholder }}
      </label>
      <div class="verification-form-input-tooltip-container">
        <div class="verification-input">
          <bp-country-select
            [countries]="countries"
            *ngIf="controlName === 'nationalityCountryCode'"
            [(selected)]="selectedNationality"
            (selectedChange)="onSelectChange($event, controlName)"
            [normalFontWeight]="true"
            errorMessage="Required"
          ></bp-country-select>
          <bp-country-select
            [countries]="countries"
            *ngIf="controlName === 'countryCode'"
            [(selected)]="selectedCountryCode"
            (selectedChange)="onSelectChange($event, controlName)"
            [normalFontWeight]="true"
            errorMessage="Required"
          ></bp-country-select>
        </div>
        <div class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip" *ngIf="countryNotSupported">
          Country not supported
        </div>
        <div
          class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
          *ngIf="personalAccountFieldMissing(controlName)"
        >
          Required
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #formFieldDate let-controlName="controlName" let-placeholder="placeholder">
    <div class="text-input-group col nopadding">
      <label class="label-text">
        {{ placeholder }}
      </label>
      <div class="verification-form-input-tooltip-container">
        <input
          [formControlName]="controlName"
          [max]="minimalBirthDate | bpMomentDate : 'YYYY-MM-DD'"
          type="date"
          class="confirmo-platform-input verification-input"
        />
        <ng-container *ngIf="birthDate.invalid && (birthDate.dirty || birthDate.touched)">
          <div
            class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
            *ngIf="birthDate.errors?.required"
          >
            Date of birth is required
          </div>
          <div
            class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
            *ngIf="birthDate.errors?.maxDate"
          >
            Maximum date is {{ minimalBirthDate | bpMomentDate }}
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</form>
