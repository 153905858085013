import { StateModel } from '../models/auxiliary/state.model';
import { CallbackPasswordModel } from '../models/api/callback-password.model';
import { createReducer, on } from '@ngrx/store';
import {
  getCallbackPasswordAction,
  getCallbackPasswordErrorAction,
  getCallbackPasswordSuccessAction,
} from '../actions/callback-password.actions';

export type CallbackPasswordState = StateModel<CallbackPasswordModel>;

const INITIAL_STATE: CallbackPasswordState = {
  errors: null,
  data: null,
};

export const callbackPasswordReducer = createReducer(
  INITIAL_STATE,
  on(getCallbackPasswordAction, (state) => ({
    errors: null,
    data: null,
  })),
  on(getCallbackPasswordSuccessAction, (state, { callbackPassword }) => ({
    errors: null,
    data: callbackPassword,
  })),
  on(getCallbackPasswordErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
