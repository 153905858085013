export const GOOGLE_ANALYTICS_PROD_ID = 'G-Q6Q2H12C05';
export const GOOGLE_ANALYTICS_DEVTEST_ID = 'G-F3ZQYQLZMR';
export const SOLANA_MAINNET_RPC_URL =
  'https://capable-patient-dinghy.solana-mainnet.quiknode.pro/1e4e03b11b5cb4aebc8ed787f3869378ae15390b/';
export const SOLANA_DEVNET_RPC_URL = 'https://api.devnet.solana.com';
export const WALLET_CONNECT_PROJECT_ID = 'd20b504445b2ab74e3148edc179025a2';

export const WALLET_CONNECT_METADATA = {
  name: 'Confirmo',
  description: 'Confirmo Payment Gateway',
  url: 'https://confirmo.net',
  icons: ['https://confirmo.net/confirmofavicon.png'],
};

export const WALLET_CONNECT_THEME = {
  '--w3m-z-index': 1500,
  '--w3m-accent-color': '#8100ff',
};
