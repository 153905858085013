import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    @Inject(DOCUMENT) private dom,
    private title: Title,
    private meta: Meta,
    private appConfig: AppConfigService
  ) {}

  updateMetaTags(metaTags: MetaDefinition) {
    for (const metaTag in metaTags) {
      switch (metaTag) {
        case 'title':
          this.updateTitle(metaTags[metaTag]);
          break;
        case 'override':
          break;
        case 'description':
          this.updateAllMetaTags(metaTag, metaTags[metaTag], true);
          break;
        case 'author':
        case 'publisher':
          this.updateAllMetaTags(metaTag, metaTags[metaTag], false);
          break;
        case 'robots':
          this.updateAllMetaTags(metaTag, metaTags[metaTag], false);
          break;
        default:
          /**
           * Twitter meta tags use name instead of property
           */
          if (metaTag.startsWith('twitter:')) {
            this.meta.updateTag({ name: metaTag, content: metaTags[metaTag] });
          } else {
            this.meta.updateTag({ property: metaTag, content: metaTags[metaTag] });
          }
          break;
      }
    }
  }

  private updateAllMetaTags(name: string, value: string, updateTwitter: boolean): void {
    this.meta.updateTag({ name: name, content: value });
    this.meta.updateTag({ property: 'og:' + name, content: value });
    if (updateTwitter) {
      this.meta.updateTag({ name: 'twitter:' + name, content: value });
    }
  }

  private updateTitle(title: string): void {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }

  /**
   * Sets canonical link with specified url. If no url is provided, removes canonical link at all as unnecessary.
   * @param url
   */

  public updateCanonicalUrl(url?: string): void {
    if (url) {
      const head = this.dom.getElementsByTagName('head')[0];
      let link: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
      if (link == null) {
        link = this.dom.createElement('link') as HTMLLinkElement;
        head.appendChild(link);
      }
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url);
    } else {
      const link: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
      if (link) {
        link.remove();
      }
    }
  }

  showLiveChat(): void {
    // temporarily hidden: CBP-3804
    // this.toggleLiveChat(true);
  }

  hideLiveChat(): void {
    this.toggleLiveChat(false);
  }

  private toggleLiveChat(show: boolean): void {
    if (this.appConfig.config.profile !== 'local') {
      const scriptCode =
        '!function(){function t(t,e){return function(){window.dashlyasync.push(t,arguments)}}{var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.dashly.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.dashly={},window.dashlyasync=[],dashly.settings={};for(var n=["connect","track","identify","auth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)dashly[n[a]]=t(n[a])}}(),dashly.connect("5164-aeab1ca0877a7611fb105b580c6")';
      const head = this.dom.getElementsByTagName('head')[0];
      if (show) {
        if (this.dashlyScripts().length === 0) {
          const script = this.dom.createElement('script');
          script.text = scriptCode;
          head.appendChild(script);
        }
      } else {
        this.dashlyScripts().forEach((script) => script.parentNode.removeChild(script));
        const iframe = this.dom.getElementsByClassName('carrotquest-css-reset')[0];
        iframe?.parentNode.removeChild(iframe);
      }
    }
  }

  private dashlyScripts(): any[] {
    const scripts = [...this.dom.getElementsByTagName('script')];
    return [...scripts.filter((script) => script.text.includes('dashly') || script.src.includes('dashly'))];
  }
}
