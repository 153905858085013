import { StateModel } from '../models/auxiliary/state.model';
import { WebhookInfoModel } from '../models/api/webhook-info.model';
import { createReducer, on } from '@ngrx/store';
import {
  clearWebhookAction,
  getWebhookInfoListAction,
  getWebhookInfoListErrorAction,
  getWebhookInfoListSuccessAction,
} from '../actions/webhook-info-list.actions';

export type WebhookInfoListState = StateModel<WebhookInfoModel[]>;

const INITIAL_STATE: WebhookInfoListState = {
  errors: null,
  data: [],
};

export const webhookInfoListReducer = createReducer(
  INITIAL_STATE,
  on(getWebhookInfoListAction, clearWebhookAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getWebhookInfoListSuccessAction, (state, { webhooks }) => ({
    errors: null,
    data: webhooks,
  })),
  on(getWebhookInfoListErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
