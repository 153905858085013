import { StateModel } from '../models/auxiliary/state.model';
import { TwoFAModel } from '../models/api/two-fa.model';
import { createReducer, on } from '@ngrx/store';
import {
  clearTwoFAAction,
  googleRegisterTwoFAAction,
  googleRegisterTwoFAErrorAction,
  googleRegisterTwoFASuccessAction,
} from '../actions/two-factor-auth.actions';

export type TwoFactorAuthState = StateModel<TwoFAModel>;

const INITIAL_STATE: TwoFactorAuthState = {
  errors: null,
  data: null,
};

export const twoFactorAuthReducer = createReducer(
  INITIAL_STATE,
  on(googleRegisterTwoFAAction, (state) => ({
    errors: null,
    data: null,
  })),
  on(googleRegisterTwoFASuccessAction, (state, { twoFACode }) => ({
    errors: null,
    data: twoFACode,
  })),
  on(googleRegisterTwoFAErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
  on(clearTwoFAAction, (state) => ({
    ...INITIAL_STATE,
  }))
);
