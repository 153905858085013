import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { ApiService } from '../services/api.service';
import {
  getCustomerEmailAction,
  getCustomerEmailErrorAction,
  getCustomerEmailSuccessAction,
  postCustomerEmailAction,
  postCustomerEmailErrorAction,
  postCustomerEmailSuccessAction,
} from '../actions/customer-email.actions';

@Injectable()
export class CustomerEmailEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCustomerEmailAction),
      mergeMap(({ hashId, refundToken }) =>
        this.api.get(`/invoice/${hashId}/customer-email/is-settable?refundToken=${refundToken}`).pipe(
          map((customerEmailResponse) => getCustomerEmailSuccessAction({ customerEmailResponse })),
          catchError((errors) => observableOf(getCustomerEmailErrorAction(errors)))
        )
      )
    )
  );

  post$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postCustomerEmailAction),
      switchMap(({ hashId, customerEmailBody }) =>
        this.api.post(`/invoice/${hashId}/customer-email`, customerEmailBody).pipe(
          map(() => postCustomerEmailSuccessAction()),
          catchError((errors) => observableOf(postCustomerEmailErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
