import { createReducer, on } from '@ngrx/store';
import {
  getFiatPaymentMethodsAction,
  getFiatPaymentMethodsErrorAction,
  getFiatPaymentMethodsSuccessAction,
} from './fiat-payment-methods.actions';
import { StateModel } from '../../models/auxiliary/state.model';
import { FiatPaymentMethodModel } from '../../models/api/fiat-payment-method.model';

export type FiatPaymentMethodsState = StateModel<FiatPaymentMethodModel[]>;

const INITIAL_STATE: FiatPaymentMethodsState = {
  errors: null,
  data: null,
  initial: true,
};

export const fiatPaymentMethodsReducer = createReducer(
  INITIAL_STATE,
  on(getFiatPaymentMethodsAction, () => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(getFiatPaymentMethodsSuccessAction, (state, { paymentMethods }) => ({
    data: paymentMethods,
    errors: null,
  })),
  on(getFiatPaymentMethodsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
