/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { UserModel } from '../models/api/user.model';

export enum userActions {
  GET_REQUEST = 'user/GET_REQUEST',
  GET_REQUEST_CACHED = 'user/GET_REQUEST_CACHED',
  GET_SUCCESS = 'user/GET_SUCCESS',
  GET_ERROR = 'user/GET_ERROR',
}

export const getUserAction = createAction(userActions.GET_REQUEST);
export const getUserCachedAction = createAction(userActions.GET_REQUEST_CACHED);
export const getUserSuccessAction = createAction(userActions.GET_SUCCESS, props<{ user: UserModel }>());
export const getUserErrorAction = createAction(userActions.GET_ERROR, props<{ errors: ErrorModel[] }>());
