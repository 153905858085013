import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { RefundCreationTo } from '../../models/api/refund-creation.model';
import { RefundTo } from '../../models/api/refund.model';

enum RefundActionTypes {
  CREATE_REFUND_PUBLIC_REQUEST = 'refund/CREATE_REFUND_PUBLIC_REQUEST',
  CREATE_REFUND_PUBLIC_SUCCESS = 'refund/CREATE_REFUND_PUBLIC_SUCCESS',
  CREATE_REFUND_PUBLIC_ERROR = 'refund/CREATE_REFUND_PUBLIC_ERROR',

  CREATE_REFUND_MERCHANT_REQUEST = 'refund/CREATE_REFUND_MERCHANT_REQUEST',
  CREATE_REFUND_MERCHANT_SUCCESS = 'refund/CREATE_REFUND_MERCHANT_SUCCESS',
  CREATE_REFUND_MERCHANT_ERROR = 'refund/CREATE_REFUND_MERCHANT_ERROR',

  CREATE_REFUND_ADMIN_REQUEST = 'refund/CREATE_REFUND_ADMIN_REQUEST',
  CREATE_REFUND_ADMIN_SUCCESS = 'refund/CREATE_REFUND_ADMIN_SUCCESS',
  CREATE_REFUND_ADMIN_ERROR = 'refund/CREATE_REFUND_ADMIN_ERROR',

  MARK_REFUND_AS_REFUNDED_REQUEST = 'refund/MARK_REFUND_AS_REFUNDED_REQUEST',
  MARK_REFUND_AS_REFUNDED_SUCCESS = 'refund/MARK_REFUND_AS_REFUNDED_SUCCESS',
  MARK_REFUND_AS_REFUNDED_ERROR = 'refund/MARK_REFUND_AS_REFUNDED_ERROR',

  SEND_REQUEST = 'refund/SEND_REQUEST',
  SEND_SUCCESS = 'refund/SEND_SUCCESS',
  SEND_ERROR = 'refund/SEND_ERROR',

  UPDATE_CRYPTO_ADDRESS_REQUEST = 'refund/UPDATE_CRYPTO_ADDRESS_REQUEST',
  UPDATE_CRYPTO_ADDRESS_SUCCESS = 'refund/UPDATE_CRYPTO_ADDRESS_SUCCESS',
  UPDATE_CRYPTO_ADDRESS_ERROR = 'refund/UPDATE_CRYPTO_ADDRESS_ERROR',
}

export const createRefundPublicAction = createAction(
  RefundActionTypes.CREATE_REFUND_PUBLIC_REQUEST,
  props<{ id: string; body: RefundCreationTo }>()
);

export const createRefundPublicSuccessAction = createAction(RefundActionTypes.CREATE_REFUND_PUBLIC_SUCCESS);

export const createRefundPublicErrorAction = createAction(
  RefundActionTypes.CREATE_REFUND_PUBLIC_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createRefundMerchantAction = createAction(
  RefundActionTypes.CREATE_REFUND_MERCHANT_REQUEST,
  props<{ invoiceId: string; body: RefundCreationTo; twoFaToken: string }>()
);

export const createRefundMerchantSuccessAction = createAction(
  RefundActionTypes.CREATE_REFUND_MERCHANT_SUCCESS,
  props<{ refund: RefundTo }>()
);

export const createRefundMerchantErrorAction = createAction(
  RefundActionTypes.CREATE_REFUND_MERCHANT_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createRefundAdminAction = createAction(
  RefundActionTypes.CREATE_REFUND_ADMIN_REQUEST,
  props<{ invoiceId: string; body: RefundCreationTo }>()
);

export const createRefundAdminSuccessAction = createAction(
  RefundActionTypes.CREATE_REFUND_ADMIN_SUCCESS,
  props<{ refund: RefundTo }>()
);

export const createRefundAdminErrorAction = createAction(
  RefundActionTypes.CREATE_REFUND_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const markRefundAsRefundedAction = createAction(
  RefundActionTypes.MARK_REFUND_AS_REFUNDED_REQUEST,
  props<{ id: string; body: { txid: string } }>()
);

export const markRefundAsRefundedSuccessAction = createAction(RefundActionTypes.MARK_REFUND_AS_REFUNDED_SUCCESS);

export const markRefundAsRefundedErrorAction = createAction(
  RefundActionTypes.MARK_REFUND_AS_REFUNDED_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const sendRefundsAction = createAction(RefundActionTypes.SEND_REQUEST, props<{ refundsIds: string[] }>());

export const sendRefundsSuccessAction = createAction(RefundActionTypes.SEND_SUCCESS, props<{ refundsIds: string[] }>());

export const sendRefundsErrorAction = createAction(RefundActionTypes.SEND_ERROR, props<{ errors: ErrorModel[] }>());

export const updateRefundCryptoAddressAction = createAction(
  RefundActionTypes.UPDATE_CRYPTO_ADDRESS_REQUEST,
  props<{ newCryptoAddress: string; refundHashId: string }>()
);

export const updateRefundCryptoAddressSuccessAction = createAction(
  RefundActionTypes.UPDATE_CRYPTO_ADDRESS_SUCCESS,
  props<{ refund: RefundTo }>()
);

export const updateRefundCryptoAddressErrorAction = createAction(
  RefundActionTypes.UPDATE_CRYPTO_ADDRESS_ERROR,
  props<{ errors: ErrorModel[] }>()
);
