import { StateModel } from '../models/auxiliary/state.model';
import {
  adminUploadMerchantVerificationDocumentsAction,
  adminUploadMerchantVerificationDocumentsErrorAction,
  adminUploadMerchantVerificationDocumentsSuccessAction,
  getMerchantVerificationDocumentsAction,
  getMerchantVerificationDocumentsErrorAction,
  getMerchantVerificationDocumentsSuccessAction,
  updateMerchantVerificationDocumentAction,
} from '../actions/merchant-verification-documents.actions';
import { MerchantDocument } from '../models/api/merchant-profile/merchant-document.model';
import { createReducer, on } from '@ngrx/store';

export type MerchantVerificationDocumentsState = StateModel<MerchantDocument[]>;

const INITIAL_STATE: MerchantVerificationDocumentsState = {
  data: null,
  errors: null,
  loading: false,
};

export const merchantVerificationDocumentsReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantVerificationDocumentsAction, () => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(adminUploadMerchantVerificationDocumentsAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(getMerchantVerificationDocumentsSuccessAction, (state, { documents }) => ({
    ...state,
    data: documents,
    loading: false,
  })),
  on(adminUploadMerchantVerificationDocumentsSuccessAction, (state, { documents }) => ({
    ...state,
    data: appendDocumentToArray(state.data, documents),
    loading: false,
  })),
  on(
    getMerchantVerificationDocumentsErrorAction,
    adminUploadMerchantVerificationDocumentsErrorAction,
    (state, { errors }) => ({
      ...state,
      errors: errors,
      loading: false,
    })
  ),
  on(updateMerchantVerificationDocumentAction, (state, { file }) => ({
    ...state,
    data: replaceDocumentInArray(state.data, file),
  }))
);

function replaceDocumentInArray(array: MerchantDocument[], document: MerchantDocument): MerchantDocument[] {
  const index = array.findIndex((arrayDoc) => arrayDoc.id === document.id);

  if (index === -1) {
    return array;
  }

  const newArray = array.slice();
  newArray[index] = document;

  return newArray;
}

function appendDocumentToArray(array: MerchantDocument[], document: MerchantDocument[]): MerchantDocument[] {
  if (!array) {
    return document;
  }

  array.unshift(...document);
  return array;
}
