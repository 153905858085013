import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import { ADMIN_ENDPOINT_PREFIX, API_VERSION } from '../../constants';
import {
  getLedgerErrorAction,
  getLedgerForSettlementAction,
  getLedgerSuccessAction,
} from '../../actions/ledger.actions';
import {
  getPdfSettlementAction,
  getPdfSettlementErrorAction,
  getPdfSettlementSuccessAction,
} from '../../actions/pdf-settlement.action';
import { getSettlementAction, getSettlementErrorAction, getSettlementSuccessAction } from './settlement.actions';
import { QueryParams, queryParams } from '../../utils';

export const SETTLEMENT_ENDPOINT = '/settlements';

@Injectable()
export class SettlementEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSettlementAction),
      mergeMap(({ settlementId, isAdmin }) => {
        return this.api.get(this.url(settlementId, isAdmin)).pipe(
          map((settlement) => getSettlementSuccessAction({ settlement })),
          catchError((errors) => observableOf(getSettlementErrorAction(errors)))
        );
      })
    )
  );

  getLedgerForSettlement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLedgerForSettlementAction),
      mergeMap(({ settlementId, paging }) =>
        this.api.get(this.urlGetLedgerForSettlement(settlementId, { ...paging })).pipe(
          map((ledger) => getLedgerSuccessAction({ ledger })),
          catchError((errors) => observableOf(getLedgerErrorAction(errors)))
        )
      )
    )
  );

  getPdfSettlement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPdfSettlementAction),
      mergeMap(({ settlementHashId }) =>
        this.api.downloadFile(this.urlGetPdfSettlement(settlementHashId)).pipe(
          map((pdfSettlementResponse) => getPdfSettlementSuccessAction({ pdfSettlementResponse })),
          catchError((errors) => observableOf(getPdfSettlementErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(settlementId: string, isAdmin: boolean): string {
    if (isAdmin) {
      return `/${ADMIN_ENDPOINT_PREFIX}${SETTLEMENT_ENDPOINT}/${settlementId}`;
    }
    return `/${API_VERSION}${SETTLEMENT_ENDPOINT}/${settlementId}`;
  }

  urlGetLedgerForSettlement(settlementId: string, params?: QueryParams): string {
    return `${SETTLEMENT_ENDPOINT}/${settlementId}/ledger${queryParams(params)}`;
  }

  urlGetPdfSettlement(settlementHashId: string): string {
    return `/${API_VERSION}${SETTLEMENT_ENDPOINT}/${settlementHashId}/pdf`;
  }
}
