import { StateModel } from '../models/auxiliary/state.model';
import { NotificationSettingsModel } from '../models/api/notification-settings.model';
import {
  getNotificationSettingsErrorAction,
  getNotificationSettingsSuccessAction,
  updateNotificationSettingsErrorAction,
  updateNotificationSettingsSuccessAction,
} from '../actions/notification-settings.actions';
import { createReducer, on } from '@ngrx/store';

export type NotificationSettingsState = StateModel<NotificationSettingsModel>;

const INITIAL_STATE: NotificationSettingsState = {
  data: null,
  errors: null,
};

export const notificationSettingsReducer = createReducer(
  INITIAL_STATE,
  on(
    getNotificationSettingsSuccessAction,
    updateNotificationSettingsSuccessAction,
    (state, { notificationSettings }) => ({
      ...state,
      errors: null,
      data: notificationSettings,
    })
  ),
  on(getNotificationSettingsErrorAction, updateNotificationSettingsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
