import { RoleFactoryService } from './role-factory.service';
import { AbstractRole } from './roles/abstract.role';
import { Observable, race } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppStateModel } from '../../models/auxiliary/app-state.model';
import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import {
  getUserAction,
  getUserCachedAction,
  getUserErrorAction,
  getUserSuccessAction,
} from '../../actions/user.actions';
import { invalidTokenUsedAction } from '../../actions/auth.actions';
import { selectAuth } from '../../selectors/auth.selector';
import { selectUser } from '../../selectors/user.selector';

@Injectable()
export class AuthService {
  constructor(
    protected roleFactory: RoleFactoryService,
    protected store: Store<AppStateModel>,
    private actions$: Actions
  ) {}

  getLoggedInUserRole(): Observable<AbstractRole> {
    this.store.dispatch(getUserCachedAction());

    return this.store.pipe(
      select(selectUser),
      filter((state) => state.data != null),
      map((state) => {
        const role = state.data.role;
        return this.roleFactory.getRole(role);
      })
    );
  }

  isUserAuthorized(): Observable<boolean> {
    return this.store.select(selectAuth).pipe(
      take(1),
      map((auth) => auth.errors === null && auth.data && auth.data.authorized)
    );
  }

  isUserAdmin(): Observable<boolean> {
    this.store.dispatch(getUserCachedAction());

    return this.store.select(selectUser).pipe(
      filter(({ data, errors }) => errors !== null || data != null),
      take(1),
      map(({ data, errors }) => errors === null && data && data.admin)
    );
  }

  /**
   * Checks if invalid token was used.
   * Dispatch test request and return result depending on response.
   */
  invalidTokenUsed(): Observable<boolean> {
    this.store.dispatch(getUserAction());

    return race(
      this.actions$.pipe(
        ofType(invalidTokenUsedAction),
        take(1),
        map(() => true)
      ),
      this.actions$.pipe(
        ofType(getUserSuccessAction, getUserErrorAction),
        take(1),
        map(() => false)
      )
    );
  }
}
