import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getMerchantApiVersionAction,
  getMerchantApiVersionActionError,
  getMerchantApiVersionActionSuccess,
} from '../actions/merchant-api-version.actions';

export const API_VERSION_ENDPOINT = '/invoice/merchant-api-version';

@Injectable()
export class MerchantApiVersionEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantApiVersionAction),
      mergeMap(() =>
        this.api.get(API_VERSION_ENDPOINT).pipe(
          map((version) => getMerchantApiVersionActionSuccess({ version })),
          catchError((errors) => of(getMerchantApiVersionActionError(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
