import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ADMIN_ENDPOINT_PREFIX, API_VERSION } from '../constants';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';
import { INVOICE_ENDPOINT, INVOICE_EXPORT_ENDPOINT } from './invoice.effect';
import {
  getEmailInvoiceInvoicesAction,
  getEmailInvoiceInvoicesErrorAction,
  getEmailInvoiceInvoicesSuccessAction,
  getInvoicesAction,
  getInvoicesExportAction,
  getInvoicesExportErrorAction,
  getInvoicesExportSuccessAction,
  getInvoicesErrorAction,
  getInvoicesSuccessAction,
  getPaymentButtonInvoicesAction,
  getPaymentButtonInvoicesErrorAction,
  getPaymentButtonInvoicesSuccessAction,
  getSelectedInvoicesAction,
  getSelectedInvoicesErrorAction,
  getSelectedInvoicesSuccessAction,
  invoicePageActions,
  getSubscriptionInvoicesAction,
  getSubscriptionInvoicesSuccessAction,
  getSubscriptionInvoicesErrorAction,
} from '../actions/invoice-page.actions';

export const EMAIL_INVOICE_INVOICES_ENDPOINT = '/email-invoices/{hashId}/invoices';
export const BUTTON_INVOICE_INVOICES_ENDPOINT = '/button-invoices/{buttonId}/invoices';
export const SUBSCRIPTION_INVOICE_INVOICES_ENDPOINT = '/subscriptions/{subscriptionId}/invoices';

@Injectable()
export class InvoicePageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInvoicesAction),
      mergeMap(({ pagination, filters, isAdmin, type }) => {
        return this.api.get(this.url({ ...pagination, ...filters }, isAdmin, type)).pipe(
          map((invoices) => getInvoicesSuccessAction({ invoices })),
          catchError((errors) => observableOf(getInvoicesErrorAction(errors)))
        );
      })
    )
  );

  getSelectedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelectedInvoicesAction),
      mergeMap(({ hashIds, isAdmin, type }) => {
        return this.api.get(this.url(hashIds, isAdmin, type)).pipe(
          map((invoices) => getSelectedInvoicesSuccessAction({ invoices })),
          catchError((errors) => observableOf(getSelectedInvoicesErrorAction(errors)))
        );
      })
    )
  );

  getInvoicesDownloadAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInvoicesExportAction),
      switchMap(({ pagination, filters, isAdmin, type, uuid }) => {
        return this.api.post(this.url({ ...pagination, ...filters, uuid: uuid }, isAdmin, type)).pipe(
          map(() => getInvoicesExportSuccessAction()),
          catchError((errors) => observableOf(getInvoicesExportErrorAction(errors)))
        );
      })
    )
  );

  getEmailInvoiceInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmailInvoiceInvoicesAction),
      mergeMap(({ emailInvoiceId, isAdmin }) =>
        this.api.get(this.invoicesByEmailInvoiceUrl(emailInvoiceId, isAdmin)).pipe(
          map((invoices) => getEmailInvoiceInvoicesSuccessAction({ invoices })),
          catchError((errors) => observableOf(getEmailInvoiceInvoicesErrorAction(errors)))
        )
      )
    )
  );

  getPaymentButtonInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentButtonInvoicesAction),
      mergeMap(({ paymentButtonId, pagination }) =>
        this.api.get(this.getPaymentButtonInvoicesUrl(paymentButtonId, pagination)).pipe(
          map((invoices) => getPaymentButtonInvoicesSuccessAction({ invoices })),
          catchError((errors) => observableOf(getPaymentButtonInvoicesErrorAction(errors)))
        )
      )
    )
  );

  getSubscriptionInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubscriptionInvoicesAction),
      mergeMap(({ subscriptionId, pagination }) =>
        this.api.get(this.getSubscriptionInvoicesUrl(subscriptionId, pagination)).pipe(
          map((invoices) => getSubscriptionInvoicesSuccessAction({ invoices })),
          catchError((errors) => observableOf(getSubscriptionInvoicesErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(params: any, isAdmin: boolean, action: string): string {
    if (isAdmin) {
      if (action === invoicePageActions.GET_REQUEST) {
        return `/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}${queryParams(params)}`;
      } else if (action === invoicePageActions.GET_SELECTED_REQUEST) {
        return `/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}/id-list${queryParams({ hashIds: params })}`;
      } else if (action === invoicePageActions.POST_EXPORT_REQUEST) {
        return `/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_EXPORT_ENDPOINT}${queryParams(params)}`;
      }
    } else {
      if (action === invoicePageActions.POST_EXPORT_REQUEST) {
        return `/${API_VERSION}/${INVOICE_EXPORT_ENDPOINT}${queryParams(params)}`;
      } else {
        return `/${API_VERSION}/${INVOICE_ENDPOINT}${queryParams(params)}`;
      }
    }
  }

  invoicesByEmailInvoiceUrl(emailInvoiceId: string, isAdmin: boolean): string {
    if (isAdmin) {
      return `${EMAIL_INVOICE_INVOICES_ENDPOINT.replace('{hashId}', emailInvoiceId)}`;
    }
    return `${EMAIL_INVOICE_INVOICES_ENDPOINT.replace('{hashId}', emailInvoiceId)}`;
  }

  getPaymentButtonInvoicesUrl(buttonId: string, params: any): string {
    return `${BUTTON_INVOICE_INVOICES_ENDPOINT.replace('{buttonId}', buttonId)}${queryParams(params)}`;
  }

  getSubscriptionInvoicesUrl(subscriptionId: string, params: any): string {
    return `${SUBSCRIPTION_INVOICE_INVOICES_ENDPOINT.replace('{subscriptionId}', subscriptionId)}${queryParams(
      params
    )}`;
  }
}
