import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getBalanceHistoryPageAction,
  getBalanceHistoryPageActionError,
  getBalanceHistoryPageActionSuccess,
} from '../actions/balance-history-page.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';

export const BALANCE_HISTORY_PAGE_ENDPOINT = 'balance-history';

@Injectable()
export class BalanceHistoryPageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBalanceHistoryPageAction),
      mergeMap(({ limit, offset }) =>
        this.api.get(this.url(limit, offset)).pipe(
          map((balanceHistoryList) => getBalanceHistoryPageActionSuccess({ balanceHistoryList })),
          catchError((errors) => of(getBalanceHistoryPageActionError(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(limit: number, offset: number): string {
    return `/${ADMIN_ENDPOINT_PREFIX}/${BALANCE_HISTORY_PAGE_ENDPOINT}${queryParams({
      limit: limit.toString(),
      offset: offset.toString(),
    })}`;
  }
}
