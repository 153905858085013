/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { CorrectionTransactionAdminTo } from '../models/api/correction-transaction-admin.model';
import { ErrorModel } from '../models/api/error.model';
import { CorrectionTransactionCategory } from '../enums/correction-transaction-category-type.enum';

export enum CorrectionTxActionTypes {
  GetCorrectionTxListForSettlement = 'correctionTx/GET_LIST_FOR_SETTLEMENT_REQUEST',
  GetCorrectionTxListForMerchant = 'correctionTx/GET_LIST_FOR_MERCHANT_REQUEST',

  GetCorrectionTxListForSettlementSuccess = 'correctionTx/GET_SUCCESS',
  GetCorrectionTxListForSettlementError = 'correctionTx/GET_ERROR',

  CreateCorrectionTxForMerchant = 'correctionTx/CREATE_FOR_MERCHANT_REQUEST',
  CreateCorrectionTxForMerchantSuccess = 'correctionTx/CREATE_FOR_MERCHANT_SUCCESS',
  CreateCorrectionTxForMerchantError = 'correctionTx/CREATE_FOR_MERCHANT_ERROR',
}

export const getCorrectionTxListForSettlementAction = createAction(
  CorrectionTxActionTypes.GetCorrectionTxListForSettlement,
  props<{ settlementId: string; admin: boolean }>()
);

export const getCorrectionTxListForMerchantAction = createAction(
  CorrectionTxActionTypes.GetCorrectionTxListForMerchant,
  props<{ merchantId: string }>()
);

export const getCorrectionTxListForSettlementSuccessAction = createAction(
  CorrectionTxActionTypes.GetCorrectionTxListForSettlementSuccess,
  props<{ correctionTxs: CorrectionTransactionAdminTo[] }>()
);

export const getCorrectionTxListForSettlementErrorAction = createAction(
  CorrectionTxActionTypes.GetCorrectionTxListForSettlementError,
  props<{ errors: ErrorModel[] }>()
);

export const createCorrectionTxForMerchantAction = createAction(
  CorrectionTxActionTypes.CreateCorrectionTxForMerchant,
  props<{
    correctionTx: { amount: string; currency: string; comment: string; category: CorrectionTransactionCategory };
    merchantId: string;
  }>()
);

export const createCorrectionTxForMerchantSuccessAction = createAction(
  CorrectionTxActionTypes.CreateCorrectionTxForMerchantSuccess
);

export const createCorrectionTxForMerchantErrorAction = createAction(
  CorrectionTxActionTypes.CreateCorrectionTxForMerchantError,
  props<{ errors: ErrorModel[] }>()
);
