/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { MarketOperationModel } from '../models/api/market-operation.model';
import { ErrorModel } from '../models/api/error.model';

export enum MarketOperationActionTypes {
  HIDE_MARKET_OPERATION = 'marketOperation/HIDE_MARKET_OPERATION',
  HIDE_MARKET_OPERATION_SUCCESS = 'marketOperation/HIDE_MARKET_OPERATION_SUCCESS',
  HIDE_MARKET_OPERATION_ERROR = 'marketOperation/HIDE_MARKET_OPERATION_ERROR',
  UPDATE_MARKET_ORDER_STATUS = 'marketOperation/UPDATE_MARKET_ORDER',
  UPDATE_MARKET_ORDER_STATUS_SUCCESS = 'marketOperation/UPDATE_MARKET_ORDER_SUCCESS',
  UPDATE_MARKET_ORDER_STATUS_ERROR = 'marketOperation/UPDATE_MARKET_ORDER_ERROR',
}

export const hideMarketOperationAction = createAction(
  MarketOperationActionTypes.HIDE_MARKET_OPERATION,
  props<{ marketOperationHashId: string }>()
);

export const hideMarketOperationErrorAction = createAction(
  MarketOperationActionTypes.HIDE_MARKET_OPERATION_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const hideMarketOperationSuccessAction = createAction(MarketOperationActionTypes.HIDE_MARKET_OPERATION_SUCCESS);

export const updateMarketOrderStatusAction = createAction(
  MarketOperationActionTypes.UPDATE_MARKET_ORDER_STATUS,
  props<{ marketOperationHashId: string }>()
);

export const updateMarketOrderStatusSuccessAction = createAction(
  MarketOperationActionTypes.UPDATE_MARKET_ORDER_STATUS_SUCCESS,
  props<{ marketOperation: MarketOperationModel }>()
);

export const updateMarketOrderStatusErrorAction = createAction(
  MarketOperationActionTypes.UPDATE_MARKET_ORDER_STATUS_ERROR,
  props<{ errors: ErrorModel[] }>()
);
