import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { ApiService } from '../services/api.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import {
  getMerchantFeesAction,
  getMerchantFeesErrorAction,
  getMerchantFeesSuccessAction,
  updateMerchantFeesAction,
  updateMerchantFeesErrorAction,
  updateMerchantFeesSuccessAction,
} from '../actions/merchant-fees.actions';

export const MERCHANT_FEES_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/{merchantId}/fees`;

@Injectable()
export class MerchantFeesEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantFeesAction),
      mergeMap(({ merchantId }) =>
        this.api.get(this.url(merchantId)).pipe(
          map((merchantFees) => getMerchantFeesSuccessAction({ merchantFees })),
          catchError((errors) => observableOf(getMerchantFeesErrorAction(errors)))
        )
      )
    )
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantFeesAction),
      mergeMap(({ merchantFees, merchantId }) =>
        this.api.put(this.url(merchantId), merchantFees).pipe(
          map((merchantFees) => updateMerchantFeesSuccessAction({ merchantFees })),
          catchError((errors) => observableOf(updateMerchantFeesErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(merchantId: string): string {
    return MERCHANT_FEES_ENDPOINT.replace('{merchantId}', merchantId);
  }
}
