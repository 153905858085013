import { Observable, of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import { API_VERSION } from '../../constants';
import {
  getCurrencyListAction,
  getCurrencyListErrorAction,
  getCurrencyListSuccessAction,
} from './currency-list.actions';

export const CURRENCIES_ENDPOINT = `/${API_VERSION}/currencies`;

@Injectable()
export class CurrencyListEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrencyListAction),
      mergeMap((action) => this.getCurrenciesRequest())
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  getCurrenciesRequest(): Observable<any> {
    return this.api.get(CURRENCIES_ENDPOINT).pipe(
      map((currencies) => getCurrencyListSuccessAction({ currencies })),
      catchError((errors) => observableOf(getCurrencyListErrorAction(errors)))
    );
  }
}
