import { PersonModel } from '../../models/api/merchant-profile/person-model';
import {
  createMerchantDirectorErrorAction,
  createMerchantDirectorSuccessAction,
  deleteMerchantDirectorErrorAction,
  deleteMerchantDirectorSuccessAction,
  getMerchantDirectorsListAction,
  getMerchantDirectorsListErrorAction,
  getMerchantDirectorsListSuccessAction,
  updateMerchantDirectorErrorAction,
  updateMerchantDirectorSuccessAction,
} from './merchant-directors.actions';
import { createReducer, on } from '@ngrx/store';
import { StateModel } from '../../models/auxiliary/state.model';

export type MerchantDirectorsState = StateModel<PersonModel[]>;

const INITIAL_STATE: MerchantDirectorsState = {
  errors: null,
  data: null,
};

export const merchantDirectorsReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantDirectorsListAction, () => ({
    ...INITIAL_STATE,
  })),
  on(getMerchantDirectorsListSuccessAction, (state, { merchantDirectors }) => ({
    errors: null,
    data: merchantDirectors,
  })),
  on(createMerchantDirectorSuccessAction, (state, { merchantDirector }) => ({
    errors: null,
    data: [...state.data, merchantDirector],
  })),
  on(updateMerchantDirectorSuccessAction, (state, { merchantDirector }) => ({
    errors: null,
    data: [
      ...state.data.slice(0, state.data.indexOf(state.data.find((director) => director.id === merchantDirector.id))),
      merchantDirector,
      ...state.data.slice(state.data.indexOf(state.data.find((director) => director.id === merchantDirector.id)) + 1),
    ],
  })),
  on(deleteMerchantDirectorSuccessAction, (state, { merchantDirectorId }) => ({
    ...state,
    data: state.data.filter((director) => director.id !== merchantDirectorId),
  })),
  on(
    getMerchantDirectorsListErrorAction,
    createMerchantDirectorErrorAction,
    updateMerchantDirectorErrorAction,
    deleteMerchantDirectorErrorAction,
    (state, { errors }) => ({
      ...state,
      errors: errors,
    })
  )
);
