import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { PaymentButtonModel, PaymentButtonPublicInfo } from '../../shared/models/api/payment-button.model';
import { PagingModel } from '../../shared/models/auxiliary/paging.model';
import { PageRequestModel } from '../../shared/models/auxiliary/page-request.model';
import { queryParams } from '../../shared/utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentButtonService {
  public readonly baseUrl: string;
  public readonly paymentButtonDetailUrl: string;
  public readonly paymentButtonPublicInfoUrl: string;

  constructor(private api: ApiService) {
    // TODO: Add URLs
    this.baseUrl = `/button-invoices`;
    this.paymentButtonDetailUrl = `${this.baseUrl}/{paymentButtonId}`;
    this.paymentButtonPublicInfoUrl = `/button-invoices/{buttonHashCode}/public-information`;
  }

  public create(button: PaymentButtonModel): Observable<PaymentButtonModel> {
    return this.api.post(this.baseUrl, button);
  }

  public delete(button: PaymentButtonModel): Observable<any> {
    const url = this.paymentButtonDetailUrl.replace('{paymentButtonId}', button.id);

    return this.api.delete(url);
  }

  public get(id: string): Observable<PaymentButtonModel> {
    const url = this.paymentButtonDetailUrl.replace('{paymentButtonId}', id);

    return this.api.get(url).pipe(map((paymentButton: PaymentButtonModel) => paymentButton));
  }

  public getList(pagination: PageRequestModel, filters: any): Observable<PagingModel<PaymentButtonModel>> {
    const params: any = { ...pagination, ...filters };
    const url = this.baseUrl + queryParams(params);

    return this.api.get(url);
  }

  public getPublicInfo(hashCode: string): Observable<PaymentButtonPublicInfo> {
    const url = this.paymentButtonPublicInfoUrl.replace('{buttonHashCode}', hashCode);

    return this.api.get(url);
  }
}
