/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { CallbackPasswordModel } from '../models/api/callback-password.model';
import { ErrorModel } from '../models/api/error.model';

export enum callbackPasswordCreateActions {
  POST_REQUEST = 'callbackPassword/POST_REQUEST',
  POST_SUCCESS = 'callbackPassword/POST_SUCCESS',
  POST_ERROR = 'callbackPassword/POST_ERROR',
  CLEAR = 'callbackPassword/CLEAR',
}

export const createCallbackPasswordAction = createAction(
  callbackPasswordCreateActions.POST_REQUEST,
  props<{ twoFACode: string }>()
);

export const createCallbackPasswordSuccessAction = createAction(
  callbackPasswordCreateActions.POST_SUCCESS,
  props<{ callbackPassword: CallbackPasswordModel }>()
);

export const createCallbackPasswordErrorAction = createAction(
  callbackPasswordCreateActions.POST_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createCallbackPasswordClearAction = createAction(callbackPasswordCreateActions.CLEAR);
