import { of } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import {
  getApiKeyListAction,
  getApiKeyListErrorAction,
  getApiKeyListSuccessAction,
} from '../actions/api-key-list.actions';

export const API_KEYS_ENDPOINT = '/merchants/{merchantHashId}/api-keys';

@Injectable()
export class ApiKeyListEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getApiKeyListAction),
      mergeMap(({ merchantHashId }) =>
        this.api.get(API_KEYS_ENDPOINT.replace('{merchantHashId}', merchantHashId)).pipe(
          map((apiKeys) => getApiKeyListSuccessAction({ apiKeys })),
          catchError((errors) => of(getApiKeyListErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
