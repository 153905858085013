/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { PersonModel } from '../models/api/merchant-profile/person-model';
import { ErrorModel } from '../models/api/error.model';

export enum MerchantPersonActionTypes {
  UPDATE_FLAGS_REQUEST = 'merchantPerson/UPDATE_FLAGS_REQUEST',
  UPDATE_FLAGS_SUCCESS = 'merchantPerson/UPDATE_FLAGS_SUCCESS',
  UPDATE_FLAGS_ERROR = 'merchantPerson/UPDATE_FLAGS_ERROR',

  ADD_PERSON_REQUEST = 'merchantPerson/ADD_PERSON_REQUEST',
  ADD_PERSON_SUCCESS = 'merchantPerson/ADD_PERSON_SUCCESS',
  ADD_PERSON_ERROR = 'merchantPerson/ADD_PERSON_ERROR',

  DELETE_PERSON_REQUEST = 'merchantPerson/DELETE_PERSON_REQUEST',
  DELETE_PERSON_SUCCESS = 'merchantPerson/DELETE_PERSON_SUCCESS',
  DELETE_PERSON_ERROR = 'merchantPerson/DELETE_PERSON_ERROR',

  UPDATE_MERCHANT_PERSON_ADMIN = 'merchantPerson/UPDATE_MERCHANT_PERSON_ADMIN_REQUEST',
  UPDATE_MERCHANT_PERSON_ADMIN_SUCCESS = 'merchantPerson/UPDATE_MERCHANT_PERSON_ADMIN_SUCCESS',
  UPDATE_MERCHANT_PERSON_ADMIN_ERROR = 'merchantPerson/UPDATE_MERCHANT_PERSON_ADMIN_ERROR',

  CREATE_MERCHANT_PERSON_ADMIN = 'merchantPerson/CREATE_MERCHANT_PERSON_ADMIN',
  CREATE_MERCHANT_PERSON_ADMIN_SUCCESS = 'merchantPerson/CREATE_MERCHANT_PERSON_ADMIN_SUCCESS',
  CREATE_MERCHANT_PERSON_ADMIN_ERROR = 'merchantPerson/CREATE_MERCHANT_PERSON_ADMIN_ERROR',

  PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN = 'merchantPerson/PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN',
  PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN_SUCCESS = 'merchantPerson/PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN_SUCCESS',
  PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN_ERROR = 'merchantPerson/PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN_ERROR',

  DELETE_MERCHANT_PERSON_ADMIN = 'merchantPerson/DELETE_MERCHANT_PERSON_ADMIN_REQUEST',
  DELETE_MERCHANT_PERSON_ADMIN_SUCCESS = 'merchantPerson/DELETE_MERCHANT_PERSON_ADMIN_SUCCESS',
  DELETE_MERCHANT_PERSON_ADMIN_ERROR = 'merchantPerson/DELETE_MERCHANT_PERSON_ADMIN_ERROR',
}

export const updateFlagsAction = createAction(
  MerchantPersonActionTypes.UPDATE_FLAGS_REQUEST,
  props<{ flags: PersonModel['flags']; personId: string }>()
);

export const updateFlagsSuccessAction = createAction(
  MerchantPersonActionTypes.UPDATE_FLAGS_SUCCESS,
  props<{ flags: PersonModel['flags']; personId: string }>()
);

export const updateFlagsErrorAction = createAction(
  MerchantPersonActionTypes.UPDATE_FLAGS_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateMerchantPersonAdminAction = createAction(
  MerchantPersonActionTypes.UPDATE_MERCHANT_PERSON_ADMIN,
  props<{ person: PersonModel; merchantId: string }>()
);

export const updateMerchantPersonAdminSuccessAction = createAction(
  MerchantPersonActionTypes.UPDATE_MERCHANT_PERSON_ADMIN_SUCCESS,
  props<{ person: PersonModel }>()
);

export const updateMerchantPersonAdminErrorAction = createAction(
  MerchantPersonActionTypes.UPDATE_MERCHANT_PERSON_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createMerchantPersonAdminAction = createAction(
  MerchantPersonActionTypes.CREATE_MERCHANT_PERSON_ADMIN,
  props<{ person: PersonModel; merchantId: string }>()
);

export const createMerchantPersonAdminSuccessAction = createAction(
  MerchantPersonActionTypes.CREATE_MERCHANT_PERSON_ADMIN_SUCCESS
);

export const createMerchantPersonAdminErrorAction = createAction(
  MerchantPersonActionTypes.CREATE_MERCHANT_PERSON_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createMerchantPersonAction = createAction(
  MerchantPersonActionTypes.ADD_PERSON_REQUEST,
  props<{ person: PersonModel }>()
);

export const createMerchantPersonSuccessAction = createAction(
  MerchantPersonActionTypes.ADD_PERSON_SUCCESS,
  props<{ addedPerson: PersonModel }>()
);

export const createMerchantPersonErrorAction = createAction(
  MerchantPersonActionTypes.ADD_PERSON_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const promoteDemoteMerchantPersonAdminAction = createAction(
  MerchantPersonActionTypes.PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN,
  props<{ personId: string; merchantId: string }>()
);

export const promoteDemoteMerchantPersonAdminSuccessAction = createAction(
  MerchantPersonActionTypes.PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN_SUCCESS
);

export const promoteDemoteMerchantPersonAdminErrorAction = createAction(
  MerchantPersonActionTypes.PROMOTE_DEMOTE_MERCHANT_PERSON_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const deleteMerchantPersonAdminAction = createAction(
  MerchantPersonActionTypes.DELETE_MERCHANT_PERSON_ADMIN,
  props<{ person: PersonModel }>()
);

export const deleteMerchantPersonAdminSuccessAction = createAction(
  MerchantPersonActionTypes.DELETE_MERCHANT_PERSON_ADMIN_SUCCESS
);

export const deleteMerchantPersonAdminErrorAction = createAction(
  MerchantPersonActionTypes.DELETE_MERCHANT_PERSON_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const deleteMerchantPersonAction = createAction(
  MerchantPersonActionTypes.DELETE_PERSON_REQUEST,
  props<{ person: PersonModel }>()
);

export const deleteMerchantPersonSuccessAction = createAction(
  MerchantPersonActionTypes.DELETE_PERSON_SUCCESS,
  props<{ person: PersonModel }>()
);

export const deleteMerchantPersonErrorAction = createAction(
  MerchantPersonActionTypes.DELETE_PERSON_ERROR,
  props<{ errors: ErrorModel[] }>()
);
