import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';

import { SETTLEMENT_ENDPOINT } from '../store/settlement/settlement.effect';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import {
  createCorrectionTxForMerchantAction,
  createCorrectionTxForMerchantErrorAction,
  createCorrectionTxForMerchantSuccessAction,
  getCorrectionTxListForMerchantAction,
  getCorrectionTxListForSettlementAction,
  getCorrectionTxListForSettlementErrorAction,
  getCorrectionTxListForSettlementSuccessAction,
} from '../actions/correction-tx.actions';
import { MERCHANT_ENDPOINT } from './merchant-page.effect';

@Injectable()
export class CorrectionTxEffect {
  getCorrectionTxListForSettlement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCorrectionTxListForSettlementAction),
      mergeMap(({ settlementId, admin }) =>
        this.api.get(this.url(settlementId, admin)).pipe(
          map((correctionTxs) => getCorrectionTxListForSettlementSuccessAction({ correctionTxs })),
          catchError((errors) => observableOf(getCorrectionTxListForSettlementErrorAction(errors)))
        )
      )
    )
  );

  getCorrectionTxListForMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCorrectionTxListForMerchantAction),
      mergeMap(({ merchantId }) =>
        this.api.get(`${MERCHANT_ENDPOINT}/${merchantId}/correction-transactions`).pipe(
          map((correctionTxs) => getCorrectionTxListForSettlementSuccessAction({ correctionTxs })),
          catchError((errors) => observableOf(getCorrectionTxListForSettlementErrorAction(errors)))
        )
      )
    )
  );

  createCorrectionTxForMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCorrectionTxForMerchantAction),
      switchMap(({ merchantId, correctionTx }) =>
        this.api.post(`${MERCHANT_ENDPOINT}/${merchantId}/correction-transaction`, correctionTx).pipe(
          map(() => createCorrectionTxForMerchantSuccessAction()),
          catchError((errors) => observableOf(createCorrectionTxForMerchantErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(id: string, admin: boolean): string {
    return `${admin ? '/' + ADMIN_ENDPOINT_PREFIX : ''}${SETTLEMENT_ENDPOINT}/${id}/correction-transactions`;
  }
}
