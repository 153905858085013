import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';

import {
  changeMerchantVerificationStatusAction,
  getMerchantVerificationStatusAction,
  getMerchantVerificationStatusErrorAction,
  getMerchantVerificationStatusSuccessAction,
  resetMerchantVerificationAction,
  resetMerchantVerificationErrorAction,
  resetMerchantVerificationSuccessAction,
} from '../actions/merchant-verification-status.actions';
import { AmlAdminService } from '../../platform/services/aml-admin.service';
import { fromApiToModelProfile } from '../transformations/merchant-profile-transformations';

@Injectable()
export class MerchantVerificationStatusEffects {
  changeVerificationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeMerchantVerificationStatusAction),
      concatMap((action) =>
        this.amlService.changeVerificationStatus(action.merchant, action.status).pipe(
          map((response) => getMerchantVerificationStatusSuccessAction({ merchant: fromApiToModelProfile(response) })),
          catchError((errors) => observableOf(getMerchantVerificationStatusErrorAction(errors)))
        )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantVerificationStatusAction),
      mergeMap(({ merchantId }) =>
        this.amlService.getMerchantProfile(merchantId).pipe(
          map((response) => getMerchantVerificationStatusSuccessAction({ merchant: fromApiToModelProfile(response) })),
          catchError((errors) => observableOf(getMerchantVerificationStatusErrorAction(errors)))
        )
      )
    )
  );

  resetVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetMerchantVerificationAction),
      mergeMap((action) =>
        this.amlService.resetVerification(action.hashId, action.privateComment).pipe(
          map((merchant) => resetMerchantVerificationSuccessAction({ merchant })),
          catchError((errors) => observableOf(resetMerchantVerificationErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private amlService: AmlAdminService) {}
}
