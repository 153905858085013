import { of as observableOf } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService, get2FaHeaders } from '../services/api.service';
import { CALLBACK_PASSWORD_ENDPOINT } from './callback-password.effect';
import {
  createCallbackPasswordAction,
  createCallbackPasswordErrorAction,
  createCallbackPasswordSuccessAction,
} from '../actions/callback-password-create.actions';

@Injectable()
export class CallbackPasswordCreateEffect {
  createCallbackCreatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCallbackPasswordAction),
      switchMap(({ twoFACode }) =>
        this.api.post(CALLBACK_PASSWORD_ENDPOINT, {}, get2FaHeaders(twoFACode)).pipe(
          map((callbackPassword) => createCallbackPasswordSuccessAction({ callbackPassword })),
          catchError((errors) => observableOf(createCallbackPasswordErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
