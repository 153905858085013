/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';

export enum ParameterActionTypes {
  GET_REQUEST = 'param/GET_REQUEST',
  GET_SUCCESS = 'param/GET_SUCCESS',
  GET_ERROR = 'param/GET_ERROR',
}

export const getParameterAction = createAction(ParameterActionTypes.GET_REQUEST, props<{ param: string }>());

export const getSuccessParameterAction = createAction(
  ParameterActionTypes.GET_SUCCESS,
  props<{ parameterResponse: any }>()
);

export const getErrorParameterAction = createAction(ParameterActionTypes.GET_ERROR, props<{ errors: ErrorModel[] }>());
