/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantFeesModel } from '../models/api/merchant-fees.model';

export enum merchantFeesActions {
  GET_REQUEST = 'merchantFees/GET_REQUEST',
  GET_SUCCESS = 'merchantFees/GET_SUCCESS',
  GET_ERROR = 'merchantFees/GET_ERROR',
  UPDATE_REQUEST = 'merchantFees/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'merchantFees/UPDATE_SUCCESS',
  UPDATE_ERROR = 'merchantFees/UPDATE_ERROR',
}

export const getMerchantFeesAction = createAction(merchantFeesActions.GET_REQUEST, props<{ merchantId: string }>());

export const getMerchantFeesSuccessAction = createAction(
  merchantFeesActions.GET_SUCCESS,
  props<{ merchantFees: MerchantFeesModel }>()
);

export const getMerchantFeesErrorAction = createAction(
  merchantFeesActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateMerchantFeesAction = createAction(
  merchantFeesActions.UPDATE_REQUEST,
  props<{ merchantId: string; merchantFees: MerchantFeesModel }>()
);

export const updateMerchantFeesSuccessAction = createAction(
  merchantFeesActions.UPDATE_SUCCESS,
  props<{ merchantFees: MerchantFeesModel }>()
);

export const updateMerchantFeesErrorAction = createAction(
  merchantFeesActions.UPDATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);
