<div class="wizard-step__header">
  <h2 class="bp-heading">Add beneficiaries</h2>
  <p class="wizard-step__legend">
    Please add person(s) or corporate entity(ies) holding more than 25% of the issued shares/voting rights or has the
    power to remove the majority of the board of directors from the company.
  </p>
</div>
<div class="bp-card">
  <div class="add-button__wrapper">
    <button class="confirmo-button confirmo-button__blue extra-margin add-button" (click)="addPerson()"
      >Add beneficiary</button
    >
  </div>
  <mat-table [dataSource]="tableDataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let person">{{ person.firstName }} {{ person.lastName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="pep">
      <mat-header-cell class="center" *matHeaderCellDef
        >PEP
        <span class="tooltip-hint" matTooltip="{{ tooltipPep }}" matTooltipPosition="above">?</span></mat-header-cell
      >
      <mat-cell class="center" *matCellDef="let person">
        <div class="confirmo-checkbox-wrapper checkbox-center">
          <label class="confirmo-switch">
            <input
              name="pep"
              type="checkbox"
              class="confirmo-switch-input"
              [(ngModel)]="person.flags.pep"
              (change)="saveFlags(person)"
            />
            <span class="confirmo-switch-slider"></span>
          </label>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ubo">
      <mat-header-cell class="center" *matHeaderCellDef
        >UBU
        <span class="tooltip-hint" matTooltip="{{ tooltipUbu }}" matTooltipPosition="above">?</span></mat-header-cell
      >
      <mat-cell class="center" *matCellDef="let person">
        <div class="confirmo-checkbox-wrapper checkbox-center">
          <label class="confirmo-switch">
            <input
              name="ubo"
              type="checkbox"
              class="confirmo-switch-input"
              [(ngModel)]="person.flags.ubo"
              (change)="saveFlags(person)"
            />
            <span class="confirmo-switch-slider"></span>
          </label>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="us">
      <mat-header-cell class="center" *matHeaderCellDef
        >U.S. person
        <span class="tooltip-hint" matTooltip="{{ tooltipUs }}" matTooltipPosition="above">?</span></mat-header-cell
      >
      <mat-cell class="center" *matCellDef="let person">
        <div class="confirmo-checkbox-wrapper checkbox-center">
          <label class="confirmo-switch">
            <input
              name="us"
              type="checkbox"
              class="confirmo-switch-input"
              [(ngModel)]="person.flags.us"
              (change)="saveFlags(person)"
            />
            <span class="confirmo-switch-slider"></span>
          </label>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove">
      <mat-header-cell class="center" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="center" *matCellDef="let person">
        <button
          class="confirmo-button confirmo-button__pink button-delete"
          *ngIf="person.personType === 'OTHER_COMPANY_PERSON'"
          (click)="remove(person)"
        >
          <img
            src="assets/tables/trash.svg"
            class="user-list-button__delete__icon button-delete__img"
            alt="Delete user"
          />
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="row">
    <button class="confirmo-button confirmo-button__blue extra-margin max-width" (click)="nextStep()">Continue</button>
  </div>
</div>
