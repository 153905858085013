import { StateModel } from '../models/auxiliary/state.model';
import { ConfigurationItemModel } from '../models/api/configuration-item.model';
import { createReducer, on } from '@ngrx/store';
import {
  getConfigurationListAction,
  getConfigurationListErrorAction,
  getConfigurationListSuccessAction,
} from '../actions/configuration-list.actions';

export type ConfigurationListState = StateModel<ConfigurationItemModel[]>;

const INITIAL_STATE: ConfigurationListState = {
  errors: null,
  data: [],
};

export const configurationListReducer = createReducer(
  INITIAL_STATE,
  on(getConfigurationListAction, (state) => ({
    errors: null,
    data: [],
  })),
  on(getConfigurationListSuccessAction, (state, { configurationList }) => ({
    errors: null,
    data: configurationList,
  })),
  on(getConfigurationListErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
