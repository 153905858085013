import { AbstractIntervalRequestService } from '../../services/interval/abstract-interval-request.service';
import { getErrorTodoListAction, getTodoListAction } from './todo-list.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStateModel } from '../../models/auxiliary/app-state.model';
import { Observable } from 'rxjs';
import { TodoItemModel } from '../../models/api/todo-item.model';
import { selectToDoList } from './todo-list.selector';
import { Actions, ofType } from '@ngrx/effects';
import { MessageService } from '../../services/message.service';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TodoListService extends AbstractIntervalRequestService<TodoItemModel[]> {
  todoListIsDone = false;

  constructor(protected store: Store<AppStateModel>, private actions: Actions, private messageService: MessageService) {
    super(store);

    this.isTodoListDone().subscribe((isDone) => {
      this.todoListIsDone = isDone;
    });

    this.start(getTodoListAction(), 5000, () => !this.todoListIsDone);

    this.actions.pipe(ofType(getErrorTodoListAction)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, 'To Do list error');
    });
  }

  public getTodoList(): Observable<TodoItemModel[]> {
    return this.store.select(selectToDoList).pipe(
      filter((state) => state != null && state.data != null),
      map((state) => state.data)
    );
  }

  /**
   * Server will always reply with 2 finished tasks and every unfinished task,
   * so when the response has only 2 tasks, we know that all tasks are already finished
   */
  public isTodoListDone(): Observable<boolean> {
    return this.getTodoList().pipe(map((todoItems) => todoItems.length === 2));
  }
}
