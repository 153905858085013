import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  hideMarketOperationAction,
  hideMarketOperationErrorAction,
  hideMarketOperationSuccessAction,
  updateMarketOrderStatusAction,
  updateMarketOrderStatusErrorAction,
  updateMarketOrderStatusSuccessAction,
} from '../actions/market-operation.actions';
import { ApiService } from '../services/api.service';

export const HIDE_MARKET_ORDER_ENDPOINT = `/admin/markets/{hashId}/hideOrder`;
export const UPDATE_MARKET_ORDER_STATUS_ENDPOINT = `/admin/markets/{hashId}/updateOrder`;

@Injectable()
export class MarketOperationEffects {
  hideMarketOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hideMarketOperationAction),
      switchMap(({ marketOperationHashId }) =>
        this.api.post(this.hideMarketOrderUrl(marketOperationHashId)).pipe(
          map(() => hideMarketOperationSuccessAction()),
          catchError((errors) => of(hideMarketOperationErrorAction(errors)))
        )
      )
    )
  );

  updateMarketOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMarketOrderStatusAction),
      switchMap(({ marketOperationHashId }) =>
        this.api.post(this.updateMarketOrderStatusUrl(marketOperationHashId)).pipe(
          map((marketOperation) => updateMarketOrderStatusSuccessAction({ marketOperation })),
          catchError((errors) => of(updateMarketOrderStatusErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  hideMarketOrderUrl(marketOrderHashId: string): string {
    return HIDE_MARKET_ORDER_ENDPOINT.replace('{hashId}', marketOrderHashId);
  }

  updateMarketOrderStatusUrl(marketOrderHashId: string): string {
    return UPDATE_MARKET_ORDER_STATUS_ENDPOINT.replace('{hashId}', marketOrderHashId);
  }
}
