import { getErrorParameterAction, getParameterAction, getSuccessParameterAction } from '../actions/parameter.actions';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';

export type ParameterState = StateModel<string>;

const INITIAL_STATE: ParameterState = {
  errors: null,
  data: null,
};

export const parameterReducer = createReducer(
  INITIAL_STATE,
  on(getParameterAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getSuccessParameterAction, (state, { parameterResponse }) => ({
    errors: null,
    data: parameterResponse,
  })),
  on(getErrorParameterAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
