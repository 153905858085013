import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { closeDialogWithAnimation } from '../../../../../shared/dialogs-utils';
import { SanitizerService } from '../../../../../shared/services/sanitizer.service';
import { IdenfyIframeStatus } from '../idenfy.enum';

const IDENFY_LINK_BASE = 'https://ui.idenfy.com/?authToken=';

@Component({
  selector: 'bp-idenfy-iframe',
  templateUrl: './idenfy-iframe-dialog.component.html',
  styleUrls: ['./idenfy-iframe-dialog.component.scss'],
})
export class IdenfyIframeDialogComponent extends AbstractComponent implements OnInit {
  @ViewChild('iFrame') iframe: ElementRef;
  src: SafeResourceUrl;
  show: boolean = false;
  authToken?: string;
  locale?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public sanitizerService: SanitizerService,
    private dialogRef: MatDialogRef<IdenfyIframeDialogComponent>
  ) {
    super();

    this.authToken = data.authToken;
    this.locale = data.locale;

    window.addEventListener(
      'message',
      (event) => {
        switch (event.data.status) {
          case IdenfyIframeStatus.FAILED:
          case IdenfyIframeStatus.APPROVED:
            // can be processed in the future, if needed
            break;
        }
      },
      false
    );
  }

  ngOnInit() {
    this.src = this.sanitizerService.sanitizeUrl(`${IDENFY_LINK_BASE}${this.authToken}&lang=${this.locale}`);
  }

  closeDialog(): void {
    closeDialogWithAnimation(this.dialogRef);
  }
}
