import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { queryParams } from '../utils';
import { ErrorModel } from '../models/api/error.model';
import { MessageService } from './message.service';
import { SaveHttpBlobService } from './save-http-blob.service';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';

export const SETTLEMENT_ENDPOINT = '/settlements';

@Injectable()
export class XmlReportService {
  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private saveHttpBlobService: SaveHttpBlobService
  ) {}

  downloadInvoices(hashIds: string[]): void {
    this.download(`/${ADMIN_ENDPOINT_PREFIX}${SETTLEMENT_ENDPOINT}/xml${queryParams({ hashIds: hashIds })}`);
  }

  download(url: string): void {
    this.api.downloadFile(url).subscribe(
      (response) => this.saveHttpBlobService.saveToFileSystem(response),
      ({ errors }: { errors: ErrorModel[] }) => {
        this.messageService.showErrors(errors || [], 'Xml Error');
      }
    );
  }
}
