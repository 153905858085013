import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractComponent } from '../../../../shared/components/abstract.component';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStateModel } from '../../../../shared/models/auxiliary/app-state.model';
import { MessageService } from '../../../../shared/services/message.service';
import { MerchantProfileType } from '../../../../shared/enums/merchant-profile-type.enum';
import { PhysicalMerchantProfileModel } from '../../../../shared/models/api/merchant-profile/physical-merchant-profile.model';
import { CompanyMerchantProfileModel } from '../../../../shared/models/api/merchant-profile/company-merchant-profile.model';
import { Actions, ofType } from '@ngrx/effects';
import { wizardNextStepAction } from '../../../../shared/actions/wizard.actions';
import { MerchantProfileVerificationStatus } from '../../../../shared/enums/merchant-profile-verification-status.enum';

import { MerchantProfileUnionModel } from '../../../../shared/models/api/merchant-profile/merchant-profile-union.model';
import { PersonalAccountVerificationFormComponent } from './personal-account-verification-form/personal-account-verification-form.component';
import { CompanyAccountVerificationFormComponent } from './company-account-verification-form/company-account-verification-form.component';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { CountryTo } from '../../../../shared/models/api/country.model';
import {
  getMerchantProfileAction,
  updateMerchantProfileErrorAction,
  updateMerchantProfileSuccessAction,
} from '../../../../shared/actions/merchant-profile.actions';
import { selectMerchantProfile } from '../../../../shared/selectors/merchant-profile.selectors';
import { CountryService } from '../../../../shared/store/countries/country-list.service';

@Component({
  selector: 'bp-merchant-verification',
  templateUrl: './merchant-verification.component.html',
  styleUrls: ['./merchant-verification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MerchantVerificationComponent extends AbstractComponent {
  @ViewChild(PersonalAccountVerificationFormComponent)
  personalAccountComponent: PersonalAccountVerificationFormComponent;

  @ViewChild(CompanyAccountVerificationFormComponent)
  companyAccountComponent: CompanyAccountVerificationFormComponent;

  countries: CountryTo[] = [];
  verificationStatus: MerchantProfileVerificationStatus;
  selectedType: MerchantProfileType;
  merchantProfile: MerchantProfileUnionModel;

  constructor(
    private store: Store<AppStateModel>,
    private actions: Actions,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private countryService: CountryService
  ) {
    super();

    this.countryService
      .getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countries) => {
        this.countries = countries;
      });

    combineLatest([this.route.data, this.store.select(selectMerchantProfile)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([routeData, merchantProfile]) => {
        if (merchantProfile.errors !== null) {
          this.messageService.showErrors(merchantProfile.errors, 'Merchant Profile Error');
          return;
        }

        if (merchantProfile.data) {
          this.merchantProfile = merchantProfile.data;
          const selectedAccountType =
            merchantProfile.data.verificationStatus !== MerchantProfileVerificationStatus.NOT_VERIFIED
              ? merchantProfile.data.type
              : null;
          this.selectType(selectedAccountType);
        } else {
          /*
            If merchant profile doesn't exist and server responses 204 No content (errors = null, data = null and state not initial)
            then we request for data for pre-filling forms.
             */
          this.verificationStatus = MerchantProfileVerificationStatus.NOT_VERIFIED;
        }

        this.selectType(routeData.type);
      });

    this.actions.pipe(ofType(updateMerchantProfileSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.messageService.success('Merchant profile successfully updated');
      this.performNextStep();
    });

    this.actions
      .pipe(ofType(updateMerchantProfileErrorAction), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => {
        this.messageService.showErrors(errors, 'Merchant Profile Error');
      });

    this.store.dispatch(getMerchantProfileAction());
  }

  prepareForms(): void {
    if (this.merchantProfile.type === MerchantProfileType.PERSON && this.selectedType === MerchantProfileType.PERSON) {
      const personProfile = this.merchantProfile as PhysicalMerchantProfileModel;
      this.personalAccountComponent.personalAccountForm.patchValue(personProfile.person, { emitEvent: false });
    } else if (
      this.merchantProfile.type === MerchantProfileType.COMPANY &&
      this.selectedType === MerchantProfileType.COMPANY
    ) {
      const companyProfile = this.merchantProfile as CompanyMerchantProfileModel;
      this.companyAccountComponent.companyAccountForm.patchValue(companyProfile, { emitEvent: false });
    }
    this.verificationStatus = this.merchantProfile.verificationStatus;
  }

  selectType(type: MerchantProfileType): void {
    this.selectedType = type;

    if (this.selectedType && this.merchantProfile) {
      setTimeout(() => {
        this.prepareForms();
        if (type === MerchantProfileType.COMPANY) {
          const countryCode = this.companyAccountComponent?.companyAccountForm.get('countryCode').value;
          this.countries.some((country) => {
            if (country.alpha2 === countryCode) {
              this.companyAccountComponent.selectedCountry = country;
              return true;
            } else {
              return false;
            }
          });
        } else if (type === MerchantProfileType.PERSON) {
          const countryCode = this.personalAccountComponent?.personalAccountForm.get('countryCode').value;
          const nationality = this.personalAccountComponent?.personalAccountForm.get('nationalityCountryCode').value;
          this.countries.some((country) => {
            if (country.alpha2 === nationality) {
              this.personalAccountComponent.selectedNationality = country;
              this.countries.some((place) => {
                if (place.alpha2 === countryCode) {
                  this.personalAccountComponent.selectedCountryCode = place;
                  return true;
                }
                return false;
              });
              return true;
            } else {
              return false;
            }
          });
        }
      });
    }
  }

  public performNextStep(): void {
    this.store.dispatch(wizardNextStepAction());
  }
}
