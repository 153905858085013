import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  cancelOtherSideTransactionAction,
  cancelOtherSideTransactionErrorAction,
  cancelOtherSideTransactionSuccessAction,
  confirmOtherSideTransactionAction,
  confirmOtherSideTransactionErrorAction,
  confirmOtherSideTransactionSuccessAction,
  create2FAOtherSideTransactionAction,
  createOtherSideTransactionAction,
  createOtherSideTransactionErrorAction,
  createOtherSideTransactionSuccessAction,
  getOtherSideTransactionAction,
  getOtherSideTransactionListAction,
  getOtherSideTransactionsErrorAction,
  getOtherSideTransactionsListErrorAction,
  getOtherSideTransactionsListSuccessAction,
  getOtherSideTransactionsSuccessAction,
  payoutExportAction,
  payoutExportErrorAction,
  payoutExportSuccessAction,
  retryWithdrawAction,
  retryWithdrawErrorAction,
  retryWithdrawSuccessAction,
} from '../actions/other-side-transaction.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { OtherSideTransactionService } from '../../platform/services/other-side-transaction.service';
import {
  getOtherSideTransactionLimitsAction,
  getOtherSideTransactionLimitsErrorAction,
  getOtherSideTransactionLimitsSuccessAction,
} from '../actions/other-side-transaction-limits.actions';

@Injectable()
export class OtherSideTransactionEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOtherSideTransactionAction),
      mergeMap(({ transactionId, isAdmin }) =>
        this.otherSideTransactionService.get(transactionId, isAdmin).pipe(
          map((transaction) => getOtherSideTransactionsSuccessAction({ transaction })),
          catchError((errors) => of(getOtherSideTransactionsErrorAction(errors)))
        )
      )
    )
  );

  withdrawRetry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retryWithdrawAction),
      switchMap(({ transactionId }) =>
        this.otherSideTransactionService.withdrawRetry(transactionId).pipe(
          map((transaction) => retryWithdrawSuccessAction({ transaction })),
          catchError((errors) => of(retryWithdrawErrorAction(errors)))
        )
      )
    )
  );

  getHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOtherSideTransactionListAction),
      mergeMap(({ isAdmin, filters, paging }) =>
        this.otherSideTransactionService.getOsTransactionHistory(isAdmin, paging, filters).pipe(
          map((transactions) => getOtherSideTransactionsListSuccessAction({ transactions })),
          catchError((errors) => of(getOtherSideTransactionsListErrorAction(errors)))
        )
      )
    )
  );

  payoutExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payoutExportAction),
      switchMap(({ isAdmin, filters, paging, uuid: uuid }) =>
        this.otherSideTransactionService.postPayoutHistoryExport(isAdmin, paging, filters, uuid).pipe(
          map((transactions) => payoutExportSuccessAction()),
          catchError((errors) => of(payoutExportErrorAction(errors)))
        )
      )
    )
  );

  createOsTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOtherSideTransactionAction),
      switchMap(({ otherSideTransaction }) =>
        this.otherSideTransactionService.create(otherSideTransaction).pipe(
          map((otherSideTransaction) => createOtherSideTransactionSuccessAction({ otherSideTransaction })),
          catchError((errors) => of(createOtherSideTransactionErrorAction(errors)))
        )
      )
    )
  );

  createOsTransaction2FA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(create2FAOtherSideTransactionAction),
      switchMap(({ otherSideTransaction, twoFa }) =>
        this.otherSideTransactionService.create2FA(otherSideTransaction, twoFa).pipe(
          map((otherSideTransaction) => createOtherSideTransactionSuccessAction({ otherSideTransaction })),
          catchError((errors) => of(createOtherSideTransactionErrorAction(errors)))
        )
      )
    )
  );

  confirmOsTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmOtherSideTransactionAction),
      switchMap(({ hashId, twoFa }) =>
        this.otherSideTransactionService.confirmTransaction(hashId, twoFa).pipe(
          map((transaction) => confirmOtherSideTransactionSuccessAction({ transaction })),
          catchError((errors) => of(confirmOtherSideTransactionErrorAction(errors)))
        )
      )
    )
  );

  cancelOsTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelOtherSideTransactionAction),
      switchMap(({ hashId }) =>
        this.otherSideTransactionService.cancelTransaction(hashId).pipe(
          map((transaction) => cancelOtherSideTransactionSuccessAction({ transaction })),
          catchError((errors) => of(cancelOtherSideTransactionErrorAction(errors)))
        )
      )
    )
  );

  getTransactionLimits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOtherSideTransactionLimitsAction),
      mergeMap(() =>
        this.otherSideTransactionService.getLimits().pipe(
          map((limits) => getOtherSideTransactionLimitsSuccessAction({ limits })),
          catchError((errors) => of(getOtherSideTransactionLimitsErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private otherSideTransactionService: OtherSideTransactionService) {}
}
