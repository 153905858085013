import { Injectable } from '@angular/core';
import { ReadWriteRole } from './roles/read-write.role';
import { AbstractRole } from './roles/abstract.role';
import { ReadOnlyRole } from './roles/read-only.role';
import { UserRole } from '../../enums/user-role.enum';
import { OwnerRole } from './roles/owner.role';
import { AdminRole } from './roles/admin.role';
import { AdminEditorRole } from './roles/admin-editor.role';
import { SupportRole } from './roles/support.role';
import { AdminReporterRole } from './roles/admin-reporter.role';

@Injectable({
  providedIn: 'root',
})
export class RoleFactoryService {
  private availableRoles = new Map<string, AbstractRole>();

  constructor() {
    this.availableRoles.set(UserRole.READER, new ReadOnlyRole());
    this.availableRoles.set(UserRole.USER, new ReadWriteRole());
    this.availableRoles.set(UserRole.MERCHANT, new OwnerRole());
    this.availableRoles.set(UserRole.ADMIN, new AdminRole());
    this.availableRoles.set(UserRole.ADMIN_EDITOR, new AdminEditorRole());
    this.availableRoles.set(UserRole.SUPPORT, new SupportRole());
    this.availableRoles.set(UserRole.ADMIN_REPORTER, new AdminReporterRole());
  }

  public getRole(roleName: string): AbstractRole {
    if (!this.availableRoles.has(roleName)) {
      throw new Error(`Invalid role ${roleName}`);
    }

    return this.availableRoles.get(roleName);
  }
}
