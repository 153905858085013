import { MerchantModel } from '../models/api/merchant.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';
import { getMerchantPageErrorAction, getMerchantPageSuccessAction } from '../actions/merchant-page.actions';

export type MerchantPageState = StateModel<PagingModel<MerchantModel>>;

const INITIAL_STATE: MerchantPageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
};

export const merchantPageReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantPageSuccessAction, (state, { merchants }) => ({
    errors: null,
    data: merchants,
  })),
  on(getMerchantPageErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
