import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import { of as observableOf } from 'rxjs';
import {
  deleteUserAsAdminAction,
  deleteUserAsAdminErrorAction,
  deleteUserAsAdminSuccessAction,
  getUserListAsAdminAction,
  getUserListAsAdminErrorAction,
  getUserListAsAdminSuccessAction,
  resendActivationEmailAsAdminAction,
  resendActivationEmailAsAdminErrorAction,
  resendActivationEmailAsAdminSuccessAction,
  updateUserRoleAsAdminAction,
  updateUserRoleAsAdminErrorAction,
  updateUserRoleAsAdminSuccessAction,
} from './user-list-admin.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ADMIN_ENDPOINT_PREFIX } from '../../constants';
import { MERCHANT_USER_LIST_ENDPOINT, USER_LIST_ADMIN_ENDPOINT } from '../user-list/user-list.effects';

export const MERCHANT_USER_LIST_ADMIN_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/{merchantHashId}/users`;

@Injectable()
export class UserListAdminEffects {
  getUsersAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserListAsAdminAction),
      mergeMap(({ merchantHashId }) =>
        this.api.get(`${MERCHANT_USER_LIST_ADMIN_ENDPOINT.replace('{merchantHashId}', merchantHashId)}`).pipe(
          map((users) => getUserListAsAdminSuccessAction({ users })),
          catchError((errors) => observableOf(getUserListAsAdminErrorAction(errors)))
        )
      )
    )
  );

  updateUserRoleAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserRoleAsAdminAction),
      switchMap(({ role, id }) =>
        this.api.patch(`${USER_LIST_ADMIN_ENDPOINT}/${id}/role`, { role: role }).pipe(
          map((user) => updateUserRoleAsAdminSuccessAction({ user })),
          catchError((errors) => observableOf(updateUserRoleAsAdminErrorAction(errors)))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserAsAdminAction),
      mergeMap(({ user }) =>
        this.api.delete(`${MERCHANT_USER_LIST_ENDPOINT}/${user.id}`).pipe(
          map(() => deleteUserAsAdminSuccessAction({ user: user })),
          catchError((errors) => observableOf(deleteUserAsAdminErrorAction(errors)))
        )
      )
    )
  );

  resendActivationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendActivationEmailAsAdminAction),
      switchMap(({ user }) =>
        this.api.post(`${MERCHANT_USER_LIST_ENDPOINT}/${user.id}/activation-resend`).pipe(
          map(() => resendActivationEmailAsAdminSuccessAction({ user: user })),
          catchError((errors) => observableOf(resendActivationEmailAsAdminErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
