/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { CurrencyTo } from '../../models/api/currencyTo';

export const currencyListActions = {
  GET_REQUEST: 'currencyList/GET_REQUEST',
  GET_SUCCESS: 'currencyList/GET_SUCCESS',
  GET_ERROR: 'currencyList/GET_ERROR',
};

export const getCurrencyListAction = createAction(currencyListActions.GET_REQUEST);

export const getCurrencyListSuccessAction = createAction(
  currencyListActions.GET_SUCCESS,
  props<{ currencies: CurrencyTo[] }>()
);

export const getCurrencyListErrorAction = createAction(
  currencyListActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
