/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { SettlementModel } from '../../models/api/settlement.model';
import { ErrorModel } from '../../models/api/error.model';

export enum settlementActions {
  GET_REQUEST = 'settlement/GET_REQUEST',
  GET_SUCCESS = 'settlement/GET_SUCCESS',
  GET_ERROR = 'settlement/GET_ERROR',
}

export const getSettlementAction = createAction(
  settlementActions.GET_REQUEST,
  props<{ settlementId: string; isAdmin: boolean }>()
);

export const getSettlementSuccessAction = createAction(
  settlementActions.GET_SUCCESS,
  props<{ settlement: SettlementModel }>()
);

export const getSettlementErrorAction = createAction(settlementActions.GET_ERROR, props<{ errors: ErrorModel[] }>());
