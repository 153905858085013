import { Component, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '../../../../shared/components/abstract.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PersonModel } from '../../../../shared/models/api/merchant-profile/person-model';
import moment from 'moment';
import { maxDateValidator } from '../../../../shared/validators/max-date.validator';
import { whitespaceTrimValidator } from '../../../../shared/validators/whitespace-trim.validator';

@Component({
  selector: 'bp-merchant-person-form',
  templateUrl: './merchant-person-form.component.html',
  styleUrls: ['./merchant-person-form.component.scss'],
})
export class MerchantPersonFormComponent extends AbstractComponent implements OnInit {
  @Input()
  displayFlags: boolean;

  @Input()
  person: PersonModel;

  form: UntypedFormGroup;
  minimalBirthDate: moment.Moment;

  constructor(private fb: UntypedFormBuilder) {
    super();

    // https://en.wikipedia.org/wiki/Age_of_majority#Up_to_age_15: except for Iran girls, we're good with 15 years
    this.minimalBirthDate = moment().add(-15, 'years');

    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      lastName: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      birthDate: ['', [Validators.required, maxDateValidator(this.minimalBirthDate)]],
      flags: this.fb.group({
        pep: [''],
        ubo: [''],
        us: [''],
      }),
    });
  }

  ngOnInit(): void {
    if (this.person) {
      this.form.patchValue(this.person);
    }
  }

  get firstName(): UntypedFormControl {
    return this.form.get('firstName') as UntypedFormControl;
  }

  get lastName(): UntypedFormControl {
    return this.form.get('lastName') as UntypedFormControl;
  }

  get birthDate(): UntypedFormControl {
    return this.form.get('birthDate') as UntypedFormControl;
  }

  containsWhitespaceAtStartOrEnd(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return control.touched && !!control.getError('whitespaces');
  }
}
