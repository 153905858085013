import {
  cryptoAddressVerifyErrorAction,
  cryptoAddressVerifyRequestAction,
  cryptoAddressVerifySuccessAction,
} from '../actions/crypto-address.actions';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';

export type CryptoAddressVerifyState = StateModel<Boolean>;
const INITIAL_STATE: CryptoAddressVerifyState = {
  errors: null,
  data: null,
};

export const cryptoAddressVerifyReducer = createReducer(
  INITIAL_STATE,
  on(cryptoAddressVerifyRequestAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(cryptoAddressVerifySuccessAction, (state, { isValidCryptoAddress }) => ({
    errors: null,
    data: isValidCryptoAddress,
  })),
  on(cryptoAddressVerifyErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
