import { createReducer, on } from '@ngrx/store';
import {
  createCryptoWalletSuccessAction,
  getCryptoWalletsAction,
  getCryptoWalletsErrorAction,
  getCryptoWalletsSuccessAction,
  updateCryptoWalletSuccessAction,
} from '../actions/crypto-wallets.actions';
import { StateModel } from '../models/auxiliary/state.model';
import { CryptoWalletSummaryTo } from '../models/api/crypto-wallets.model';

export type CryptoWalletsState = StateModel<CryptoWalletSummaryTo[]>;

const INITIAL_STATE: CryptoWalletsState = {
  errors: null,
  data: [],
};

export const cryptoWalletsReducer = createReducer(
  INITIAL_STATE,
  on(getCryptoWalletsAction, () => ({
    ...INITIAL_STATE,
  })),
  on(getCryptoWalletsSuccessAction, (state, { wallets }) => ({
    errors: null,
    data: wallets,
  })),
  on(getCryptoWalletsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
  on(createCryptoWalletSuccessAction, (state, { wallet }) => ({
    errors: null,
    data: [wallet, ...state.data],
    // For optimizing DB requests we shouldn't request all wallets list after every successful upload,
    // so we will just add a returned wallet to the top of the list of wallets.
    // Wallets in a view are sorted by id in descending order
  })),
  on(updateCryptoWalletSuccessAction, (state, { wallet }) => ({
    errors: null,
    data: [
      ...state.data.slice(0, state.data.indexOf(state.data.find((stateWallet) => stateWallet.id === wallet.id))),
      wallet,
      ...state.data.slice(state.data.indexOf(state.data.find((stateWallet) => stateWallet.id === wallet.id)) + 1),
    ],
    // For optimizing DB requests we shouldn't request all wallets list after every successful update,
    // so we will just replace the updated wallet with a new object.
    // Wallets in a view are sorted by id in descending order
  }))
);
