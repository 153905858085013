import { StateModel } from '../../models/auxiliary/state.model';
import { SettlementModel } from '../../models/api/settlement.model';
import { createReducer, on } from '@ngrx/store';
import { getSettlementAction, getSettlementErrorAction, getSettlementSuccessAction } from './settlement.actions';

export type SettlementState = StateModel<SettlementModel>;

const INITIAL_STATE: SettlementState = {
  errors: null,
  data: null,
};

export const settlementReducer = createReducer(
  INITIAL_STATE,
  on(getSettlementAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getSettlementSuccessAction, (state, { settlement }) => ({
    errors: null,
    data: settlement,
  })),
  on(getSettlementErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
