import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { SettlementMethodTo } from '../../models/api/settlement-method.model';

enum SettlementMethodWizardActions {
  GET_REQUEST = 'settlementMethodWizard/GET_REQUEST',
  GET_SUCCESS = 'settlementMethodWizard/GET_SUCCESS',
  GET_ERROR = 'settlementMethodWizard/GET_ERROR',

  SET_STEP = 'settlementMethodWizard/SET_STEP',

  CLEAR_DATA = 'settlementMethodWizard/CLEAR_DATA',

  PATCH_DATA = 'settlementMethodWizard/PATCH_DATA',
}

export const getSettlementMethodWizardAction = createAction(
  SettlementMethodWizardActions.GET_REQUEST,
  props<{ id: string; isAdmin: boolean }>()
);

export const getSettlementMethodWizardSuccessAction = createAction(
  SettlementMethodWizardActions.GET_SUCCESS,
  props<{ settlementMethod: SettlementMethodTo }>()
);

export const getSettlementMethodWizardErrorAction = createAction(
  SettlementMethodWizardActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const setStepSettlementMethodWizardAction = createAction(
  SettlementMethodWizardActions.SET_STEP,
  props<{ step: number }>()
);

export const clearDataSettlementMethodWizardAction = createAction(SettlementMethodWizardActions.CLEAR_DATA);

export const patchDataSettlementMethodWizardAction = createAction(
  SettlementMethodWizardActions.PATCH_DATA,
  props<{ settlementMethod: SettlementMethodTo }>()
);
