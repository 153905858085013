/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ApiKeyModel } from '../models/api/api-key.model';
import { ErrorModel } from '../models/api/error.model';

export enum ApiKeyDetailActionTypes {
  GET_REQUEST = 'apiKeyDetail/GET_REQUEST',
  GET_SUCCESS = 'apiKeyDetail/GET_SUCCESS',
  GET_ERROR = 'apiKeyDetail/GET_ERROR',
  POST_REQUEST = 'apiKeyDetail/POST_REQUEST',
  POST_SUCCESS = 'apiKeyDetail/POST_SUCCESS',
  POST_ERROR = 'apiKeyDetail/POST_ERROR',
  UPDATE_REQUEST = 'apiKeyDetail/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'apiKeyDetail/UPDATE_SUCCESS',
  UPDATE_ERROR = 'apiKeyDetail/UPDATE_ERROR',
  DELETE_API_KEY_REQUEST = 'apiKeyDetail/DELETE_API_KEY_REQUEST',
  DELETE_API_KEY_SUCCESS = 'apiKeyDetail/DELETE_API_KEY_SUCCESS',
  DELETE_API_KEY_ERROR = 'apiKeyDetail/DELETE_API_KEY_ERROR',
  CLEAR = 'apiKeyDetail/CLEAR',
}

export const getApiKeyAction = createAction(
  ApiKeyDetailActionTypes.GET_REQUEST,
  props<{ keyId: string; merchantHashId: string }>()
);

export const getApiKeySuccessAction = createAction(
  ApiKeyDetailActionTypes.GET_SUCCESS,
  props<{ apiKey: ApiKeyModel }>()
);

export const getApiKeyErrorAction = createAction(ApiKeyDetailActionTypes.GET_ERROR, props<{ errors: ErrorModel[] }>());

export const createApiKeyAction = createAction(
  ApiKeyDetailActionTypes.POST_REQUEST,
  props<{ key: ApiKeyModel; merchantHashId: string; twoFACode: string }>()
);

export const createApiKeySuccessAction = createAction(
  ApiKeyDetailActionTypes.POST_SUCCESS,
  props<{ apiKey: ApiKeyModel }>()
);

export const createApiKeyErrorAction = createAction(
  ApiKeyDetailActionTypes.POST_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateApiKeyAction = createAction(
  ApiKeyDetailActionTypes.UPDATE_REQUEST,
  props<{ key: ApiKeyModel; merchantHashId: string; twoFACode: string }>()
);

export const updateApiKeySuccessAction = createAction(
  ApiKeyDetailActionTypes.UPDATE_SUCCESS,
  props<{ apiKey: ApiKeyModel }>()
);

export const updateApiKeyErrorAction = createAction(
  ApiKeyDetailActionTypes.UPDATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const deleteApiKeyAction = createAction(
  ApiKeyDetailActionTypes.DELETE_API_KEY_REQUEST,
  props<{ id: string; merchantHashId: string; twoFACode: string }>()
);

export const deleteApiKeySuccessAction = createAction(ApiKeyDetailActionTypes.DELETE_API_KEY_SUCCESS);

export const deleteApiKeyErrorAction = createAction(
  ApiKeyDetailActionTypes.DELETE_API_KEY_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const clearAction = createAction(ApiKeyDetailActionTypes.CLEAR);
