import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { markAllAsTouched } from '../../../../../shared/utils';
import { MerchantProfileType } from '../../../../../shared/enums/merchant-profile-type.enum';
import moment, { Moment } from 'moment';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { Store } from '@ngrx/store';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { maxDateValidator } from '../../../../../shared/validators/max-date.validator';
import { whitespaceTrimValidator } from '../../../../../shared/validators/whitespace-trim.validator';
import { phoneNumberValidator } from '../../../../shared/validators/phone-number.validator';
import { CountryCode, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js';
import { CountryTo } from '../../../../../shared/models/api/country.model';
import { updateMerchantProfileAction } from '../../../../../shared/actions/merchant-profile.actions';
import { normalizePhoneNumber } from '../../../../shared/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bp-personal-account-verification-form',
  templateUrl: './personal-account-verification-form.component.html',
  styleUrls: ['../merchant-verification.component.scss'],
})
export class PersonalAccountVerificationFormComponent extends AbstractComponent {
  @Input()
  countries: CountryTo[] = [];

  @Input()
  isAdmin = false;

  @Output()
  nextStep = new EventEmitter();

  personalAccountForm: UntypedFormGroup;
  minimalBirthDate: Moment;
  canEditForm?: boolean;
  selectedNationality?: CountryTo;
  selectedCountryCode?: CountryTo;

  constructor(private store: Store<AppStateModel>, private fb: UntypedFormBuilder) {
    super();
    this.minimalBirthDate = moment().add(-15, 'years');
    this.personalAccountForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      lastName: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      birthDate: ['', [Validators.required, maxDateValidator(this.minimalBirthDate)]],
      birthPlace: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      nationalityCountryCode: ['', Validators.required],

      street: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      city: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      zip: ['', [Validators.required, Validators.maxLength(10), whitespaceTrimValidator]],
      countryCode: ['', [Validators.required]],
      phone: ['', [Validators.required, phoneNumberValidator, whitespaceTrimValidator]],
      businessId: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
    });

    this.subscribeToCountryCode();
  }

  updatePersonalAccount(): void {
    if (!this.isAdmin) {
      if (this.canEditForm === false) {
        this.nextStep.emit();
      } else if (this.personalAccountForm.invalid) {
        markAllAsTouched(this.personalAccountForm);
      } else {
        this.dispatchMerchantUpdate();
      }
    }
  }

  dispatchMerchantUpdate(): void {
    this.personalAccountForm.get('nationalityCountryCode')?.patchValue(this.selectedNationality?.alpha2);
    this.personalAccountForm.get('countryCode')?.patchValue(this.selectedCountryCode?.alpha2);
    this.patchPhoneNumberBeforeRequest();
    this.store.dispatch(
      updateMerchantProfileAction({
        // @ts-ignore
        merchantProfile: {
          type: MerchantProfileType.PERSON,
          person: {
            ...this.personalAccountForm.value,
          },
        },
      })
    );
  }

  get phoneInvalid(): boolean {
    const phoneControl = this.personalAccountForm.get('phone');
    return !!phoneControl?.touched && !!phoneControl.getError('phoneNumber');
  }

  personalAccountFieldMissing(fieldName: string): boolean {
    const fieldControl = this.personalAccountForm.get(fieldName);
    return !!fieldControl && fieldControl.touched && !!fieldControl.getError('required');
  }

  get countryNotSupported(): boolean {
    const countryControl = this.personalAccountForm.get('countryCode');
    return !!countryControl?.touched && !!countryControl.getError('countryNotSupported');
  }

  containsWhitespaceAtStartOrEnd(fieldName: string): boolean {
    const fieldControl = this.personalAccountForm.get(fieldName);
    return !!fieldControl?.touched && !!fieldControl.getError('whitespaces');
  }

  onSelectChange($event: CountryTo, controlName: string): void {
    this.personalAccountForm.get(controlName)?.patchValue($event);

    if (controlName === 'nationalityCountryCode') {
      this.selectedNationality = $event;
    }

    if (controlName === 'countryCode') {
      this.selectedCountryCode = $event;
    }
  }

  get birthDate(): UntypedFormControl {
    return this.personalAccountForm.get('birthDate') as UntypedFormControl;
  }

  subscribeToCountryCode(): void {
    this.personalAccountForm
      .get('countryCode')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((country: CountryTo) => {
        this.setNumberCountryCode(country.alpha2);
      });
  }

  setNumberCountryCode(countryCode: string): void {
    if (!isSupportedCountry(countryCode)) {
      return;
    }

    const phoneControl = this.personalAccountForm.get('phone');
    const callingCode = getCountryCallingCode(<CountryCode>countryCode);
    phoneControl?.setValue('+' + callingCode);
  }

  patchPhoneNumberBeforeRequest(): void {
    const insertedPhone = this.personalAccountForm.get('phone')?.value;
    if (insertedPhone) {
      this.personalAccountForm.get('phone')?.patchValue(normalizePhoneNumber(insertedPhone));
    }
  }
}
