import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import {
  getWebhookInfoListAction,
  getWebhookInfoListErrorAction,
  getWebhookInfoListSuccessAction,
  resendWebhookRequestAction,
  resendWebhookRequestErrorAction,
  resendWebhookRequestSuccessAction,
} from '../actions/webhook-info-list.actions';

export const WEBHOOKS_ENDPOINT = '/invoices/{id}/webhooks';

@Injectable()
export class WebhookInfoListEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWebhookInfoListAction),
      mergeMap(({ invoiceId }) =>
        this.api.get(this.url(invoiceId)).pipe(
          map((webhooks) => getWebhookInfoListSuccessAction({ webhooks })),
          catchError((errors) => observableOf(getWebhookInfoListErrorAction(errors)))
        )
      )
    )
  );

  resendWebhook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendWebhookRequestAction),
      switchMap(({ invoiceId }) =>
        this.api.post(this.url(invoiceId)).pipe(
          map((webhook) => resendWebhookRequestSuccessAction({ webhook })),
          catchError((errors) => observableOf(resendWebhookRequestErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(id: string): string {
    return WEBHOOKS_ENDPOINT.replace('{id}', id);
  }
}
