import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { MERCHANT_ENDPOINT } from './merchant-page.effect';
import {
  getMerchantDetailAction,
  getMerchantDetailErrorAction,
  getMerchantDetailSuccessAction,
} from '../actions/merchant-detail.actions';

@Injectable()
export class MerchantDetailEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDetailAction),
      mergeMap(({ merchantId }) =>
        this.api.get(`${MERCHANT_ENDPOINT}/${merchantId}`).pipe(
          map((merchant) => getMerchantDetailSuccessAction({ merchant })),
          catchError((errors) => observableOf(getMerchantDetailErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
