/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { PayoutFeesWithCurrencyModel } from '../models/api/payout-fees.model';
import { ErrorModel } from '../models/api/error.model';

export enum PayoutFeesActionTypes {
  GetPayoutFees = 'payoutFees/GET_PAYOUT_FEES_REQUEST',
  GetPayoutFeesSuccess = 'payoutFees/GET_PAYOUT_FEES_SUCCESS',
  GetPayoutFeesError = 'payoutFees/GET_PAYOUT_FEES_ERROR',
}

export const getPayoutFeesAction = createAction(PayoutFeesActionTypes.GetPayoutFees, props<{ currencies: string[] }>());

export const getPayoutFeesSuccessAction = createAction(
  PayoutFeesActionTypes.GetPayoutFeesSuccess,
  props<{ payoutFees: PayoutFeesWithCurrencyModel[] }>()
);

export const getPayoutFeesErrorAction = createAction(
  PayoutFeesActionTypes.GetPayoutFeesError,
  props<{ errors: ErrorModel[] }>()
);
