/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { TwoFaType } from '../models/api/auth.model';
import { ErrorModel } from '../models/api/error.model';

export enum authActions {
  LOGIN_REQUEST = 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS',
  LOGIN_AFTER_REGISTRATION = 'auth/LOGIN_AFTER_REGISTRATION',
  LOGIN_2FA_NEEDED = 'auth/LOGIN_2FA_NEEDED',
  LOGIN_ERROR = 'auth/LOGIN_ERROR',
  TWO_FACTOR_AUTHENTICATE_REQUEST = 'auth/TWO_FACTOR_AUTHENTICATE_REQUEST',
  TWO_FACTOR_AUTHENTICATE_ERROR = 'auth/TWO_FACTOR_AUTHENTICATE_ERROR',
  TWO_FACTOR_AUTHENTICATION_INTERRUPTED = 'auth/TWO_FACTOR_AUTHENTICATION_INTERRUPTED',
  TWO_FACTOR_INVALID_CODE = 'auth/TWO_FACTOR_INVALID_CODE',
  LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'auth/LOGOUT_ERROR',
  INVALID_TOKEN_USED = 'auth/INVALID_TOKEN_USED',
}

export const loginAction = createAction(authActions.LOGIN_REQUEST, props<{ email: string; password: string }>());

export const loginSuccessAction = createAction(authActions.LOGIN_SUCCESS, props<{ token: string }>());

export const loginAfterRegistrationAction = createAction(
  authActions.LOGIN_AFTER_REGISTRATION,
  props<{ token: string }>()
);

export const loginTwoFANeededAction = createAction(
  authActions.LOGIN_2FA_NEEDED,
  props<{ username: string; password: string; twoFaRequiredType: TwoFaType }>()
);

export const loginErrorAction = createAction(authActions.LOGIN_ERROR, props<{ errors: ErrorModel[] }>());

export const twoFactorAuthAction = createAction(
  authActions.TWO_FACTOR_AUTHENTICATE_REQUEST,
  props<{ otp: string; email: string; password: string }>()
);

export const twoFactorAuthErrorAction = createAction(
  authActions.TWO_FACTOR_AUTHENTICATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const twoFactorAuthInterruptedAction = createAction(authActions.TWO_FACTOR_AUTHENTICATION_INTERRUPTED);

export const twoFactorInvalidCodeAction = createAction(authActions.TWO_FACTOR_INVALID_CODE, props<{ error: string }>());

export const logoutAction = createAction(authActions.LOGOUT_REQUEST);

export const logoutSuccessAction = createAction(authActions.LOGOUT_SUCCESS);

export const logoutErrorAction = createAction(authActions.LOGOUT_ERROR, props<{ errors: ErrorModel[] }>());

export const invalidTokenUsedAction = createAction(authActions.INVALID_TOKEN_USED, props<{ error: string }>());
