import { MerchantDocument } from '../models/api/merchant-profile/merchant-document.model';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';
import {
  getMerchantDocumentsAction,
  getMerchantDocumentsErrorAction,
  getMerchantDocumentsSuccessAction,
  uploadMerchantDocumentsSuccessAction,
} from '../actions/merchant-document.actions';

export type MerchantDocumentState = StateModel<MerchantDocument[]>;

const INITIAL_STATE: MerchantDocumentState = {
  errors: null,
  data: null,
};

export const merchantDocumentReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantDocumentsAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getMerchantDocumentsSuccessAction, (state, { files }) => ({
    errors: null,
    data: files,
  })),
  on(uploadMerchantDocumentsSuccessAction, (state, { files }) => ({
    errors: null,
    data: [...state.data, ...files],
  })),
  on(getMerchantDocumentsErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
