import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PlatformGuard } from './shared/guards/platform.guard';
import { MetaGuard } from './shared/guards/meta.guard';

const appRoutes: Routes = [
  {
    path: '',
    canActivateChild: [MetaGuard],
    children: [
      {
        path: 'platform',
        data: { meta: { robots: 'noindex' } },
        loadChildren: () => import('./platform/platform.module').then((m) => m.PlatformModule),
        canActivateChild: [PlatformGuard],
      },
      {
        path: 'public',
        data: { meta: { robots: 'noindex' } },
        loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
      },
      {
        path: '',
        data: { meta: { robots: 'index,follow' } },
        loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
      },
      { path: '**', redirectTo: '/home', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
