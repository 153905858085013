import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import { getCountryListAction, getCountryListErrorAction, getCountryListSuccessAction } from './country-list.actions';

export const COUNTRIES_ENDPOINT = '/countries';

@Injectable()
export class CountryListEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountryListAction),
      mergeMap((action) => this.getCountriesRequest())
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  getCountriesRequest(): Observable<any> {
    return this.api.get(COUNTRIES_ENDPOINT).pipe(
      map((response) =>
        getCountryListSuccessAction({
          countries: [...response].sort((a, b) => a.name.localeCompare(b.name)),
        })
      ),
      catchError((errors) => observableOf(getCountryListErrorAction(errors)))
    );
  }
}
