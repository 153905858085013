import { StateModel } from '../models/auxiliary/state.model';
import { UserGuiSettingsModel } from '../models/api/user-gui-settings.model';
import { createReducer, on } from '@ngrx/store';
import { getUserGuiSettingsErrorAction, getUserGuiSettingsSuccessAction } from '../actions/user-gui-settings.actions';

export type UserGuiSettingsState = StateModel<UserGuiSettingsModel>;

const INITIAL_STATE: UserGuiSettingsState = {
  errors: null,
  data: null,
  initial: true,
};

export const userGuiSettingsReducer = createReducer(
  INITIAL_STATE,
  on(getUserGuiSettingsSuccessAction, (state, { userSettings }) => ({
    errors: null,
    data: userSettings,
    initial: false,
  })),
  on(getUserGuiSettingsErrorAction, (state, { errors }) => ({
    errors: errors,
    initial: false,
  }))
);
