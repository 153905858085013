/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ConfigurationItemModel } from '../models/api/configuration-item.model';
import { EmailInvoiceCreationModel, EmailInvoiceModel } from '../models/api/email-invoice.model';
import { ErrorModel } from '../models/api/error.model';

export enum EmailInvoiceActionTypes {
  GET_EMAIL_INVOICE = 'emailInvoice/GET_EMAIL_INVOICE',
  CREATE_MERCHANT_EMAIL_INVOICE = 'emailInvoice/CREATE_MERCHANT_EMAIL_INVOICE',
  EMAIL_INVOICE_SUCCESS = 'emailInvoice/GET_EMAIL_INVOICE_SUCCESS',
  EMAIL_INVOICE_ERROR = 'emailInvoice/EMAIL_INVOICE_ERROR',

  GET_MAX_CLICKS = 'emailInvoice/GET_MAX_CLICKS',
  MAX_CLICKS_SUCCESS = 'emailInvoice/MAX_CLICKS_SUCCESS',
  MAX_CLICKS_ERROR = 'emailInvoice/MAX_CLICKS_ERROR',
}

export const getEmailInvoiceAction = createAction(
  EmailInvoiceActionTypes.GET_EMAIL_INVOICE,
  props<{ emailInvoiceHashId: string; isAdmin: boolean }>()
);

export const getMaxClickAction = createAction(EmailInvoiceActionTypes.GET_MAX_CLICKS);

export const createMerchantEmailInvoiceAction = createAction(
  EmailInvoiceActionTypes.CREATE_MERCHANT_EMAIL_INVOICE,
  props<{ emailInvoice: EmailInvoiceCreationModel }>()
);

export const emailInvoiceSuccessAction = createAction(
  EmailInvoiceActionTypes.EMAIL_INVOICE_SUCCESS,
  props<{ emailInvoice: EmailInvoiceModel }>()
);

export const maxClicksSuccessAction = createAction(
  EmailInvoiceActionTypes.MAX_CLICKS_SUCCESS,
  props<{ maxClicksParam: ConfigurationItemModel }>()
);

export const emailInvoiceErrorAction = createAction(
  EmailInvoiceActionTypes.EMAIL_INVOICE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const maxClicksErrorAction = createAction(
  EmailInvoiceActionTypes.MAX_CLICKS_ERROR,
  props<{ errors: ErrorModel[] }>()
);
