import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import {
  getLedgerAction,
  getLedgerAsAdminAction,
  getLedgerErrorAction,
  getLedgerExportAction,
  getLedgerExportErrorAction,
  getLedgerExportSuccessAction,
  getLedgerSuccessAction,
} from '../actions/ledger.actions';
import { queryParams } from '../utils';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';

export const LEDGER_ENDPOINT = `ledger`;
export const LEDGER_EXPORT_ENDPOINT = `${LEDGER_ENDPOINT}/export`;
export const LEDGER_ADMIN_EXPORT_ENDPOINT = `${ADMIN_ENDPOINT_PREFIX}/${LEDGER_ENDPOINT}/export`;

@Injectable()
export class LedgerEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLedgerAction),
      mergeMap(({ paging, filters }) =>
        this.api.get(this.url({ ...paging, ...filters })).pipe(
          map((ledger) => getLedgerSuccessAction({ ledger })),
          catchError((errors) => observableOf(getLedgerErrorAction(errors)))
        )
      )
    )
  );

  getAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLedgerAsAdminAction),
      mergeMap(({ paging, merchantHashId, filters }) =>
        this.api.get(this.adminUrl(merchantHashId, { ...paging, ...filters })).pipe(
          map((ledger) => getLedgerSuccessAction({ ledger })),
          catchError((errors) => observableOf(getLedgerErrorAction(errors)))
        )
      )
    )
  );

  ledgerExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLedgerExportAction),
      switchMap(({ paging, filters, isAdmin, uuid: uuid }) =>
        this.api.post(this.exportUrl({ ...paging, ...filters, uuid: uuid }, isAdmin)).pipe(
          map((ledger) => getLedgerExportSuccessAction()),
          catchError((errors) => observableOf(getLedgerExportErrorAction(errors)))
        )
      )
    )
  );
  constructor(private actions$: Actions, private api: ApiService) {}

  url(params: any): string {
    return `/${LEDGER_ENDPOINT}${queryParams(params)}`;
  }

  adminUrl(merchantHashId: string, params: any): string {
    return `/${ADMIN_ENDPOINT_PREFIX}/${merchantHashId}/${LEDGER_ENDPOINT}${queryParams(params)}`;
  }

  exportUrl(params: any, isAdmin: boolean): string {
    if (isAdmin) {
      return `/${LEDGER_ADMIN_EXPORT_ENDPOINT}${queryParams(params)}`;
    } else {
      return `/${LEDGER_EXPORT_ENDPOINT}${queryParams(params)}`;
    }
  }
}
