/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';

export enum CustomerEmailActionTypes {
  GET_EXISTS_REQUEST = 'customerEmail/GET_EXISTS_REQUEST',
  GET_EXISTS_SUCCESS = 'customerEmail/GET_EXISTS_SUCCESS',
  GET_EXISTS_ERROR = 'customerEmail/GET_EXISTS_ERROR',
  POST_REQUEST = 'customerEmail/POST_REQUEST',
  POST_SUCCESS = 'customerEmail/POST_SUCCESS',
  POST_ERROR = 'customerEmail/POST_ERROR',
}

export const getCustomerEmailAction = createAction(
  CustomerEmailActionTypes.GET_EXISTS_REQUEST,
  props<{ hashId: string; refundToken: string }>()
);

export const getCustomerEmailSuccessAction = createAction(
  CustomerEmailActionTypes.GET_EXISTS_SUCCESS,
  props<{ customerEmailResponse: any }>()
);

export const getCustomerEmailErrorAction = createAction(
  CustomerEmailActionTypes.GET_EXISTS_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const postCustomerEmailAction = createAction(
  CustomerEmailActionTypes.POST_REQUEST,
  props<{ customerEmailBody: any; hashId: string }>()
);

export const postCustomerEmailSuccessAction = createAction(CustomerEmailActionTypes.POST_SUCCESS);

export const postCustomerEmailErrorAction = createAction(
  CustomerEmailActionTypes.POST_ERROR,
  props<{ errors: ErrorModel[] }>()
);
