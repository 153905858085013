/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { PageRequestFiltersModel, PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { MarketOperationModel, MarketOrderEntityType } from '../models/api/market-operation.model';

export enum marketPageActions {
  GET_REQUEST = 'marketPage/GET_REQUEST',
  GET_SUCCESS = 'marketPage/GET_SUCCESS',
  GET_ERROR = 'marketPage/GET_ERROR',
  RETRY_MARKET_ENTRY_REQUEST = 'marketPage/RETRY_MARKET_ENTRY_REQUEST',
  RETRY_MARKET_ENTRY_ERROR = 'marketPage/RETRY_MARKET_ENTRY_ERROR',
  RETRY_MARKET_ENTRY_SUCCESS = 'marketPage/RETRY_MARKET_ENTRY_SUCCESS',
}

export const getMarketPageAction = createAction(
  marketPageActions.GET_REQUEST,
  props<{
    paging: PageRequestModel;
    filters: PageRequestFiltersModel;
    entityType: MarketOrderEntityType;
    showHidden: boolean;
  }>()
);

export const getMarketPageSuccessAction = createAction(
  marketPageActions.GET_SUCCESS,
  props<{ marketOrders: PagingModel<MarketOperationModel> }>()
);

export const getMarketPageErrorAction = createAction(marketPageActions.GET_ERROR, props<{ errors: ErrorModel[] }>());

export const retryMarketEntryAction = createAction(
  marketPageActions.RETRY_MARKET_ENTRY_REQUEST,
  props<{ marketOrderId: string }>()
);

export const retryMarketEntrySuccessAction = createAction(marketPageActions.RETRY_MARKET_ENTRY_SUCCESS);

export const retryMarketEntryErrorAction = createAction(
  marketPageActions.RETRY_MARKET_ENTRY_ERROR,
  props<{ errors: ErrorModel[] }>()
);
