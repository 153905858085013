/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { CryptoWalletStatisticsTo } from '../../models/api/crypto-wallets-statistics.model';
import { ErrorModel } from '../../models/api/error.model';

export enum cryptoWalletsStatisticsActions {
  GET_REQUEST = 'cryptoWalletsStatistics/GET_REQUEST',
  GET_SUCCESS = 'cryptoWalletsStatistics/GET_SUCCESS',
  GET_ERROR = 'cryptoWalletsStatistics/GET_ERROR',
}

export const getCryptoWalletsStatisticsAction = createAction(cryptoWalletsStatisticsActions.GET_REQUEST);

export const getCryptoWalletsStatisticsSuccessAction = createAction(
  cryptoWalletsStatisticsActions.GET_SUCCESS,
  props<{ walletsStatistics: CryptoWalletStatisticsTo[] }>()
);

export const getCryptoWalletsStatisticsErrorAction = createAction(
  cryptoWalletsStatisticsActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
