/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { SettlementModel } from '../../models/api/settlement.model';
import { ErrorModel } from '../../models/api/error.model';
import { PagingModel } from '../../models/auxiliary/paging.model';
import { PageRequestFiltersModel, PageRequestModel } from '../../models/auxiliary/page-request.model';
import { SettlementSendModel } from '../../models/api/settlements-send.model';

export enum settlementPageActions {
  GET_REQUEST = 'settlementPage/GET_REQUEST',
  GET_SUCCESS = 'settlementPage/GET_SUCCESS',
  GET_ERROR = 'settlementPage/GET_ERROR',
  PERFORM_ACTION_REQUEST = 'settlementPage/PERFORM_ACTION_REQUEST',
  PERFORM_ACTION_SUCCESS = 'settlementPage/PERFORM_ACTION_SUCCESS',
  PERFORM_ACTION_ERROR = 'settlementPage/PERFORM_ACTION_ERROR',
  PUT_SETTLEMENTS_REQUEST = 'settlementPage/PUT_SETTLEMENTS_REQUEST',
  PUT_SETTLEMENTS_SUCCESS = 'settlementPage/PUT_SETTLEMENTS_SUCCESS',
  PUT_SETTLEMENTS_ERROR = 'settlementPage/PUT_SETTLEMENTS_ERROR',
}

export const getSettlementPageAction = createAction(
  settlementPageActions.GET_REQUEST,
  props<{ paging: PageRequestModel; isAdmin: boolean; filters: PageRequestFiltersModel }>()
);

export const getSettlementPageSuccessAction = createAction(
  settlementPageActions.GET_SUCCESS,
  props<{ settlements: PagingModel<SettlementModel> }>()
);

export const getSettlementPageErrorAction = createAction(
  settlementPageActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const performSettlementAction = createAction(
  settlementPageActions.PERFORM_ACTION_REQUEST,
  props<{ settlementId: string; actionId: string }>()
);

export const performSettlementActionSuccess = createAction(
  settlementPageActions.PERFORM_ACTION_SUCCESS,
  props<{ settlementId: string }>()
);

export const performSettlementActionError = createAction(
  settlementPageActions.PERFORM_ACTION_ERROR,
  props<{ settlementId: string; errors: ErrorModel[] }>()
);

export const putSettlementsAction = createAction(
  settlementPageActions.PUT_SETTLEMENTS_REQUEST,
  props<{ settlementsSend: SettlementSendModel }>()
);

export const putSettlementsSuccessAction = createAction(settlementPageActions.PUT_SETTLEMENTS_SUCCESS);

export const putSettlementsErrorAction = createAction(
  settlementPageActions.PUT_SETTLEMENTS_ERROR,
  props<{ errors: ErrorModel[] }>()
);
