import { StateModel } from '../models/auxiliary/state.model';
import { RefundTokenMapModel } from '../models/auxiliary/customer-token-map.model';
import { createReducer, on } from '@ngrx/store';
import { addRefundTokenMapIfAbsentAction, overwiteRefundTokenMapAction } from '../actions/refund-token-map.actions';

export type RefundTokenMapState = StateModel<RefundTokenMapModel>;

const INITIAL_STATE: RefundTokenMapState = {
  errors: null,
  data: {},
};

export const refundTokenMapReducer = createReducer(
  INITIAL_STATE,
  on(addRefundTokenMapIfAbsentAction, overwiteRefundTokenMapAction, (state, { invoiceIdToken }) => ({
    errors: null,
    data: {
      ...state.data,
      ...invoiceIdToken,
    },
  }))
);
