/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { UserGuiSettingsModel } from '../models/api/user-gui-settings.model';
import { ErrorModel } from '../models/api/error.model';

export enum userGuiSettingsActions {
  GET_REQUEST = 'userGuiSettings/GET_REQUEST',
  GET_SUCCESS = 'userGuiSettings/GET_SUCCESS',
  GET_ERROR = 'userGuiSettings/GET_ERROR',
  UPDATE_USER_GUI_SETTINGS_REQUEST = 'userGuiSettings/UPDATE_USER_GUI_SETTINGS_REQUEST',
  UPDATE_USER_GUI_SETTINGS_SUCCESS = 'userGuiSettings/UPDATE_USER_GUI_SETTINGS_SUCCESS',
  UPDATE_USER_GUI_SETTINGS_ERROR = 'userGuiSettings/UPDATE_USER_GUI_SETTINGS_ERROR',
}

export const getUserGuiSettingsAction = createAction(userGuiSettingsActions.GET_REQUEST);
export const getUserGuiSettingsSuccessAction = createAction(
  userGuiSettingsActions.GET_SUCCESS,
  props<{ userSettings: UserGuiSettingsModel }>()
);
export const getUserGuiSettingsErrorAction = createAction(
  userGuiSettingsActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
export const updateUserGuiSettingsAction = createAction(
  userGuiSettingsActions.UPDATE_USER_GUI_SETTINGS_REQUEST,
  props<{ userSettings: UserGuiSettingsModel }>()
);
export const updateUserGuiSettingsSuccessAction = createAction(userGuiSettingsActions.UPDATE_USER_GUI_SETTINGS_SUCCESS);
export const updateUserGuiSettingsErrorAction = createAction(
  userGuiSettingsActions.UPDATE_USER_GUI_SETTINGS_ERROR,
  props<{ errors: ErrorModel[] }>()
);
