import { QrScannerDialogComponent } from './components/qr-scanner/qr-scanner-dialog.component';
import { TwoFactorAuthForLoginComponent } from './components/two-factor-auth-input/two-factor-auth-for-login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayoutIntervalInputComponent } from './components/payout-interval-input/payout-interval-input.component';
import { RouterModule } from '@angular/router';
import { FixedHeaderDirective } from './directives/fixed-header.directive';
import { CopyOnClickDirective } from './directives/copy-on-click.directive';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TextDialogComponent } from './components/dialogs/text-dialog/text-dialog.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { ThrottleClickDirective } from './directives/throttle-click.directive';
import { LoadingOverlayComponent } from './components/loading-overlay-container/loading-overlay/loading-overlay.component';
import { LoadingOverlayContainerComponent } from './components/loading-overlay-container/loading-overlay-container.component';
import { SecuredImageComponent } from './components/secured-image/secured-image.component';
import { AuthService } from './services/authorization/auth.service';
import { RoleFactoryService } from './services/authorization/role-factory.service';
import { MessageComponent } from './components/message/message.component';
import { FileUploadDirective } from './directives/file-upload.directive';
import { FileDropUploadDirective } from './directives/file-drop-upload.directive';
import { FileUploadService } from './services/file-upload.service';
import { DisableControlDirective } from './directives/disable-control.directive';
import { SanitizerService } from './services/sanitizer.service';
import { InvoiceService } from './services/invoice.service';
import { CookieService } from 'ngx-cookie-service';
import { ParagraphTextComponent } from './components/paragraph-text/paragraph-text.component';
import { TooltipOnEllipsisDirective } from './directives/tooltip-on-ellipsis.directive';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GenericDialogComponent } from './components/dialogs/generic-dialog/generic-dialog.component';
import { HeaderDialogComponent } from './components/dialogs/header-dialog/header-dialog.component';
import { InputLogoComponent } from './components/input-logo/input-logo.component';
import { InputLogoToggleComponent } from './components/input-logo-toggle/input-logo-toggle.component';
import { ScrollableTableComponent } from './components/scrollable-table/scrollable-table.component';
import { SelectComponent } from './components/select/universal-select/select.component';
import { OuterClickDirective } from './directives/outer-click.directive';
import { StepsHeaderComponent } from './components/steps-header/steps-header.component';
import { PasswordRequirementsComponent } from './components/password-requirements/password-requirements.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { DragAndDropUploadDirective } from './directives/drag-and-drop-upload.directive';
import { CountrySelectComponent } from './components/select/country-select/country-select.component';
import { InputButtonAppendComponent } from './components/input-button-append/input-button-append.component';
import { DetailedSelectComponent } from './components/select/detailed-select/detailed-select.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarDialogComponent } from './components/datepicker/calendar-dialog/calendar-dialog.component';
import { FeaturesService } from './services/features.service';
import { PartialValueSelectorComponent } from './components/partial-value-selector/partial-value-selector.component';
import { SimpleCopyOnClickComponent } from './components/simple-copy-on-click/simple-copy-on-click.component';
import { TodoListService } from './store/todo-list/todo-list.service';
import { FitMerchantAmountDirective } from './directives/fit-merchant-amount.directive';
import { TextWithTooltipDirective } from './components/overflown-text-with-tooltip/text-with-tooltip.component';
import { OverlayImageComponent } from './components/overlay-image/overlay-image.component';
import { LandingDialogComponent } from './components/dialogs/landing-dialog/landing-dialog.component';
import { LandingCheckboxComponent } from './components/landing-checkbox/landing-checkbox.component';
import { LandingDialogButtonComponent } from './components/landing-button/landing-dialog-button.component';
import { LandingDialogTitleComponent } from './components/dialogs/landing-dialog-title/landing-dialog-title.component';
import { PlatformCheckboxComponent } from './components/platform-checkbox/platform-checkbox.component';
import { PublicConfirmationDialogComponent } from './components/dialogs/public-confirmation-dialog/public-confirmation-dialog.component';
import { PublicConfirmationDialogTitleComponent } from './components/dialogs/public-confirmation-dialog-title/public-confirmation-dialog-title.component';
import { IdentifierPipe } from './pipes/identifier.pipe';
import { ConnectedWalletComponent } from './components/connected-wallet/connected-wallet.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatCardModule,
    MatDialogModule,
    ZXingScannerModule,
    MatDatepickerModule,
    TranslateModule,
    IdentifierPipe,
  ],
  declarations: [
    TooltipComponent,
    PayoutIntervalInputComponent,
    TwoFactorAuthForLoginComponent,
    FixedHeaderDirective,
    CopyOnClickDirective,
    ConfirmationDialogComponent,
    TextDialogComponent,
    WizardComponent,
    ThrottleClickDirective,
    FitMerchantAmountDirective,
    LoadingOverlayComponent,
    LoadingOverlayContainerComponent,
    OverlayImageComponent,
    SecuredImageComponent,
    MessageComponent,
    FileUploadDirective,
    FileDropUploadDirective,
    DisableControlDirective,
    QrScannerDialogComponent,
    ParagraphTextComponent,
    TooltipOnEllipsisDirective,
    GenericDialogComponent,
    HeaderDialogComponent,
    InputLogoComponent,
    InputLogoToggleComponent,
    ScrollableTableComponent,
    SelectComponent,
    OuterClickDirective,
    StepsHeaderComponent,
    PasswordRequirementsComponent,
    DragAndDropUploadDirective,
    CountrySelectComponent,
    InputButtonAppendComponent,
    DatepickerComponent,
    CalendarDialogComponent,
    DetailedSelectComponent,
    PartialValueSelectorComponent,
    SimpleCopyOnClickComponent,
    TextWithTooltipDirective,
    LandingDialogComponent,
    LandingCheckboxComponent,
    LandingDialogButtonComponent,
    LandingDialogTitleComponent,
    PlatformCheckboxComponent,
    PublicConfirmationDialogComponent,
    PublicConfirmationDialogTitleComponent,
    ConnectedWalletComponent,
  ],
  exports: [
    TooltipComponent,
    PayoutIntervalInputComponent,
    TwoFactorAuthForLoginComponent,
    FixedHeaderDirective,
    CopyOnClickDirective,
    ThrottleClickDirective,
    LoadingOverlayContainerComponent,
    SecuredImageComponent,
    MessageComponent,
    FileUploadDirective,
    FileDropUploadDirective,
    DisableControlDirective,
    ParagraphTextComponent,
    TooltipOnEllipsisDirective,
    GenericDialogComponent,
    HeaderDialogComponent,
    InputLogoComponent,
    InputLogoToggleComponent,
    ScrollableTableComponent,
    SelectComponent,
    StepsHeaderComponent,
    OuterClickDirective,
    PasswordRequirementsComponent,
    DragAndDropUploadDirective,
    CountrySelectComponent,
    InputButtonAppendComponent,
    DatepickerComponent,
    DetailedSelectComponent,
    PartialValueSelectorComponent,
    SimpleCopyOnClickComponent,
    FitMerchantAmountDirective,
    TextWithTooltipDirective,
    LandingDialogComponent,
    LandingCheckboxComponent,
    LandingDialogButtonComponent,
    LandingDialogTitleComponent,
    PlatformCheckboxComponent,
    ConnectedWalletComponent,
  ],
  providers: [
    RoleFactoryService,
    AuthService,
    FileUploadService,
    TranslatePipe,
    SanitizerService,
    InvoiceService,
    FeaturesService,
    CookieService,
    TodoListService,
    PayoutIntervalInputComponent,
    CanDeactivateGuard,
  ],
})
export class SharedModule {}
