export enum LanguageName {
  ENGLISH = 'English',
  CZECH = 'Česky',
  GERMAN = 'Deutsch',
  ITALIAN = 'Italiano',
  JAPANESE = '日本語',
}
export enum LanguageCode {
  ENGLISH = 'en',
  CZECH = 'cs',
  GERMAN = 'de',
  ITALIAN = 'it',
  JAPANESE = 'ja',
}
