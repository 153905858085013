import { SettlementMethodTo, SettlementMethodType } from '../../models/api/settlement-method.model';
import { ADMIN_ENDPOINT_PREFIX } from '../../constants';
import { Injectable } from '@angular/core';
import { ApiService, get2FaHeaders } from '../../services/api.service';
import { Observable } from 'rxjs';

export const SETTLEMENT_METHODS_ENDPOINT = '/settlement-methods';

@Injectable({
  providedIn: 'root',
})
export class SettlementMethodApiService {
  public readonly baseUrl: string;
  public readonly updateUrl: string;
  public readonly feeUrl: string;

  constructor(private api: ApiService) {
    this.baseUrl = `${SETTLEMENT_METHODS_ENDPOINT}/{type}`;
    this.updateUrl = `${this.baseUrl}/{id}`;
    this.feeUrl = `${SETTLEMENT_METHODS_ENDPOINT}/fee/{type}`;
  }

  create(settlementMethod: SettlementMethodTo, twoFa: string): Observable<SettlementMethodTo> {
    return this.api.post(
      this.baseUrl.replace('{type}', this.settlementMethodTypeUrl(settlementMethod.type)),
      settlementMethod,
      get2FaHeaders(twoFa)
    );
  }

  update(settlementMethod: SettlementMethodTo, twoFa: string): Observable<SettlementMethodTo> {
    return this.api.put(this.url(settlementMethod, !twoFa), settlementMethod, !twoFa ? {} : get2FaHeaders(twoFa));
  }

  getFee(settlementMethod: SettlementMethodTo): Observable<number> {
    return this.api.post(
      this.feeUrl.replace('{type}', this.settlementMethodTypeUrl(settlementMethod.type)),
      settlementMethod
    );
  }

  private url(settlementMethod: SettlementMethodTo, isAdmin: boolean): string {
    const url = this.updateUrl
      .replace('{type}', this.settlementMethodTypeUrl(settlementMethod.type))
      .replace('{id}', settlementMethod.id);
    return isAdmin ? `/${ADMIN_ENDPOINT_PREFIX}${url}` : url;
  }

  private settlementMethodTypeUrl(type: SettlementMethodType): string {
    return type === 'crypto_payment_accounts' ? 'crypto' : 'bank';
  }
}
