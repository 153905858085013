import { StateModel } from '../models/auxiliary/state.model';
import { DashboardChartModel } from '../models/api/dashboard-chart.model';
import {
  getDashboardChart,
  getDashboardChartError,
  getDashboardChartSuccess,
} from '../actions/dashboard-chart.actions';
import { createReducer, on } from '@ngrx/store';

export type DashboardChartState = StateModel<DashboardChartModel>;

const INITIAL_STATE: DashboardChartState = {
  errors: null,
  data: null,
};

export const dashboardChartReducer = createReducer(
  INITIAL_STATE,
  on(getDashboardChart, () => ({
    ...INITIAL_STATE,
  })),
  on(getDashboardChartSuccess, (state, { dashboardChartData }) => ({
    data: dashboardChartData,
    errors: null,
  })),
  on(getDashboardChartError, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
