import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../../models/api/error.model';

export enum subscriptionEnabledInfoActions {
  GET_REQUEST = 'subscription-enabled-info/GET_REQUEST',
  GET_SUCCESS = 'subscription-enabled-info/GET_SUCCESS',
  GET_ERROR = 'subscription-enabled-info/GET_ERROR',
}

export const getSubscriptionEnabledInfoAction = createAction(subscriptionEnabledInfoActions.GET_REQUEST);

export const getSubscriptionEnabledInfoSuccessAction = createAction(
  subscriptionEnabledInfoActions.GET_SUCCESS,
  props<{ isSubscriptionEnabled: boolean }>()
);

export const getSubscriptionEnabledInfoErrorAction = createAction(
  subscriptionEnabledInfoActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
