import { getMaxClickAction, maxClicksErrorAction, maxClicksSuccessAction } from '../actions/email-invoice.actions';
import { StateModel } from '../models/auxiliary/state.model';
import { ConfigurationItemModel } from '../models/api/configuration-item.model';
import { createReducer, on } from '@ngrx/store';

export type ConfigurationState = StateModel<ConfigurationItemModel>;

const INITIAL_STATE: ConfigurationState = {
  errors: null,
  data: null,
  loading: true,
};

export const MaxClicksReducer = createReducer(
  INITIAL_STATE,
  on(getMaxClickAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(maxClicksSuccessAction, (state, { maxClicksParam }) => ({
    ...state,
    errors: null,
    data: maxClicksParam,
    loading: false,
  })),
  on(maxClicksErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
