<div
  class="custom-select-container"
  bpOuterClick
  (outerClick)="listShown = false"
  [ngClass]="{ redesigned: redesigned }"
>
  <input
    #input
    readonly
    class="confirmo-platform-select country-select-input"
    [ngStyle]="{ 'width.px': minWidth }"
    [value]="this.selected?.name"
    (keydown)="setOptionByArrowButton($event)"
    (click)="toggleOptionList()"
    (focusin)="setFocus(true)"
    (focusout)="setFocus(false)"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    [ngClass]="{
      'input-centered': this.centered,
      'confirmo-platform-select__high': this.isHigh,
      'confirmo-platform-select__low': !this.isHigh,
      'show-icon': selected,
      'custom-select-normal-font-weight': this.normalFontWeight,
      invalid: !selected && showInvalidTooltip
    }"
  />
  <div *ngIf="redesigned" class="country-select-icon-container" (click)="focusInput()">
    <span
      [ngClass]="{ invalid: !selected && showInvalidTooltip }"
      class="{{ selected ? currencyIconService.getFlagIcon(selected.alpha2) : 'compass' }}"
    >
    </span>
  </div>
  <div *ngIf="!redesigned && selected" class="country-select-icon-container" (click)="focusInput()">
    <span
      [ngClass]="{ 'country-icon-disabled': !selected.enabled }"
      class="{{ currencyIconService.getFlagIcon(selected.alpha2) }}"
    >
    </span>
  </div>
  <div class="confirmo-input-invalid-tooltip__no-prepend country-select-invalid-tooltip" *ngIf="showInvalidTooltip">
    <ng-container *ngIf="this.selected; else countryNotSet">
      <span *ngIf="isDisabledOptionSet">Your country is not supported</span>
    </ng-container>
    <ng-template #countryNotSet>Select your country</ng-template>
  </div>
  <div
    [ngClass]="{
      'input-centered': this.centered,
      'custom-select-options-list': this.listShown,
      'custom-select-options-list__hidden': !this.listShown
    }"
    [ngStyle]="{ 'width.px': minWidth }"
  >
    <div class="custom-select-options-container" #optionsContainer>
      <div *ngIf="countries.length === 0">{{ noItemsToShowText }}</div>
      <div
        *ngFor="let t of countries"
        class="custom-select-option country-select-option"
        (click)="t.enabled ? setOption(t) : null"
        [id]="idPrefix + t.alpha2 | bpIdentifier"
        [ngClass]="{
          'option-selected': isOptionSelected(t),
          'option-disabled': !t.enabled
        }"
      >
        <div class="country-select-icon">
          <span
            [ngClass]="{ 'country-select-icon-disabled': !t.enabled }"
            class="{{ currencyIconService.getFlagIcon(t.alpha2) }}"
          >
          </span>
        </div>
        {{ t.name }}
      </div>
    </div>
  </div>
</div>
