import { StateModel } from '../models/auxiliary/state.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { MarketOperationModel } from '../models/api/market-operation.model';
import { createReducer, on } from '@ngrx/store';
import { getMarketPageErrorAction, getMarketPageSuccessAction } from '../actions/market-page.actions';

export type MarketPageState = StateModel<PagingModel<MarketOperationModel>>;

const INITIAL_STATE: MarketPageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
};

export const marketPageReducer = createReducer(
  INITIAL_STATE,
  on(getMarketPageSuccessAction, (state, { marketOrders }) => ({
    errors: null,
    data: marketOrders,
  })),
  on(getMarketPageErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
