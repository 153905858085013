import { StateModel } from '../models/auxiliary/state.model';
import { InvoiceExpirationFeeModel } from '../models/api/invoice-expiration-fee.model';
import {
  getInvoiceExpirationFeeAction,
  getInvoiceExpirationFeeAdminAction,
  getInvoiceExpirationFeeErrorAction,
  getInvoiceExpirationFeeSuccessAction,
} from '../actions/invoice-expiration-fee.actions';
import { createReducer, on } from '@ngrx/store';

export type InvoiceExpirationFeeState = StateModel<InvoiceExpirationFeeModel>;

const INITIAL_STATE: InvoiceExpirationFeeState = {
  errors: null,
  data: null,
};

export const InvoiceExpirationFeeReducer = createReducer(
  INITIAL_STATE,
  on(getInvoiceExpirationFeeAction, getInvoiceExpirationFeeAdminAction, (state) => ({
    ...state,
    errors: null,
  })),
  on(getInvoiceExpirationFeeSuccessAction, (state, { invoiceFee }) => ({
    ...state,
    data: invoiceFee,
  })),
  on(getInvoiceExpirationFeeErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
