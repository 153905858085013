import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_VERSION } from '../../shared/constants';
import {
  OtherSideTransaction,
  OtherSideTransactionForAdmin,
  OtherSideTransactionLimits,
} from '../../shared/models/api/other-side-transaction.model';
import { PagingModel } from '../../shared/models/auxiliary/paging.model';
import { ApiService, get2FaHeaders } from '../../shared/services/api.service';
import { queryParams } from '../../shared/utils';
import { PageRequestFiltersModel } from '../../shared/models/auxiliary/page-request.model';

@Injectable({
  providedIn: 'root',
})
export class OtherSideTransactionService {
  public readonly transactionDetailUrl: string;
  public readonly adminTransactionDetailUrl: string;
  public readonly osTransactionUrl: string;
  public readonly osTransaction2FAUrl: string;
  public readonly adminTransactionHistoryUrl: string;
  public readonly withdrawRetryAdminUrl: string;
  public readonly confirmTransactionUrl: string;
  public readonly cancelTransactionUrl: string;
  public readonly transactionLimitsUrl: string;
  public readonly payoutAdminCsvExportUrl: string;
  public readonly payoutMerchantCsvExportUrl: string;

  constructor(private api: ApiService) {
    this.transactionDetailUrl = `/${API_VERSION}/payouts/{transactionId}`;
    this.adminTransactionDetailUrl = `/admin/payouts/{transactionId}`;
    this.withdrawRetryAdminUrl = '/admin/payouts/{transactionId}/withdraw/retry';
    this.osTransactionUrl = `/${API_VERSION}/payouts`;
    this.osTransaction2FAUrl = `/payouts`;
    this.adminTransactionHistoryUrl = '/admin/payouts';
    this.confirmTransactionUrl = '/payouts/confirm';
    this.cancelTransactionUrl = '/payouts/cancel';
    this.transactionLimitsUrl = '/payouts/limits';
    this.payoutAdminCsvExportUrl = `${this.adminTransactionHistoryUrl}/export`;
    this.payoutMerchantCsvExportUrl = `${this.osTransactionUrl}/export`;
  }

  public get(id: string, isAdmin: boolean = false): Observable<OtherSideTransaction | OtherSideTransactionForAdmin> {
    let url = isAdmin ? this.adminTransactionDetailUrl : this.transactionDetailUrl;

    url = url.replace('{transactionId}', id);

    return this.api.get(url);
  }

  public getOsTransactionHistory(
    isAdmin: boolean = false,
    paging: any,
    filters: PageRequestFiltersModel
  ): Observable<PagingModel<OtherSideTransactionForAdmin>> {
    let url = isAdmin ? this.adminTransactionHistoryUrl : this.osTransactionUrl;
    const params: any = { ...paging, ...filters };
    url = url + `${queryParams(params)}`;

    return this.api.get(url);
  }

  public postPayoutHistoryExport(
    isAdmin: boolean = false,
    paging: any,
    filters: PageRequestFiltersModel,
    uuid: string
  ): Observable<PagingModel<OtherSideTransactionForAdmin>> {
    let url = isAdmin ? this.payoutAdminCsvExportUrl : this.payoutMerchantCsvExportUrl;
    const params: any = { ...paging, ...filters, uuid: uuid };
    url = url + `${queryParams(params)}`;

    return this.api.post(url);
  }

  public withdrawRetry(id: string): Observable<OtherSideTransaction | OtherSideTransactionForAdmin> {
    return this.api.post(this.withdrawRetryAdminUrl.replace('{transactionId}', id));
  }

  public create(osTransaction: OtherSideTransaction): Observable<OtherSideTransaction> {
    return this.api.post(this.osTransactionUrl, osTransaction);
  }

  public create2FA(osTransaction: OtherSideTransaction, twoFaCode: string): Observable<OtherSideTransaction> {
    return this.api.post(this.osTransaction2FAUrl, osTransaction, get2FaHeaders(twoFaCode));
  }

  public confirmTransaction(hashId: string, twoFaCode: string): Observable<OtherSideTransaction> {
    return this.api.patch(`${this.confirmTransactionUrl}/${hashId}`, null, get2FaHeaders(twoFaCode));
  }

  public cancelTransaction(hashId: string): Observable<OtherSideTransaction> {
    return this.api.patch(`${this.cancelTransactionUrl}/${hashId}`);
  }

  public getLimits(): Observable<OtherSideTransactionLimits[]> {
    return this.api.get(this.transactionLimitsUrl);
  }
}
