/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { LedgerFiltersModel } from './ledger-filters.model';

export enum LedgerFiltersActions {
  GET = 'ledgerFilters/GET',
  UPDATE = 'ledgerFilters/UPDATE',
}

export const getLedgerFiltersAction = createAction(LedgerFiltersActions.GET);

export const updateLedgerFiltersAction = createAction(
  LedgerFiltersActions.UPDATE,
  props<{ filters: LedgerFiltersModel }>()
);
