/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';

export enum MerchantProfileActions {
  GET_REQUEST = 'merchantProfile/GET_REQUEST',
  GET_SUCCESS = 'merchantProfile/GET_SUCCESS',
  GET_ERROR = 'merchantProfile/GET_ERROR',
  GET_ADMIN_REQUEST = 'merchantPerson/GET_ADMIN_REQUEST',
  GET_ADMIN_SUCCESS = 'merchantPerson/GET_ADMIN_SUCCESS',
  GET_ADMIN_ERROR = 'merchantPerson/GET_ADMIN_ERROR',
  UPDATE_REQUEST = 'merchantProfile/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'merchantProfile/UPDATE_SUCCESS',
  UPDATE_ERROR = 'merchantProfile/UPDATE_ERROR',
}

export const getMerchantProfileAction = createAction(MerchantProfileActions.GET_REQUEST);

export const getMerchantProfileSuccessAction = createAction(
  MerchantProfileActions.GET_SUCCESS,
  props<{ merchantProfile: MerchantProfileUnionModel }>()
);

export const getMerchantProfileErrorAction = createAction(
  MerchantProfileActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const getMerchantProfileAsAdminAction = createAction(
  MerchantProfileActions.GET_ADMIN_REQUEST,
  props<{ id: any }>()
);

export const getMerchantProfileAsAdminSuccessAction = createAction(
  MerchantProfileActions.GET_ADMIN_SUCCESS,
  props<{ merchantProfile: MerchantProfileUnionModel }>()
);

export const getMerchantProfileAsAdminErrorAction = createAction(
  MerchantProfileActions.GET_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateMerchantProfileAction = createAction(
  MerchantProfileActions.UPDATE_REQUEST,
  props<{ merchantProfile: MerchantProfileUnionModel }>()
);

export const updateMerchantProfileSuccessAction = createAction(
  MerchantProfileActions.UPDATE_SUCCESS,
  props<{ updatedMerchantProfile: MerchantProfileUnionModel }>()
);

export const updateMerchantProfileErrorAction = createAction(
  MerchantProfileActions.UPDATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);
