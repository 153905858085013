/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ApiKeyModel } from '../models/api/api-key.model';
import { ErrorModel } from '../models/api/error.model';

export enum ApiKeyListActionTypes {
  GET_REQUEST = 'apiKeyList/GET_REQUEST',
  GET_SUCCESS = 'apiKeyList/GET_SUCCESS',
  GET_ERROR = 'apiKeyList/GET_ERROR',
}

export const getApiKeyListAction = createAction(ApiKeyListActionTypes.GET_REQUEST, props<{ merchantHashId: string }>());

export const getApiKeyListSuccessAction = createAction(
  ApiKeyListActionTypes.GET_SUCCESS,
  props<{ apiKeys: ApiKeyModel[] }>()
);

export const getApiKeyListErrorAction = createAction(
  ApiKeyListActionTypes.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
