/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { UserRole } from '../enums/user-role.enum';
import { ErrorModel } from '../models/api/error.model';
import { UserModel } from '../models/api/user.model';

export enum UserDetailActionTypes {
  GetUserDetail = 'userDetail/GET_REQUEST',
  GetUserDetailSuccess = 'userDetail/GET_SUCCESS',
  GetUserDetailError = 'userDetail/GET_ERROR',
  CreateUser = 'userDetail/CREATE_USER_REQUEST',
  CreateUserAsAdmin = 'userDetail/CREATE_USER_AS_ADMIN_REQUEST',
  CreateUserSuccess = 'userDetail/CREATE_USER_SUCCESS',
  CreateUserError = 'userDetail/Create_USER_ERROR',
  ClearUserDetailState = 'userDetail/CLEAR_USER_DETAIL_STATE',
  updateMerchantPopupForm = 'userDetail/UPDATE_MERCHANT_POPUP',
  updateMerchantPopupFormSuccess = 'userDetail/UPDATE_MERCHANT_POPUP_SUCCESS',
  updateMerchantPopupFormError = 'userDetail/UPDATE_MERCHANT_POPUP_ERROR',
}

export const getUserDetailAction = createAction(UserDetailActionTypes.GetUserDetail, props<{ id: string }>());

export const getUserDetailSuccessAction = createAction(
  UserDetailActionTypes.GetUserDetailSuccess,
  props<{ user: UserModel }>()
);

export const getUserDetailErrorAction = createAction(
  UserDetailActionTypes.GetUserDetailError,
  props<{ errors: ErrorModel[] }>()
);

export const createUserAction = createAction(
  UserDetailActionTypes.CreateUser,
  props<{ user: { email: string; role: UserRole }; twoFaCode: string }>()
);

export const createUserAsAdminAction = createAction(
  UserDetailActionTypes.CreateUserAsAdmin,
  props<{ user: { email: string; role: UserRole }; merchantId: string }>()
);

export const createUserSuccessAction = createAction(
  UserDetailActionTypes.CreateUserSuccess,
  props<{ user: UserModel }>()
);

export const createUserErrorAction = createAction(
  UserDetailActionTypes.CreateUserError,
  props<{ errors: ErrorModel[] }>()
);

export const clearUserDetailStateAction = createAction(UserDetailActionTypes.ClearUserDetailState);

export const updateMerchantPopupFormAction = createAction(
  UserDetailActionTypes.updateMerchantPopupForm,
  props<{ popupFilled: boolean; user: UserModel }>()
);

export const updateMerchantPopupFormSuccessAction = createAction(UserDetailActionTypes.updateMerchantPopupFormSuccess);

export const updateMerchantPopupFormErrorAction = createAction(
  UserDetailActionTypes.updateMerchantPopupFormError,
  props<{ errors: ErrorModel[] }>()
);
