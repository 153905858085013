import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class SaveHttpBlobService {
  static UNKNOWN_FILE_NAME = 'UnknownFile';

  public saveToFileSystem(blobResponse: HttpResponse<Blob>): void {
    const contentDispositionHeader = blobResponse.headers.get('Content-Disposition');
    const filename = this.extractFileName(contentDispositionHeader);
    const blob = new Blob([blobResponse.body], { type: blobResponse.headers.get('Content-Type') });

    FileSaver.saveAs(blob, filename);
  }

  public generateFileUrl(blobResponse: HttpResponse<Blob>): string {
    const blob = new Blob([blobResponse.body], { type: blobResponse.headers.get('Content-Type') });
    return URL.createObjectURL(blob);
  }

  private extractFileName(contentDispositionHeader: string): string {
    if (!contentDispositionHeader) {
      return SaveHttpBlobService.UNKNOWN_FILE_NAME;
    }

    const matches = contentDispositionHeader
      .replace(/"/g, "'") // replace double quotes " with single ones ' so both are accepted
      .match(/filename='(.+)'/);

    if (!matches || !matches[1]) {
      return SaveHttpBlobService.UNKNOWN_FILE_NAME;
    }

    return matches[1];
  }
}
