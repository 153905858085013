import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getFiatPaymentMethodsAction,
  getFiatPaymentMethodsErrorAction,
  getFiatPaymentMethodsSuccessAction,
} from './fiat-payment-methods.actions';
import { queryParams } from '../../utils';
import { ApiService } from '../../services/api.service';

export const FIAT_PAYMENT_METHODS_ENDPOINT = '/fiat-payment-methods';

@Injectable()
export class FiatPaymentMethodsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFiatPaymentMethodsAction),
      mergeMap(({ settlementEnabled }) =>
        this.api.get(this.url(settlementEnabled)).pipe(
          map((paymentMethods) => getFiatPaymentMethodsSuccessAction({ paymentMethods })),
          catchError((errors) => of(getFiatPaymentMethodsErrorAction(errors)))
        )
      )
    )
  );

  url(settlementEnabled?: boolean): string {
    return `${FIAT_PAYMENT_METHODS_ENDPOINT}${queryParams({
      ...(settlementEnabled && { settlementEnabled: settlementEnabled }),
    })}`;
  }

  constructor(private actions$: Actions, private api: ApiService) {}
}
