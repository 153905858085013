import {
  hideMarketOperationAction,
  hideMarketOperationErrorAction,
  hideMarketOperationSuccessAction,
  updateMarketOrderStatusAction,
  updateMarketOrderStatusErrorAction,
  updateMarketOrderStatusSuccessAction,
} from '../actions/market-operation.actions';
import { MarketOperationModel } from '../models/api/market-operation.model';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';

export type MarketOperationState = StateModel<MarketOperationModel>;

const INITIAL_STATE: MarketOperationState = {
  errors: null,
  data: null,
  loading: false,
};

export const marketOperationReducer = createReducer(
  INITIAL_STATE,
  on(updateMarketOrderStatusAction, hideMarketOperationAction, () => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(hideMarketOperationSuccessAction, (state) => ({
    ...state,
    errors: null,
    loading: false,
  })),
  on(updateMarketOrderStatusErrorAction, hideMarketOperationErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
  on(updateMarketOrderStatusSuccessAction, (state, { marketOperation }) => ({
    data: marketOperation,
    errors: null,
    loading: false,
  }))
);
