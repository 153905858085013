/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantModel } from '../models/api/merchant.model';

export enum merchantDetailActions {
  GET_REQUEST = 'merchantDetail/GET_REQUEST',
  GET_SUCCESS = 'merchantDetail/GET_SUCCESS',
  GET_ERROR = 'merchantDetail/GET_ERROR',
}

export const getMerchantDetailAction = createAction(merchantDetailActions.GET_REQUEST, props<{ merchantId: string }>());

export const getMerchantDetailSuccessAction = createAction(
  merchantDetailActions.GET_SUCCESS,
  props<{ merchant: MerchantModel }>()
);

export const getMerchantDetailErrorAction = createAction(
  merchantDetailActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
