import { SettlementMethodTo } from '../../models/api/settlement-method.model';
import { StateModel } from '../../models/auxiliary/state.model';
import { PagingModel } from '../../models/auxiliary/paging.model';
import {
  getSettlementMethodPageErrorAction,
  getSettlementMethodPageSuccessAction,
} from './settlement-method-page.actions';
import { createReducer, on } from '@ngrx/store';

export type SettlementMethodPageState = StateModel<PagingModel<SettlementMethodTo>>;

const INITIAL_STATE: SettlementMethodPageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: null,
  },
};

export const settlementMethodPageReducer = createReducer(
  INITIAL_STATE,
  on(getSettlementMethodPageSuccessAction, (state, { settlements }) => ({
    errors: null,
    data: settlements,
  })),
  on(getSettlementMethodPageErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
