import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { CompanyMerchantProfileModel } from '../../../../shared/models/api/merchant-profile/company-merchant-profile.model';
import { AppStateModel } from '../../../../shared/models/auxiliary/app-state.model';
import { AbstractComponent } from '../../../../shared/components/abstract.component';
import { MessageService } from '../../../../shared/services/message.service';
import { PersonModel } from '../../../../shared/models/api/merchant-profile/person-model';
import { PersonAddDialogComponent } from './person-add-dialog/person-add-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CloseReason, DialogResult } from '../../../../shared/components/dialogs/dialog-result';
import { wizardNextStepAction, wizardStepBackAction } from '../../../../shared/actions/wizard.actions';
import { DIALOG_CONFIG_WITH_AUTOFOCUS } from '../../../../shared/dialogs-utils';
import {
  deleteMerchantPersonAction,
  deleteMerchantPersonErrorAction,
  deleteMerchantPersonSuccessAction,
  updateFlagsAction,
  updateFlagsErrorAction,
} from '../../../../shared/actions/merchant-person.actions';
import { getMerchantProfileAction } from '../../../../shared/actions/merchant-profile.actions';
import { selectMerchantProfile } from '../../../../shared/selectors/merchant-profile.selectors';

@Component({
  selector: 'bp-merchant-persons',
  templateUrl: './merchant-persons.component.html',
  styleUrls: ['./merchant-persons.component.scss'],
})
export class MerchantPersonsComponent extends AbstractComponent {
  merchantProfile: CompanyMerchantProfileModel;
  tableDataSource = new MatTableDataSource<PersonModel>();

  displayedColumns = ['name', 'pep', 'ubo', 'us', 'remove'];

  constructor(
    private store: Store<AppStateModel>,
    private actions: Actions,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {
    super();

    this.store
      .select(selectMerchantProfile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'Merchant Verification Info Error');
          return;
        }

        this.merchantProfile = state.data as CompanyMerchantProfileModel;

        if (this.merchantProfile) {
          // Force table to rerender content
          this.tableDataSource.data = null;
          this.tableDataSource.data = this.merchantProfile.persons || [];
        }
      });

    this.actions.pipe(ofType(updateFlagsErrorAction), takeUntil(this.unsubscribe$)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, 'Merchant Person Update Error');
    });

    this.actions.pipe(ofType(deleteMerchantPersonSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.messageService.success('Person successfully deleted');
    });

    this.actions
      .pipe(ofType(deleteMerchantPersonErrorAction), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => this.messageService.showErrors(errors, 'Person Delete Error'));

    this.store.dispatch(getMerchantProfileAction());
  }

  saveFlags(person: PersonModel): void {
    this.store.dispatch(updateFlagsAction({ flags: person.flags, personId: person.id }));
  }

  addPerson(): void {
    this.dialog.open(PersonAddDialogComponent, DIALOG_CONFIG_WITH_AUTOFOCUS);
  }

  remove(person: PersonModel): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...DIALOG_CONFIG_WITH_AUTOFOCUS,
      data: {
        confirmMessage: `Do you want to delete person ${person.firstName} ${person.lastName}?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: DialogResult) => {
      if (result.reason === CloseReason.SUCCESS) {
        this.store.dispatch(deleteMerchantPersonAction({ person: person }));
      }
    });
  }

  get tooltipPep(): String {
    return (
      'PEP - Politically exposed Person is an individual that holds a prominent public position or function' +
      'or an individual who has close relations with a PEP whether it is socially or professionally.'
    );
  }

  get tooltipUbu(): String {
    return (
      'Ultimate Beneficiary owner - the natural person(s) who ultimately owns or controls a customer and/or' +
      'the natural person on whose behalf a transaction is being conducted.'
    );
  }

  get tooltipUs(): String {
    return (
      'Any United States citizen or person admitted for permanent residence in the United States, and any' +
      'corporation, partnership, or other organization organized under the laws of the United States.'
    );
  }

  public nextStep(): void {
    this.store.dispatch(wizardNextStepAction());
  }

  public stepBack(): void {
    this.store.dispatch(wizardStepBackAction());
  }
}
