import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getPublicPayoutFeesAction,
  getPublicPayoutFeesActionError,
  getPublicPayoutFeesActionSuccess,
} from './payout-fees-public.actions';

export const PAYOUT_FEES_PUBLIC_ENDPOINT = '/payouts/fees';

@Injectable()
export class PayoutFeesPublicEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPublicPayoutFeesAction),
      mergeMap(() =>
        this.api.get(PAYOUT_FEES_PUBLIC_ENDPOINT).pipe(
          map((payoutFees) => getPublicPayoutFeesActionSuccess({ payoutFees })),
          catchError((errors) => of(getPublicPayoutFeesActionError(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
