import { interval, Subject } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AppStateModel } from '../../models/auxiliary/app-state.model';
import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractIntervalRequestService<T> implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();
  protected calledAlready = false;
  protected interval$;

  protected constructor(protected store: Store<AppStateModel>) {}

  protected start(dispatchAction: Action, milliseconds: number, takeWhileCondition: () => boolean = () => true): void {
    this.store.dispatch(dispatchAction);
    this.interval$ = interval(milliseconds)
      .pipe(
        // eslint-disable-next-line rxjs/no-ignored-takewhile-value
        takeWhile(() => takeWhileCondition()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.store.dispatch(dispatchAction);
      });
  }

  public finish(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // define new one
    this.unsubscribe$ = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
