import { hideLoaderAction, showLoaderAction } from '../actions/loader.actions';
import { createReducer, on } from '@ngrx/store';

export interface LoaderState {
  showLoaderRequests: number;
}

export const initialState: LoaderState = {
  showLoaderRequests: 0,
};

export const loaderReducer = createReducer(
  initialState,
  on(showLoaderAction, (state) => ({
    ...state,
    showLoaderRequests: state.showLoaderRequests + 1,
  })),
  on(hideLoaderAction, (state) => ({
    ...state,
    showLoaderRequests: Math.max(state.showLoaderRequests - 1, 0), // prevent negative numbers errors
  }))
);
