import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, get2FaHeaders } from '../../services/api.service';
import { RefundCreationTo } from '../../models/api/refund-creation.model';
import { ADMIN_ENDPOINT_PREFIX } from '../../constants';
import { RefundTo } from '../../models/api/refund.model';
import { INVOICE_ENDPOINT } from '../../effects/invoice.effect';
import { PagingModel } from '../../models/auxiliary/paging.model';
import { PageRequestModel } from '../../models/auxiliary/page-request.model';
import { queryParams } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class RefundApiService {
  public readonly baseUrl: string;
  public readonly createRefundUrl: string;
  public readonly createRefundAdminUrl;
  public readonly createRefundMerchantUrl: string;
  public readonly markRefundAsRefundedUrl: string;
  public readonly sendRefundUrl: string;
  public readonly updateRefundCryptoAddressUrl: string;
  public readonly getRefundPageUrl: string;

  constructor(private api: ApiService) {
    this.baseUrl = `/${ADMIN_ENDPOINT_PREFIX}/refunds`;
    this.createRefundUrl = `/${INVOICE_ENDPOINT}/{invoiceId}/refund`;
    this.createRefundAdminUrl = `/${ADMIN_ENDPOINT_PREFIX}${this.createRefundUrl}`;
    this.createRefundMerchantUrl = `/${INVOICE_ENDPOINT}/merchant-refund/{invoiceId}`;
    this.markRefundAsRefundedUrl = `${this.baseUrl}/{id}`;
    this.sendRefundUrl = `${this.baseUrl}/send`;
    this.updateRefundCryptoAddressUrl = `${this.baseUrl}/{refundHashId}/address`;
    this.getRefundPageUrl = `${this.baseUrl}{params}`;
  }

  public createRefundPublic(id: string, body: RefundCreationTo): Observable<RefundTo> {
    const url = this.createRefundUrl.replace('{invoiceId}', id);
    return this.api.post(url, body);
  }

  public createRefundAdmin(id: string, body: RefundCreationTo): Observable<RefundTo> {
    const url = this.createRefundAdminUrl.replace('{invoiceId}', id);
    return this.api.post(url, body);
  }

  public createRefundMerchant(id: string, body: RefundCreationTo, twoFaToken: string): Observable<RefundTo> {
    const url = this.createRefundMerchantUrl.replace('{invoiceId}', id);
    return this.api.post(url, body, get2FaHeaders(twoFaToken));
  }

  public markRefundAsRefunded(id: string, body: { txid: string }): Observable<RefundTo> {
    const url = this.markRefundAsRefundedUrl.replace('{id}', id);
    return this.api.patch(url, body);
  }

  public sendRefunds(body: string[]): Observable<RefundTo> {
    return this.api.post(this.sendRefundUrl, body);
  }

  public updateRefundCryptoAddress(newCryptoAddress: string, refundHashId: string): Observable<RefundTo> {
    const url = this.updateRefundCryptoAddressUrl.replace('{refundHashId}', refundHashId);
    return this.api.patch(url, { newCryptoAddress: newCryptoAddress });
  }

  public getRefundPage(paging: PageRequestModel): Observable<PagingModel<RefundTo>> {
    const url = this.getRefundPageUrl.replace('{params}', queryParams({ ...paging }));
    return this.api.get(url);
  }
}
