import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';
import {
  getMerchantApiVersionAction,
  getMerchantApiVersionActionError,
  getMerchantApiVersionActionSuccess,
} from '../actions/merchant-api-version.actions';

export type ApiVersionState = StateModel<number>;

const INITIAL_STATE: ApiVersionState = {
  errors: null,
  data: null,
};

export const merchantApiVersionReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantApiVersionAction, () => ({ ...INITIAL_STATE })),
  on(getMerchantApiVersionActionSuccess, (state, { version }) => ({
    data: version,
    errors: null,
  })),
  on(getMerchantApiVersionActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
