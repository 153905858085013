import { createAction, props } from '@ngrx/store';
import { PersonModel } from '../../models/api/merchant-profile/person-model';
import { ErrorModel } from '../../models/api/error.model';

export enum MerchantDirectorsActions {
  GET_LIST_REQUEST = 'merchantDirectors/GET_LIST_REQUEST',
  GET_LIST_SUCCESS = 'merchantDirectors/GET_LIST_SUCCESS',
  GET_LIST_ERROR = 'merchantDirectors/GET_LIST_ERROR',

  CREATE_REQUEST = 'merchantDirectors/CREATE_REQUEST',
  CREATE_SUCCESS = 'merchantDirectors/CREATE_SUCCESS',
  CREATE_ERROR = 'merchantDirectors/CREATE_ERROR',

  UPDATE_REQUEST = 'merchantDirectors/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'merchantDirectors/UPDATE_SUCCESS',
  UPDATE_ERROR = 'merchantDirectors/UPDATE_ERROR',

  DELETE_REQUEST = 'merchantDirectors/DELETE_REQUEST',
  DELETE_SUCCESS = 'merchantDirectors/DELETE_SUCCESS',
  DELETE_ERROR = 'merchantDirectors/DELETE_ERROR',
}

export const getMerchantDirectorsListAction = createAction(MerchantDirectorsActions.GET_LIST_REQUEST);

export const getMerchantDirectorsListSuccessAction = createAction(
  MerchantDirectorsActions.GET_LIST_SUCCESS,
  props<{ merchantDirectors: PersonModel[] }>()
);

export const getMerchantDirectorsListErrorAction = createAction(
  MerchantDirectorsActions.GET_LIST_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createMerchantDirectorAction = createAction(
  MerchantDirectorsActions.CREATE_REQUEST,
  props<{ merchantDirector: PersonModel }>()
);

export const createMerchantDirectorSuccessAction = createAction(
  MerchantDirectorsActions.CREATE_SUCCESS,
  props<{ merchantDirector: PersonModel }>()
);

export const createMerchantDirectorErrorAction = createAction(
  MerchantDirectorsActions.CREATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateMerchantDirectorAction = createAction(
  MerchantDirectorsActions.UPDATE_REQUEST,
  props<{ merchantDirector: PersonModel; isAdmin: boolean }>()
);

export const updateMerchantDirectorSuccessAction = createAction(
  MerchantDirectorsActions.UPDATE_SUCCESS,
  props<{ merchantDirector: PersonModel }>()
);

export const updateMerchantDirectorErrorAction = createAction(
  MerchantDirectorsActions.UPDATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const deleteMerchantDirectorAction = createAction(
  MerchantDirectorsActions.DELETE_REQUEST,
  props<{ merchantDirectorId: string }>()
);

export const deleteMerchantDirectorSuccessAction = createAction(
  MerchantDirectorsActions.DELETE_SUCCESS,
  props<{ merchantDirectorId: string }>()
);

export const deleteMerchantDirectorErrorAction = createAction(
  MerchantDirectorsActions.DELETE_ERROR,
  props<{ errors: ErrorModel[] }>()
);
