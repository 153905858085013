/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { MerchantDocument } from '../models/api/merchant-profile/merchant-document.model';
import { MerchantFileVerificationStatus } from '../enums/merchant-file-verification-status.enum';
import { ErrorModel } from '../models/api/error.model';

export enum MerchantVerificationDocumentsActionTypes {
  GetMerchantVerificationDocuments = 'merchantVerificationDocuments/GET_DOCUMENTS_REQUEST',
  GetMerchantVerificationDocumentsSuccess = 'merchantVerificationDocuments/GET_DOCUMENTS_SUCCESS',
  MerchantVerificationDocumentsError = 'merchantVerificationDocuments/DOCUMENTS_ERROR',
  UpdateMerchantVerificationDocumentStatus = 'merchantVerificationDocuments/UPDATE_DOCUMENT_STATUS_REQUEST',
  UpdateMerchantVerificationDocument = 'merchantVerificationDocuments/UPDATE_SINGLE_DOCUMENT',
  AdminUploadMerchantVerificationDocuments = 'merchantVerificationDocuments/POST_DOCUMENTS_REQUEST',
  AdminUploadMerchantVerificationDocumentsSuccess = 'merchantVerificationDocuments/POST_DOCUMENTS_SUCCESS',
  AdminUploadMerchantVerificationDocumentsError = 'merchantVerificationDocuments/POST_DOCUMENTS_ERROR',
}

export const getMerchantVerificationDocumentsAction = createAction(
  MerchantVerificationDocumentsActionTypes.GetMerchantVerificationDocuments,
  props<{ merchant: MerchantProfileUnionModel }>()
);

export const getMerchantVerificationDocumentsSuccessAction = createAction(
  MerchantVerificationDocumentsActionTypes.GetMerchantVerificationDocumentsSuccess,
  props<{ documents: MerchantDocument[] }>()
);

export const getMerchantVerificationDocumentsErrorAction = createAction(
  MerchantVerificationDocumentsActionTypes.MerchantVerificationDocumentsError,
  props<{ errors: ErrorModel[] }>()
);

export const updateMerchantVerificationDocumentStatusAction = createAction(
  MerchantVerificationDocumentsActionTypes.UpdateMerchantVerificationDocumentStatus,
  props<{ file: MerchantDocument; status: MerchantFileVerificationStatus; merchant: MerchantProfileUnionModel }>()
);

export const updateMerchantVerificationDocumentAction = createAction(
  MerchantVerificationDocumentsActionTypes.UpdateMerchantVerificationDocument,
  props<{ file: MerchantDocument }>()
);

export const adminUploadMerchantVerificationDocumentsAction = createAction(
  MerchantVerificationDocumentsActionTypes.AdminUploadMerchantVerificationDocuments,
  props<{ readonly file: FormData; merchant: MerchantProfileUnionModel }>()
);

export const adminUploadMerchantVerificationDocumentsSuccessAction = createAction(
  MerchantVerificationDocumentsActionTypes.AdminUploadMerchantVerificationDocumentsSuccess,
  props<{ documents: MerchantDocument[] }>()
);

export const adminUploadMerchantVerificationDocumentsErrorAction = createAction(
  MerchantVerificationDocumentsActionTypes.AdminUploadMerchantVerificationDocumentsError,
  props<{ errors: ErrorModel[] }>()
);
