import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService, get2FaHeaders } from '../services/api.service';
import {
  deleteCallbackPasswordAction,
  deleteCallbackPasswordErrorAction,
  deleteCallbackPasswordSuccessAction,
  getCallbackPasswordAction,
  getCallbackPasswordErrorAction,
  getCallbackPasswordSuccessAction,
} from '../actions/callback-password.actions';

export const CALLBACK_PASSWORD_ENDPOINT = '/user/callback-password';

@Injectable()
export class CallbackPasswordEffect {
  getCallbackPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCallbackPasswordAction),
      mergeMap((action) =>
        this.api.get(CALLBACK_PASSWORD_ENDPOINT).pipe(
          map((callbackPassword) => getCallbackPasswordSuccessAction({ callbackPassword })),
          catchError((errors) => observableOf(getCallbackPasswordErrorAction(errors)))
        )
      )
    )
  );

  deleteCallbackPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCallbackPasswordAction),
      mergeMap(({ twoFACode }) =>
        this.api.delete(CALLBACK_PASSWORD_ENDPOINT, get2FaHeaders(twoFACode)).pipe(
          map((response) => deleteCallbackPasswordSuccessAction()),
          catchError((errors) => observableOf(deleteCallbackPasswordErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
