import { StateModel } from '../models/auxiliary/state.model';
import { HttpResponse } from '@angular/common/http';
import {
  getPdfSettlementAction,
  getPdfSettlementErrorAction,
  getPdfSettlementSuccessAction,
} from '../actions/pdf-settlement.action';
import { createReducer, on } from '@ngrx/store';

export type PdfSettlementState = StateModel<HttpResponse<Blob>>;

const INITIAL_STATE: PdfSettlementState = {
  errors: null,
  data: null,
};

export const pdfSettlementReducer = createReducer(
  INITIAL_STATE,
  on(getPdfSettlementAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getPdfSettlementSuccessAction, (state, { pdfSettlementResponse }) => ({
    ...state,
    data: pdfSettlementResponse,
  })),
  on(getPdfSettlementErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
