import { Observable, of as observableOf } from 'rxjs';

import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiService } from '../services/api.service';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { getUserAction, getUserCachedAction, getUserErrorAction, getUserSuccessAction } from '../actions/user.actions';
import { selectUser } from '../selectors/user.selector';

const USER_ENDPOINT = '/user';

@Injectable()
export class UserEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserAction),
      mergeMap((action) => this.getUserRequest())
    )
  );

  getCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserCachedAction),
      mergeMap((action) =>
        this.store.select(selectUser).pipe(
          take(1),
          mergeMap(({ data }) =>
            data != null ? observableOf(getUserSuccessAction({ user: data })) : this.getUserRequest()
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService, private store: Store<AppStateModel>) {}

  getUserRequest(): Observable<any> {
    return this.api.get(USER_ENDPOINT).pipe(
      map((user) => getUserSuccessAction({ user })),
      catchError((errors) => observableOf(getUserErrorAction(errors)))
    );
  }
}
