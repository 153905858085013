import { StateModel } from '../models/auxiliary/state.model';
import {
  cancelOtherSideTransactionAction,
  cancelOtherSideTransactionErrorAction,
  cancelOtherSideTransactionSuccessAction,
  confirmOtherSideTransactionAction,
  confirmOtherSideTransactionErrorAction,
  confirmOtherSideTransactionSuccessAction,
  getOtherSideTransactionsErrorAction,
  getOtherSideTransactionsSuccessAction,
  retryWithdrawErrorAction,
  retryWithdrawSuccessAction,
} from '../actions/other-side-transaction.actions';
import { OtherSideTransaction } from '../models/api/other-side-transaction.model';
import { createReducer, on } from '@ngrx/store';

export type OtherSideTransactionState = StateModel<OtherSideTransaction>;

const INITIAL_STATE: OtherSideTransactionState = {
  data: null,
  errors: null,
};

export const otherSideTransactionReducer = createReducer(
  INITIAL_STATE,
  on(confirmOtherSideTransactionAction, cancelOtherSideTransactionAction, () => ({
    ...INITIAL_STATE,
  })),
  on(
    getOtherSideTransactionsSuccessAction,
    retryWithdrawSuccessAction,
    confirmOtherSideTransactionSuccessAction,
    cancelOtherSideTransactionSuccessAction,
    (state, { transaction }) => ({
      ...state,
      errors: null,
      data: transaction,
    })
  ),
  on(
    getOtherSideTransactionsErrorAction,
    retryWithdrawErrorAction,
    confirmOtherSideTransactionErrorAction,
    cancelOtherSideTransactionErrorAction,
    (state, { errors }) => ({
      ...state,
      errors: errors,
    })
  )
);
