import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';
import {
  getMerchantProfilePageAction,
  getMerchantProfilePageErrorAction,
  getMerchantProfilePageSuccessAction,
} from '../actions/merchant-profile-page.actions';

const ADMIN_MERCHANT_PROFILES_ENDPOINT = '/admin/merchant-profiles';

@Injectable()
export class MerchantProfilePageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantProfilePageAction),
      mergeMap(({ paging, filters }) => {
        return this.api.get(`${ADMIN_MERCHANT_PROFILES_ENDPOINT}${queryParams({ ...paging, ...filters })}`).pipe(
          map((merchantProfiles) => getMerchantProfilePageSuccessAction({ merchantProfiles })),
          catchError((errors) => observableOf(getMerchantProfilePageErrorAction(errors)))
        );
      })
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
