import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import {
  getDefaultPaymentSettingsAction,
  getDefaultPaymentSettingsErrorAction,
  getDefaultPaymentSettingsSuccessAction,
  getPaymentSettingsAction,
  getPaymentSettingsAdminAction,
  getPaymentSettingsErrorAction,
  getPaymentSettingsSuccessAction,
  updatePaymentSettingsAction,
  updatePaymentSettingsAdminAction,
  updatePaymentSettingsErrorAction,
  updatePaymentSettingsSuccessAction,
} from '../actions/payment-settings.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';

export const PAYMENT_SETTINGS_ENDPOINT = `/payment-settings`;
export const PAYMENT_DEFAULT_SETTINGS_ENDPOINT = `/payment-settings/default`;

@Injectable()
export class PaymentSettingsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentSettingsAction),
      mergeMap(() =>
        this.api.get(PAYMENT_SETTINGS_ENDPOINT).pipe(
          map((settings) => getPaymentSettingsSuccessAction({ settings })),
          catchError((errors) => observableOf(getPaymentSettingsErrorAction(errors)))
        )
      )
    )
  );

  getDefaultSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDefaultPaymentSettingsAction),
      mergeMap(() =>
        this.api.get(PAYMENT_DEFAULT_SETTINGS_ENDPOINT).pipe(
          map((settings) => getDefaultPaymentSettingsSuccessAction({ settings })),
          catchError((errors) => observableOf(getDefaultPaymentSettingsErrorAction(errors)))
        )
      )
    )
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePaymentSettingsAction),
      mergeMap(({ settings }) =>
        this.api.put(PAYMENT_SETTINGS_ENDPOINT, settings).pipe(
          map((settings) => updatePaymentSettingsSuccessAction({ settings })),
          catchError((errors) => observableOf(updatePaymentSettingsErrorAction(errors)))
        )
      )
    )
  );

  getAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentSettingsAdminAction),
      mergeMap(({ merchantHashId }) =>
        this.api.get(this.getAdminUrl(merchantHashId)).pipe(
          map((settings) => getPaymentSettingsSuccessAction({ settings })),
          catchError((errors) => observableOf(getPaymentSettingsErrorAction(errors)))
        )
      )
    )
  );

  updateSettingsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePaymentSettingsAdminAction),
      mergeMap(({ settings, merchantHashId }) =>
        this.api.put(this.getAdminUrl(merchantHashId), settings).pipe(
          map((settings) => updatePaymentSettingsSuccessAction({ settings })),
          catchError((errors) => observableOf(updatePaymentSettingsErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  getAdminUrl(merchantHashId: string): string {
    return '/' + ADMIN_ENDPOINT_PREFIX + PAYMENT_SETTINGS_ENDPOINT + '/' + merchantHashId;
  }
}
