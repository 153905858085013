import { OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractComponent implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  constructor() {
    // intentionally empty
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
