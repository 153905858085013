/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { PagingModel } from '../../models/auxiliary/paging.model';
import { SettlementMethodTo } from '../../models/api/settlement-method.model';

enum SettlementMethodPageActions {
  GET_REQUEST = 'settlementMethodPage/GET_REQUEST',
  GET_SUCCESS = 'settlementMethodPage/GET_SUCCESS',
  GET_ERROR = 'settlementMethodPage/GET_ERROR',
}

export const getSettlementMethodPageAction = createAction(
  SettlementMethodPageActions.GET_REQUEST,
  props<{ merchantId?: string }>()
);

export const getSettlementMethodPageSuccessAction = createAction(
  SettlementMethodPageActions.GET_SUCCESS,
  props<{ settlements: PagingModel<SettlementMethodTo> }>()
);

export const getSettlementMethodPageErrorAction = createAction(
  SettlementMethodPageActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
