import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppStateModel } from '../../models/auxiliary/app-state.model';
import { MessageService } from '../../services/message.service';
import { Actions, ofType } from '@ngrx/effects';
import { selectCountryList } from './country-list.selector';
import { filter, map } from 'rxjs/operators';
import { getCountryListAction, getCountryListErrorAction } from './country-list.actions';
import { Observable } from 'rxjs';
import { CountryTo } from '../../models/api/country.model';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private store: Store<AppStateModel>, private messageService: MessageService, private actions: Actions) {
    this.store
      .pipe(
        select(selectCountryList),
        filter((state) => state.initial)
      )
      .subscribe(() => {
        this.store.dispatch(getCountryListAction());
      });
    this.actions.pipe(ofType(getCountryListErrorAction)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, `Country List Error`);
    });
  }

  public getCountries(): Observable<CountryTo[]> {
    return this.store.pipe(
      select(selectCountryList),
      filter((state) => state?.data != null),
      map((state) => state.data)
    );
  }
}
