import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getSubscriptionListAction,
  getSubscriptionListErrorAction,
  getSubscriptionListSuccessAction,
} from './subscription-list.actions';
import { SubscriptionService } from '../subscription.service';

@Injectable()
export class SubscriptionListEffects {
  private readonly actions$ = inject(Actions);
  private readonly subscriptionService = inject(SubscriptionService);

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubscriptionListAction),
      mergeMap(({ filters, paging }) =>
        this.subscriptionService.getList(paging, filters).pipe(
          map((subscriptionList) => getSubscriptionListSuccessAction({ subscriptionList })),
          catchError((errors) => of(getSubscriptionListErrorAction(errors)))
        )
      )
    )
  );
}
