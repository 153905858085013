import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { RecoveryResult } from '../../models/api/recovery-result.model';

enum FireblocksVaultAccountActionTypes {
  SET_VISIBLE_REQUEST = 'fireblocksVaultAccount/SET_VISIBLE_REQUEST',
  SET_VISIBLE_SUCCESS = 'fireblocksVaultAccount/SET_VISIBLE_SUCCESS',
  SET_VISIBLE_ERROR = 'fireblocksVaultAccount/SET_VISIBLE_ERROR',

  RECOVERY_REQUEST = 'fireblocksVaultAccount/RECOVERY_REQUEST',
  RECOVERY_SUCCESS = 'fireblocksVaultAccount/RECOVERY_SUCCESS',
  RECOVERY_ERROR = 'fireblocksVaultAccount/RECOVERY_ERROR',
}

export const setVisibleAction = createAction(
  FireblocksVaultAccountActionTypes.SET_VISIBLE_REQUEST,
  props<{ id: string; body: { visible: boolean } }>()
);

export const setVisibleSuccessAction = createAction(FireblocksVaultAccountActionTypes.SET_VISIBLE_SUCCESS);

export const setVisibleErrorAction = createAction(
  FireblocksVaultAccountActionTypes.SET_VISIBLE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const recoveryAction = createAction(FireblocksVaultAccountActionTypes.RECOVERY_REQUEST, props<{ id: string }>());

export const recoverySuccessAction = createAction(
  FireblocksVaultAccountActionTypes.RECOVERY_SUCCESS,
  props<{ recoveryResult: RecoveryResult }>()
);

export const recoveryErrorAction = createAction(
  FireblocksVaultAccountActionTypes.RECOVERY_ERROR,
  props<{ errors: ErrorModel[] }>()
);
