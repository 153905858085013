import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getMarketPageErrorAction } from '../actions/market-page.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import {
  getOpenOrdersStatisticsAction,
  getOpenOrdersStatisticsSuccessAction,
} from '../actions/open-orders-statistics.actions';

const OPEN_ORDERS_STATISTICS_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/markets/open-orders-stats`;

@Injectable()
export class OpenOrdersStatisticsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOpenOrdersStatisticsAction),
      mergeMap(() => {
        return this.api.get(`${OPEN_ORDERS_STATISTICS_ENDPOINT}`).pipe(
          map((statistics) => getOpenOrdersStatisticsSuccessAction({ statistics: statistics })),
          catchError((errors) => observableOf(getMarketPageErrorAction(errors)))
        );
      })
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
