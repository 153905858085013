import { SettlementMethodTo } from '../../models/api/settlement-method.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';

export enum SettlementMethodActions {
  CREATE_REQUEST = 'settlementMethod/CREATE_REQUEST',
  CREATE_SUCCESS = 'settlementMethod/CREATE_SUCCESS',
  CREATE_ERROR = 'settlementMethod/CREATE_ERROR',

  UPDATE_REQUEST = 'settlementMethod/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'settlementMethod/UPDATE_SUCCESS',
  UPDATE_ERROR = 'settlementMethod/UPDATE_ERROR',

  GET_FEE_REQUEST = 'settlementMethod/GET_FEE_REQUEST',
  GET_FEE_SUCCESS = 'settlementMethod/GET_FEE_SUCCESS',
  GET_FEE_ERROR = 'settlementMethod/GET_FEE_ERROR',
}

export const createSettlementMethodAction = createAction(
  SettlementMethodActions.CREATE_REQUEST,
  props<{ settlementMethod: SettlementMethodTo; twoFa: string }>()
);

export const createSettlementMethodSuccessAction = createAction(
  SettlementMethodActions.CREATE_SUCCESS,
  props<{ settlementMethodTo: SettlementMethodTo }>()
);

export const createSettlementMethodErrorAction = createAction(
  SettlementMethodActions.CREATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateSettlementMethodAction = createAction(
  SettlementMethodActions.UPDATE_REQUEST,
  props<{ settlementMethod: SettlementMethodTo; twoFa?: string }>()
);

export const updateSettlementMethodSuccessAction = createAction(
  SettlementMethodActions.UPDATE_SUCCESS,
  props<{ settlementMethodTo: SettlementMethodTo }>()
);

export const updateSettlementMethodErrorAction = createAction(
  SettlementMethodActions.UPDATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const getSettlementMethodFeeAction = createAction(
  SettlementMethodActions.GET_FEE_REQUEST,
  props<{ settlementMethod: SettlementMethodTo }>()
);

export const getSettlementMethodFeeSuccessAction = createAction(
  SettlementMethodActions.GET_FEE_SUCCESS,
  props<{ fee: number }>()
);

export const getSettlementMethodFeeErrorAction = createAction(
  SettlementMethodActions.GET_FEE_ERROR,
  props<{ errors: ErrorModel[] }>()
);
