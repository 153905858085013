/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../../models/api/error.model';
import {
  AdminCryptoPaymentMethodTo,
  AdminCryptoPaymentMethodUpdateTo,
} from '../../../models/api/crypto-payment-method.model';

export enum CryptoPaymentMethodsAdminActions {
  GET_CRYPTO_PAYMENT_METHODS_ADMIN_REQUEST = 'cryptoPaymentMethodsAdmin/GET_CRYPTO_PAYMENT_METHODS_ADMIN_REQUEST',
  GET_CRYPTO_PAYMENT_METHODS_ADMIN_SUCCESS = 'cryptoPaymentMethodsAdmin/GET_CRYPTO_PAYMENT_METHODS_ADMIN_SUCCESS',
  GET_CRYPTO_PAYMENT_METHODS_ADMIN_ERROR = 'cryptoPaymentMethodsAdmin/GET_CRYPTO_PAYMENT_METHODS_ADMIN_ERROR',
  UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_REQUEST = 'cryptoPaymentMethodsAdmin/UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_REQUEST',
  UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_SUCCESS = 'cryptoPaymentMethodsAdmin/UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_SUCCESS',
  UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_ERROR = 'cryptoPaymentMethodsAdmin/UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_ERROR',
}

export const getCryptoPaymentMethodsAdminAction = createAction(
  CryptoPaymentMethodsAdminActions.GET_CRYPTO_PAYMENT_METHODS_ADMIN_REQUEST
);

export const getCryptoPaymentMethodsAdminActionSuccess = createAction(
  CryptoPaymentMethodsAdminActions.GET_CRYPTO_PAYMENT_METHODS_ADMIN_SUCCESS,
  props<{ paymentMethods: AdminCryptoPaymentMethodTo[] }>()
);

export const getCryptoPaymentMethodsAdminActionError = createAction(
  CryptoPaymentMethodsAdminActions.GET_CRYPTO_PAYMENT_METHODS_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateCryptoPaymentMethodAdminAction = createAction(
  CryptoPaymentMethodsAdminActions.UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_REQUEST,
  props<{
    paymentMethod: AdminCryptoPaymentMethodUpdateTo;
    paymentMethodId: string;
  }>()
);

export const updateCryptoPaymentMethodAdminActionSuccess = createAction(
  CryptoPaymentMethodsAdminActions.UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_SUCCESS
);

export const updateCryptoPaymentMethodAdminActionError = createAction(
  CryptoPaymentMethodsAdminActions.UPDATE_CRYPTO_PAYMENT_METHOD_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);
