import { Moment } from 'moment';
import { UntypedFormControl } from '@angular/forms';

export function maxDateValidator(date: Moment): (control: UntypedFormControl) => { [key: string]: boolean } {
  return (control: UntypedFormControl) => {
    const isValid = date.isBefore(control.value);
    if (isValid) {
      return { maxDate: true };
    } else {
      return null;
    }
  };
}
