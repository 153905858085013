import { ANY_FILTER_VALUE } from '../../../constants';
import { Time } from '../../../enums/time.enum';
import { createReducer, on } from '@ngrx/store';
import {
  getSubscriptionListFiltersAction,
  updateSubscriptionListFiltersAction,
} from './subscription-list-filters.action';
import { SubscriptionListFiltersModel } from './subscription-list-filters.model';

export type SubscriptionListFiltersState = SubscriptionListFiltersModel;

export const SUBSCRIPTION_LIST_FILTERS_ANY_STATE: SubscriptionListFiltersState = {
  createdAt: {
    type: ANY_FILTER_VALUE,
    createdAtFrom: 0,
    createdAtTo: 0,
  },
  status: ANY_FILTER_VALUE,
  settlementCurrency: ANY_FILTER_VALUE,
};

export const SUBSCRIPTION_LIST_FILTERS_INITIAL_STATE: SubscriptionListFiltersState = {
  createdAt: {
    type: Time.MONTH,
    createdAtFrom: 0,
    createdAtTo: 0,
  },
  status: ANY_FILTER_VALUE,
  settlementCurrency: ANY_FILTER_VALUE,
};

export const subscriptionListFiltersReducer = createReducer(
  SUBSCRIPTION_LIST_FILTERS_INITIAL_STATE,
  on(getSubscriptionListFiltersAction, (state) => ({
    ...state,
  })),
  on(updateSubscriptionListFiltersAction, (state, { filters }) => ({
    ...filters,
  }))
);
