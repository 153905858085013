import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { getErrorTodoListAction, getSuccessTodoListAction, getTodoListAction } from './todo-list.actions';

export const TODO_ENDPOINT = '/todo';

@Injectable()
export class TodoListEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTodoListAction),
      mergeMap(() =>
        this.api.get(TODO_ENDPOINT).pipe(
          map((todoListResponse) => getSuccessTodoListAction({ todoListResponse })),
          catchError((errors) => observableOf(getErrorTodoListAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
