/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import {
  ButtonInvoice,
  InvoiceCreationModel,
  InvoiceModel,
  InvoiceStatusRequest,
  ManualTransactionModel,
} from '../models/api/invoice.model';
import { TakeInfoModel } from '../models/api/take-refund.model';
import { InvoiceOverpayment } from '../models/api/invoice-overpayment.model';

export enum InvoiceActionTypes {
  MarkTransactionRefundable = 'invoice/MARK_TRANSACTION_REFUNDABLE',
  MarkTransactionRefundableSuccess = 'invoice/MARK_TRANSACTION_REFUNDABLE_SUCCESS',
  MarkTransactionRefundableError = 'invoice/MARK_TRANSACTION_REFUNDABLE_ERROR',

  SET_NULL_AMOUNT = 'invoice/SET_NULL_AMOUNT',
  SET_NULL_AMOUNT_SUCCESS = 'invoice/SET_NULL_AMOUNT_SUCCESS',
  SET_NULL_AMOUNT_ERROR = 'invoice/SET_NULL_AMOUNT_ERROR',

  GET_REQUEST = 'invoice/GET_REQUEST',
  GET_SUCCESS = 'invoice/GET_SUCCESS',
  GET_ERROR = 'invoice/GET_ERROR',

  CREATE_INVOICE_REQUEST = 'invoice/CREATE_INVOICE_REQUEST',
  CREATE_INVOICE_SUCCESS = 'invoice/CREATE_INVOICE_SUCCESS',
  CREATE_INVOICE_ERROR = 'invoice/CREATE_INVOICE_ERROR',

  UPDATE_INVOICE_STATUS_REQUEST = 'invoice/UPDATE_INVOICE_STATUS_REQUEST',
  UPDATE_INVOICE_STATUS_SUCCESS = 'invoice/UPDATE_INVOICE_STATUS_SUCCESS',
  UPDATE_INVOICE_STATUS_ERROR = 'invoice/UPDATE_INVOICE_STATUS_ERROR',

  CREATE_MANUAL_TRANSACTION_REQUEST = 'invoice/CREATE_MANUAL_TRANSACTION_REQUEST',
  CREATE_MANUAL_TRANSACTION_SUCCESS = 'invoice/CREATE_MANUAL_TRANSACTION_SUCCESS',
  CREATE_MANUAL_TRANSACTION_ERROR = 'invoice/CREATE_MANUAL_TRANSACTION_ERROR',

  CREATE_INVOICE_TAKE_REQUEST = 'invoice/CREATE_INVOICE_TAKE_REQUEST',
  CREATE_INVOICE_TAKE_SUCCESS = 'invoice/CREATE_INVOICE_TAKE_SUCCESS',
  CREATE_INVOICE_TAKE_ERROR = 'invoice/CREATE_INVOICE_TAKE_ERROR',

  CREATE_INVOICE_TAKE_CONFIRM_REQUEST = 'invoice/CREATE_INVOICE_TAKE_CONFIRM_REQUEST',
  CREATE_INVOICE_TAKE_CONFIRM_SUCCESS = 'invoice/CREATE_INVOICE_TAKE_CONFIRM_SUCCESS',
  CREATE_INVOICE_TAKE_CONFIRM_ERROR = 'invoice/CREATE_INVOICE_TAKE_CONFIRM_ERROR',

  CREATE_INVOICE_TAKE_REQUEST_OVERPAYMENT = 'invoice/CREATE_INVOICE_TAKE_REQUEST_OVERPAYMENT',
  CREATE_INVOICE_TAKE_SUCCESS_OVERPAYMENT = 'invoice/CREATE_INVOICE_TAKE_SUCCESS_OVERPAYMENT',
  CREATE_INVOICE_TAKE_ERROR_OVERPAYMENT = 'invoice/CREATE_INVOICE_TAKE_ERROR_OVERPAYMENT',

  CREATE_INVOICE_TAKE_CONFIRM_REQUEST_OVERPAYMENT = 'invoice/CREATE_INVOICE_TAKE_CONFIRM_REQUEST_OVERPAYMENT',
  CREATE_INVOICE_TAKE_CONFIRM_SUCCESS_OVERPAYMENT = 'invoice/CREATE_INVOICE_TAKE_CONFIRM_SUCCESS_OVERPAYMENT',
  CREATE_INVOICE_TAKE_CONFIRM_ERROR_OVERPAYMENT = 'invoice/CREATE_INVOICE_TAKE_CONFIRM_ERROR_OVERPAYMENT',

  CREATE_INVOICE_FROM_EMAIL = 'invoice/CREATE_INVOICE_FROM_EMAIL',
  CREATE_INVOICE_FROM_EMAIL_SUCCESS = 'invoice/CREATE_INVOICE_FROM_EMAIL_SUCESS',
  CREATE_INVOICE_FROM_EMAIL_ERROR = 'invoice/CREATE_INVOICE_FROM_EMAIL_ERROR',

  CREATE_INVOICE_FROM_BUTTON = 'paymentButton/CREATE_INVOICE_FROM_BUTTON',
  CREATE_INVOICE_FROM_BUTTON_SUCCESS = 'paymentButton/CREATE_INVOICE_FROM_BUTTON_SUCCESS',
  CREATE_INVOICE_FROM_BUTTON_ERROR = 'paymentButton/CREATE_INVOICE_FROM_BUTTON_ERROR',
}

export const getInvoiceAction = createAction(
  InvoiceActionTypes.GET_REQUEST,
  props<{ invoiceId: string; isAdmin: boolean }>()
);

export const getInvoiceSuccessAction = createAction(InvoiceActionTypes.GET_SUCCESS, props<{ invoice: InvoiceModel }>());

export const getInvoiceErrorAction = createAction(InvoiceActionTypes.GET_ERROR, props<{ errors: ErrorModel[] }>());

export const createInvoiceAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_REQUEST,
  props<{ invoice: InvoiceCreationModel }>()
);

export const createInvoiceSuccessAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_SUCCESS,
  props<{ invoice: InvoiceModel }>()
);

export const createInvoiceErrorAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateInvoiceStatusAction = createAction(
  InvoiceActionTypes.UPDATE_INVOICE_STATUS_REQUEST,
  props<{ invoiceId: string; invoiceStatusRequest: InvoiceStatusRequest }>()
);

export const updateInvoiceStatusSuccessAction = createAction(InvoiceActionTypes.UPDATE_INVOICE_STATUS_SUCCESS);

export const updateInvoiceStatusErrorAction = createAction(
  InvoiceActionTypes.UPDATE_INVOICE_STATUS_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createInvoiceManualTransactionAction = createAction(
  InvoiceActionTypes.CREATE_MANUAL_TRANSACTION_REQUEST,
  props<{ manualTransaction: ManualTransactionModel }>()
);

export const createInvoiceManualTransactionSuccessAction = createAction(
  InvoiceActionTypes.CREATE_MANUAL_TRANSACTION_SUCCESS
);

export const createInvoiceManualTransactionErrorAction = createAction(
  InvoiceActionTypes.CREATE_MANUAL_TRANSACTION_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createInvoiceTakeAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_REQUEST,
  props<{ invoiceId: string }>()
);

export const createInvoiceTakeSuccessAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_SUCCESS,
  props<{ takeInfo: TakeInfoModel }>()
);

export const createInvoiceTakeErrorAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const confirmInvoiceTakeAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_CONFIRM_REQUEST,
  props<{ invoiceId: string; skipWebhook: boolean }>()
);

export const confirmInvoiceTakeSuccessAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_CONFIRM_SUCCESS,
  props<{ takeInfo: TakeInfoModel }>()
);

export const confirmInvoiceTakeErrorAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_CONFIRM_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createInvoiceTakeOverpaymentAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_REQUEST_OVERPAYMENT,
  props<{ invoiceId: string }>()
);

export const createInvoiceTakeOverpaymentSuccessAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_SUCCESS_OVERPAYMENT,
  props<{ invoiceOverpayment: InvoiceOverpayment }>()
);

export const createInvoiceTakeOverpaymentErrorAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_ERROR_OVERPAYMENT,
  props<{ errors: ErrorModel[] }>()
);

export const confirmInvoiceTakeOverpaymentAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_CONFIRM_REQUEST_OVERPAYMENT,
  props<{ invoiceId: string }>()
);

export const confirmInvoiceTakeOverpaymentSuccessAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_CONFIRM_SUCCESS_OVERPAYMENT,
  props<{ invoiceOverpayment: InvoiceOverpayment }>()
);

export const confirmInvoiceTakeOverpaymentErrorAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_TAKE_CONFIRM_ERROR_OVERPAYMENT,
  props<{ errors: ErrorModel[] }>()
);

export const createInvoiceFromEmailInvoiceAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_FROM_EMAIL,
  props<{ emailInvoiceId: string }>()
);

export const createInvoiceFromEmailInvoiceSuccessAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_FROM_EMAIL_SUCCESS,
  props<{ invoice: InvoiceModel }>()
);

export const createInvoiceFromEmailInvoiceErrorAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_FROM_EMAIL_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createInvoiceFromButtonAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_FROM_BUTTON,
  props<{ buttonId: string; params: ButtonInvoice }>()
);

export const createInvoiceFromButtonSuccessAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_FROM_BUTTON_SUCCESS,
  props<{ invoice: InvoiceModel }>()
);

export const createInvoiceFromButtonErrorAction = createAction(
  InvoiceActionTypes.CREATE_INVOICE_FROM_BUTTON_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const markTransactionRefundableAction = createAction(
  InvoiceActionTypes.MarkTransactionRefundable,
  props<{ txid: string; address: string }>()
);

export const markTransactionRefundableSuccessAction = createAction(InvoiceActionTypes.MarkTransactionRefundableSuccess);

export const markTransactionRefundableErrorAction = createAction(
  InvoiceActionTypes.MarkTransactionRefundableError,
  props<{ errors: ErrorModel[] }>()
);

export const setNullAmountInvoiceAction = createAction(
  InvoiceActionTypes.SET_NULL_AMOUNT,
  props<{ txid: string; address: string }>()
);

export const setNullAmountInvoiceSuccessAction = createAction(InvoiceActionTypes.SET_NULL_AMOUNT_SUCCESS);

export const setNullAmountInvoiceErrorAction = createAction(
  InvoiceActionTypes.SET_NULL_AMOUNT_ERROR,
  props<{ errors: ErrorModel[] }>()
);
