<div class="custom-select-container" bpOuterClick (outerClick)="listShown = false">
  <input
    readonly
    class="confirmo-platform-select"
    [ngStyle]="{ 'width.px': minWidth }"
    [value]="this.selected?.label"
    (keydown)="setOptionByArrowButton($event)"
    (click)="toggleOptionList()"
    (focusin)="setFocus(true)"
    (focusout)="setFocus(false)"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    [ngClass]="{
      'input-centered': this.centered,
      'confirmo-platform-select__high': this.isHigh,
      'confirmo-platform-select__low': !this.isHigh,
      'custom-select-normal-font-weight': this.normalFontWeight,
      redesigned: redesigned,
      invalid: showError
    }"
  />
  <div
    [ngClass]="{
      'confirmo-input-invalid-tooltip__redesigned': redesigned,
      'confirmo-input-invalid-tooltip__no-prepend': !redesigned
    }"
    *ngIf="showError"
  >
    {{ errorMessage }}
  </div>
  <div
    [ngClass]="{
      'input-centered': this.centered,
      'custom-select-options-list': this.listShown,
      'custom-select-options-list__hidden': !this.listShown
    }"
    [ngStyle]="{ 'width.px': minWidth }"
  >
    <div class="custom-select-options-container" #optionsContainer [style]="listPosition">
      <div *ngIf="options.length === 0">{{ noItemsToShowText }}</div>
      <div
        *ngFor="let t of options"
        class="custom-select-option"
        (click)="setOption(t)"
        [id]="idPrefix + t.value | bpIdentifier"
        [ngClass]="{ 'option-selected': isOptionSelected(t), 'select-option-disabled': !isOptionEnabled(t) }"
      >
        {{ t.label }}
      </div>
    </div>
  </div>
</div>
