import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantVerificationInfoModel } from '../models/api/merchant-profile/merchant-verification-info.model';

export enum merchantVerificationInfoActions {
  GET_REQUEST = 'merchantVerificationInfo/GET_REQUEST',
  GET_SUCCESS = 'merchantVerificationInfo/GET_SUCCESS',
  GET_ERROR = 'merchantVerificationInfo/GET_ERROR',
  SUBSCRIBE_INTERVAL = 'merchantVerificationInfo/SUBSCRIBE_INTERVAL',
  UNSUBSCRIBE_INTERVAL = 'merchantVerificationInfo/UNSUBSCRIBE_INTERVAL',
}

export const getMerchantVerificationInfoAction = createAction(merchantVerificationInfoActions.GET_REQUEST);

export const getMerchantVerificationInfoSuccessAction = createAction(
  merchantVerificationInfoActions.GET_SUCCESS,
  props<{ verificationInfo: MerchantVerificationInfoModel }>()
);

export const getMerchantVerificationInfoErrorAction = createAction(
  merchantVerificationInfoActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const refreshMerchantVerificationInfoOnIntervalSubscribeAction = createAction(
  merchantVerificationInfoActions.SUBSCRIBE_INTERVAL
);
export const refreshMerchantVerificationInfoOnIntervalUnsubscribeAction = createAction(
  merchantVerificationInfoActions.UNSUBSCRIBE_INTERVAL
);
