import { LimitsAndFeesModel } from '../models/api/limits-and-fees.model';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';
import {
  getLimitsAndFeesErrorAction,
  getLimitsAndFeesSuccessAction,
  updateLimitsAndFeesErrorAction,
  updateLimitsAndFeesSuccessAction,
} from '../actions/limits-and-fees.actions';

export type LimitsAndFeesState = StateModel<LimitsAndFeesModel>;

const INITIAL_STATE: LimitsAndFeesState = {
  errors: null,
  data: null,
};

export const limitsAndFeesReducer = createReducer(
  INITIAL_STATE,
  on(getLimitsAndFeesSuccessAction, updateLimitsAndFeesSuccessAction, (state, { limitsAndFees }) => ({
    errors: null,
    data: limitsAndFees,
  })),
  on(getLimitsAndFeesErrorAction, updateLimitsAndFeesErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
