/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { AuditLogEventModel } from '../../../models/api/audit-log-event.model';
import { ErrorModel } from '../../../models/api/error.model';

export enum AuditLogEventsAdminTypes {
  GetAuditLogEventsAdmin = 'auditLogEventsAdmin/GET_REQUEST',
  GetAuditLogEventsAdminSuccess = 'auditLogEventsAdmin/GET_SUCCESS',
  GetAuditLogEventsAdminError = 'auditLogEventsAdmin/GET_ERROR',
}

export const getAuditLogEventsAdminAction = createAction(
  AuditLogEventsAdminTypes.GetAuditLogEventsAdmin,
  props<{ userId: string }>()
);

export const getAuditLogEventsAdminSuccessAction = createAction(
  AuditLogEventsAdminTypes.GetAuditLogEventsAdminSuccess,
  props<{ auditLogEventsResponse: AuditLogEventModel[] }>()
);

export const getAuditLogEventsAdminErrorAction = createAction(
  AuditLogEventsAdminTypes.GetAuditLogEventsAdminError,
  props<{ errors: ErrorModel[] }>()
);
