/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { MerchantProfileStatusModel } from '../models/api/merchant-profile/merchant-profile-status.model';

export enum MerchantVerificationStatusActionTypes {
  GetMerchantVerificationStatus = 'merchantVerificationStatus/GET_REQUEST',
  GetMerchantVerificationStatusSuccess = 'merchantVerificationStatus/GET_SUCCESS',
  GetMerchantVerificationStatusError = 'merchantVerificationStatus/GET_ERROR',
  ChangeMerchantVerificationStatus = 'merchantVerificationStatus/UPDATE_STATUS_REQUEST',
  ResetMerchantVerification = 'merchantVerificationStatus/RESET_VERIFICATION_REQUEST',
  ResetMerchantVerificationSuccess = 'merchantVerificationStatus/RESET_VERIFICATION_SUCCESS',
  ResetMerchantVerificationError = 'merchantVerificationStatus/RESET_VERIFICATION_ERROR',
}

export const getMerchantVerificationStatusAction = createAction(
  MerchantVerificationStatusActionTypes.GetMerchantVerificationStatus,
  props<{ merchantId: string }>()
);

export const getMerchantVerificationStatusSuccessAction = createAction(
  MerchantVerificationStatusActionTypes.GetMerchantVerificationStatusSuccess,
  props<{ merchant: MerchantProfileUnionModel }>()
);

export const getMerchantVerificationStatusErrorAction = createAction(
  MerchantVerificationStatusActionTypes.GetMerchantVerificationStatusError,
  props<{ errors: ErrorModel[] }>()
);

export const changeMerchantVerificationStatusAction = createAction(
  MerchantVerificationStatusActionTypes.ChangeMerchantVerificationStatus,
  props<{ merchant: MerchantProfileUnionModel; status: MerchantProfileStatusModel }>()
);

export const resetMerchantVerificationAction = createAction(
  MerchantVerificationStatusActionTypes.ResetMerchantVerification,
  props<{ hashId: string; privateComment: string }>()
);

export const resetMerchantVerificationSuccessAction = createAction(
  MerchantVerificationStatusActionTypes.ResetMerchantVerificationSuccess,
  props<{ merchant: MerchantProfileUnionModel }>()
);

export const resetMerchantVerificationErrorAction = createAction(
  MerchantVerificationStatusActionTypes.ResetMerchantVerificationError,
  props<{ errors: ErrorModel[] }>()
);
