import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubscriptionService } from '../subscription.service';
import {
  getSubscriptionEnabledInfoAction,
  getSubscriptionEnabledInfoErrorAction,
  getSubscriptionEnabledInfoSuccessAction,
} from './subscription-enabled-info.actions';

@Injectable()
export class SubscriptionEnabledInfoEffects {
  private readonly actions$ = inject(Actions);
  private readonly subscriptionService = inject(SubscriptionService);

  getSubscriptionEnabledInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubscriptionEnabledInfoAction),
      switchMap(() =>
        this.subscriptionService.getSubscriptionEnabledInfo().pipe(
          map((isSubscriptionEnabled) => getSubscriptionEnabledInfoSuccessAction({ isSubscriptionEnabled })),
          catchError((errors) => of(getSubscriptionEnabledInfoErrorAction(errors)))
        )
      )
    )
  );
}
