import { Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractComponent } from '../abstract.component';
import { CurrencyIconService } from '../../services/currency-icon.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractSelectComponent<T> extends AbstractComponent {
  @ViewChild('optionsContainer') optionsContainer: ElementRef;
  @Input() isDisabled = false;
  @Input() placeholder = '';
  @Input() overflownParentRef: ElementRef;
  @Input() noItemsToShowText: string = 'No items to show';

  readonly idPrefix = 'select-option-';
  readonly timeToEmptySearch = 500;

  searchString = '';
  searchTimeOut;
  listShown = false;
  touched = false;

  readonly listPositionBottom = 'top: 0;';
  readonly listPositionTop = 'bottom: 40px;';
  listPosition = this.listPositionBottom;

  constructor(public currencyIconService: CurrencyIconService) {
    super();
  }

  toggleOptionList(): void {
    if (!this.touched) {
      this.touched = true;
    }
    this.listShown = !this.listShown;
    if (this.listPosition === this.listPositionBottom) {
      setTimeout(() => this.setListPosition(), 0);
    }
  }

  setListPosition(): void {
    this.listPosition =
      this.optionsContainer?.nativeElement.getBoundingClientRect().bottom >
      this.overflownParentRef?.nativeElement.getBoundingClientRect().bottom
        ? this.listPositionTop
        : this.listPositionBottom;
  }

  abstract setOptionByArrowButton(event: KeyboardEvent): void;

  getPrevOption(selectedOption: T, options: T[]): T {
    const index = options.indexOf(selectedOption);
    return options[(options.length + index - 1) % options.length];
  }

  getNextOption(selectedOption: T, options: T[]): T {
    const index = options.indexOf(selectedOption);
    return options[(options.length + index + 1) % options.length];
  }

  setOptionBySubstring(char): void {
    clearTimeout(this.searchTimeOut);
    this.searchString += char.toLowerCase();
    this.searchTimeOut = setTimeout(() => {
      this.searchString = '';
    }, this.timeToEmptySearch);
    this.findOptionByStoredSubstring();
  }

  abstract findOptionByStoredSubstring(): void;
}
