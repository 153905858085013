import { Component, EventEmitter, Input, Output } from '@angular/core';
import { wizardStepBackAction } from '../../../../../shared/actions/wizard.actions';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { Store } from '@ngrx/store';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from '../../../../../shared/services/message.service';
import {
  generateMerchantVerification,
  generateMerchantVerificationError,
  generateMerchantVerificationSuccess,
} from '../../../../../shared/actions/merchant-verification-idenfy.actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'bp-method-selector',
  templateUrl: './method-selector.component.html',
  styleUrls: ['./method-selector.component.scss', '../../aml-wizard.scss'],
})
export class MethodSelectorComponent extends AbstractComponent {
  @Input() merchantId: string;
  @Input() nameToDisplay: string;
  @Output() methodSelected = new EventEmitter<boolean>();

  constructor(private store: Store<AppStateModel>, private messageService: MessageService, private actions: Actions) {
    super();

    this.actions
      .pipe(ofType(generateMerchantVerificationError), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => {
        this.messageService.showErrors(errors, 'Idcheck verification error.');
      });

    this.actions.pipe(ofType(generateMerchantVerificationSuccess), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.methodSelected.emit(true);
    });
  }

  setMethodApp(): void {
    this.store.dispatch(
      generateMerchantVerification({
        idcheckGenerationData: {
          merchantId: this.merchantId,
          usesPhone: true,
        },
      })
    );
  }

  setMethodBrowser(): void {
    this.store.dispatch(
      generateMerchantVerification({
        idcheckGenerationData: {
          merchantId: this.merchantId,
          usesPhone: false,
        },
      })
    );
  }

  stepBack(): void {
    this.store.dispatch(wizardStepBackAction());
  }
}
