import { StateModel } from '../models/auxiliary/state.model';
import { PersonModel } from '../models/api/merchant-profile/person-model';
import {
  createMerchantPersonAdminAction,
  createMerchantPersonAdminErrorAction,
  createMerchantPersonAdminSuccessAction,
  deleteMerchantPersonAdminAction,
  deleteMerchantPersonAdminErrorAction,
  deleteMerchantPersonAdminSuccessAction,
  promoteDemoteMerchantPersonAdminAction,
  promoteDemoteMerchantPersonAdminErrorAction,
  promoteDemoteMerchantPersonAdminSuccessAction,
  updateMerchantPersonAdminAction,
  updateMerchantPersonAdminErrorAction,
  updateMerchantPersonAdminSuccessAction,
} from '../actions/merchant-person.actions';
import { createReducer, on } from '@ngrx/store';

export type MerchantPersonState = StateModel<PersonModel>;

const INITIAL_STATE: MerchantPersonState = {
  data: null,
  errors: null,
};

export const merchantPersonReducer = createReducer(
  INITIAL_STATE,
  on(
    updateMerchantPersonAdminAction,
    deleteMerchantPersonAdminAction,
    promoteDemoteMerchantPersonAdminAction,
    createMerchantPersonAdminAction,
    (state) => ({
      ...state,
      errors: null,
    })
  ),
  on(updateMerchantPersonAdminSuccessAction, (state, { person }) => ({
    ...state,
    data: person,
  })),
  on(
    updateMerchantPersonAdminErrorAction,
    deleteMerchantPersonAdminErrorAction,
    promoteDemoteMerchantPersonAdminErrorAction,
    createMerchantPersonAdminErrorAction,
    (state, { errors }) => ({
      ...state,
      errors: errors,
    })
  ),
  on(
    deleteMerchantPersonAdminSuccessAction,
    promoteDemoteMerchantPersonAdminSuccessAction,
    createMerchantPersonAdminSuccessAction,
    (state) => ({
      ...state,
      errors: null,
    })
  )
);
