import { StateModel } from '../../../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';
import { MerchantCryptoPaymentMethodTo } from '../../../models/api/crypto-payment-method.model';
import {
  getCryptoPaymentMethodsMerchantAction,
  getCryptoPaymentMethodsMerchantActionError,
  getCryptoPaymentMethodsMerchantActionSuccess,
  updateCryptoPaymentMethodMerchantActionSuccess,
} from './crypto-payment-methods-merchant.actions';

export type MerchantCryptoPaymentMethodsState = StateModel<MerchantCryptoPaymentMethodTo[]>;

const INITIAL_STATE: MerchantCryptoPaymentMethodsState = {
  errors: null,
  data: null,
  initial: true,
};

export const cryptoPaymentMethodsMerchantReducer = createReducer(
  INITIAL_STATE,
  on(getCryptoPaymentMethodsMerchantAction, () => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(getCryptoPaymentMethodsMerchantActionSuccess, (state, { paymentMethods }) => ({
    data: paymentMethods,
    errors: null,
  })),
  on(getCryptoPaymentMethodsMerchantActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
  on(updateCryptoPaymentMethodMerchantActionSuccess, () => ({ ...INITIAL_STATE }))
);
