/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { SettlementPageFiltersModel } from './settlement-page-filters.model';

export enum SettlementPageFiltersActions {
  GET = 'settlementPageFilters/GET',
  UPDATE = 'settlementPageFilters/UPDATE',
}

export const getSettlementPageFiltersAction = createAction(SettlementPageFiltersActions.GET);

export const updateSettlementPageFiltersAction = createAction(
  SettlementPageFiltersActions.UPDATE,
  props<{ filters: SettlementPageFiltersModel }>()
);
