import { StateModel } from '../../../models/auxiliary/state.model';
import { AuditLogEventModel } from '../../../models/api/audit-log-event.model';
import {
  getAuditLogEventsAdminAction,
  getAuditLogEventsAdminErrorAction,
  getAuditLogEventsAdminSuccessAction,
} from './audit-log-events-admin.actions';
import { createReducer, on } from '@ngrx/store';

export type AuditLogEventsAdminState = StateModel<AuditLogEventModel[]>;

const INITIAL_STATE: AuditLogEventsAdminState = {
  errors: null,
  data: [],
};

export const auditLogEventsAdminReducer = createReducer(
  INITIAL_STATE,
  on(getAuditLogEventsAdminAction, () => ({
    ...INITIAL_STATE,
  })),
  on(getAuditLogEventsAdminSuccessAction, (state, { auditLogEventsResponse }) => ({
    ...state,
    data: auditLogEventsResponse,
  })),
  on(getAuditLogEventsAdminErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
