import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import {
  createMerchantVerificationPdfAdminError,
  createMerchantVerificationPdfAdminSuccess,
  generateMerchantVerificationError,
  generateMerchantVerificationSuccess,
  getMerchantVerificationPersonActionError,
  getMerchantVerificationPersonActionSuccess,
  MerchantVerificationIdenfyActionsTypes,
  refreshStateOnIntervalAction,
  sendVerificationLinkToEmailError,
  sendVerificationLinkToEmailSuccess,
  setMerchantVerificationPersonError,
  setMerchantVerificationPersonSuccess,
  stopRefreshStateOnIntervalAction,
  updateMerchantVerificationStateAdminError,
  updateMerchantVerificationStateAdminSuccess,
} from '../actions/merchant-verification-idenfy.actions';
import { Action } from '@ngrx/store';

export const MERCHANT_VERIFICATION_ENDPOINT = '/verification';
export const MERCHANT_VERIFICATION_ADMIN_ENDPOINT = '/admin/verification';

@Injectable()
export class MerchantVerificationIdenfyEffect {
  getSelectedPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantVerificationIdenfyActionsTypes.GET_SELECTED_PERSON),
      mergeMap(({ merchantId }) =>
        this.api.get(`${MERCHANT_VERIFICATION_ENDPOINT}/${merchantId}/selected-person`).pipe(
          map((person) => getMerchantVerificationPersonActionSuccess({ person })),
          catchError((errors) => of(getMerchantVerificationPersonActionError(errors)))
        )
      )
    )
  );

  refreshStateOnInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshStateOnIntervalAction),
      switchMap(({ merchantId }): Observable<Action> => {
        return timer(0, 5000).pipe(
          mergeMap(() =>
            this.api.get(`${MERCHANT_VERIFICATION_ENDPOINT}/${merchantId}/selected-person`).pipe(
              map((person) => getMerchantVerificationPersonActionSuccess({ person })),
              catchError((errors) => of(getMerchantVerificationPersonActionError(errors)))
            )
          ),
          takeUntil(this.actions$.pipe(ofType(stopRefreshStateOnIntervalAction)))
        );
      })
    )
  );

  selectPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantVerificationIdenfyActionsTypes.SET_PERSON),
      switchMap(({ hashId }) =>
        this.api.post(`${MERCHANT_VERIFICATION_ENDPOINT}/select-person/${hashId}`).pipe(
          map(() => setMerchantVerificationPersonSuccess()),
          catchError((errors) => of(setMerchantVerificationPersonError(errors)))
        )
      )
    )
  );

  createPdfAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantVerificationIdenfyActionsTypes.CREATE_PDF_ADMIN),
      switchMap(({ merchantId }) =>
        this.api.post(`${MERCHANT_VERIFICATION_ADMIN_ENDPOINT}/create-pdf/${merchantId}`).pipe(
          map(() => createMerchantVerificationPdfAdminSuccess()),
          catchError((errors) => of(createMerchantVerificationPdfAdminError(errors)))
        )
      )
    )
  );

  updateStateAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantVerificationIdenfyActionsTypes.UPDATE_STATE_ADMIN),
      switchMap(({ merchantId }) =>
        this.api.post(`${MERCHANT_VERIFICATION_ADMIN_ENDPOINT}/update-state/${merchantId}`).pipe(
          map(() => updateMerchantVerificationStateAdminSuccess()),
          catchError((errors) => of(updateMerchantVerificationStateAdminError(errors)))
        )
      )
    )
  );

  generateVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantVerificationIdenfyActionsTypes.GENERATE_VERIFICATION),
      switchMap(({ idcheckGenerationData }) => {
        return this.api.post(`${MERCHANT_VERIFICATION_ENDPOINT}/generate`, idcheckGenerationData).pipe(
          map((idcheckVerificationData) => generateMerchantVerificationSuccess({ idcheckVerificationData })),
          catchError((errors) => of(generateMerchantVerificationError(errors)))
        );
      })
    )
  );

  sendVerificationLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MerchantVerificationIdenfyActionsTypes.SEND_LINK_TO_EMAIL),
      switchMap(({ merchantId, email }) => {
        return this.api.post(`${MERCHANT_VERIFICATION_ENDPOINT}/send-email/${merchantId}`, email).pipe(
          map(() => sendVerificationLinkToEmailSuccess()),
          catchError((errors) => of(sendVerificationLinkToEmailError(errors)))
        );
      })
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
