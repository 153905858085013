import { StateModel } from '../../../models/auxiliary/state.model';
import { PagingModel } from '../../../models/auxiliary/paging.model';
import { SubscriptionModel } from '../subscription.model';
import { createReducer, on } from '@ngrx/store';
import {
  getSubscriptionListAction,
  getSubscriptionListErrorAction,
  getSubscriptionListSuccessAction,
} from './subscription-list.actions';

export type SubscriptionListState = StateModel<PagingModel<SubscriptionModel>>;

const INITIAL_STATE: SubscriptionListState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
  loading: true,
};

export const subscriptionListReducer = createReducer(
  INITIAL_STATE,
  on(getSubscriptionListAction, (state) => ({
    ...state,
    errors: null,
    loading: true,
  })),
  on(getSubscriptionListSuccessAction, (state, { subscriptionList }) => ({
    ...state,
    data: subscriptionList,
    loading: false,
  })),
  on(getSubscriptionListErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
