import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import {
  getNotificationSettingsAction,
  getNotificationSettingsErrorAction,
  getNotificationSettingsSuccessAction,
  updateNotificationSettingsAction,
  updateNotificationSettingsErrorAction,
  updateNotificationSettingsSuccessAction,
} from '../actions/notification-settings.actions';

export const NOTIFICATION_SETTINGS_ENDPOINT = '/notification-settings';
export const NOTIFICATION_SETTINGS_ADMIN_ENDPOINT = '/admin/merchants/{merchantHashId}/notification-settings';

@Injectable()
export class NotificationSettingsEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNotificationSettingsAction),
      mergeMap(({ merchantId }) =>
        this.api.get(this.url(merchantId)).pipe(
          map((notificationSettings) => getNotificationSettingsSuccessAction({ notificationSettings })),
          catchError((errors) => of(getNotificationSettingsErrorAction(errors)))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNotificationSettingsAction),
      mergeMap(({ merchantId, notificationSettings }) =>
        this.api.put(this.url(merchantId), notificationSettings).pipe(
          map((notificationSettings) => updateNotificationSettingsSuccessAction({ notificationSettings })),
          catchError((errors) => of(updateNotificationSettingsErrorAction(errors)))
        )
      )
    )
  );

  url(merchantId?: string) {
    if (merchantId) {
      return NOTIFICATION_SETTINGS_ADMIN_ENDPOINT.replace('{merchantHashId}', merchantId);
    } else {
      return NOTIFICATION_SETTINGS_ENDPOINT;
    }
  }

  constructor(private actions$: Actions, private api: ApiService) {}
}
