import { StateModel } from '../models/auxiliary/state.model';
import { CryptoPriceTo } from '../models/api/prepared-state-currency.model';
import {
  getPreparedCurrenciesAction,
  getPreparedCurrenciesErrorAction,
  getPreparedCurrenciesSuccessAction,
} from '../actions/invoice-prepared.action';
import { createReducer, on } from '@ngrx/store';

export type InvoicePreparedState = StateModel<CryptoPriceTo[] | null>;

const INITIAL_STATE: InvoicePreparedState = {
  errors: null,
  data: null,
  loading: false,
};

export const invoicePreparedCurrenciesReducer = createReducer(
  INITIAL_STATE,
  on(getPreparedCurrenciesAction, (state) => ({ ...INITIAL_STATE, loading: true })),
  on(getPreparedCurrenciesSuccessAction, (state, { currencies }) => ({
    ...state,
    data: currencies,
    loading: false,
  })),
  on(getPreparedCurrenciesErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
