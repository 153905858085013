import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppStateModel } from '../../../models/auxiliary/app-state.model';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  MerchantCryptoPaymentMethodTo,
  MerchantCryptoPaymentMethodUpdateTo,
} from '../../../models/api/crypto-payment-method.model';
import { selectMerchantCryptoPaymentMethods } from './crypto-payment-methods-merchant.selector';
import {
  getCryptoPaymentMethodsMerchantAction,
  getCryptoPaymentMethodsMerchantActionError,
  updateCryptoPaymentMethodMerchantAction,
  updateCryptoPaymentMethodMerchantAsAdminAction,
} from './crypto-payment-methods-merchant.actions';
import { MessageService } from '../../../services/message.service';
import { Actions, ofType } from '@ngrx/effects';
import { selectUser } from '../../../selectors/user.selector';
import { ErrorModel } from '../../../models/api/error.model';
import { OtherSideTransaction } from '../../../models/api/other-side-transaction.model';

@Injectable({
  providedIn: 'root',
})
export class CryptoPaymentMethodsMerchantService {
  private merchantId: string = null;
  private isAdmin: boolean = false;
  private invoicingEnabled = null;
  private settlementEnabled = null;
  private payoutEnabled = null;
  private isError = false;
  private previousErrors: ErrorModel[];

  constructor(private store: Store<AppStateModel>, private messageService: MessageService, private actions: Actions) {
    combineLatest([
      this.store.pipe(select(selectMerchantCryptoPaymentMethods)),
      this.store.pipe(select(selectUser)),
    ]).subscribe(([cryptoPaymentMethods, user]) => {
      this.merchantId = user.data?.merchantId;
      this.isAdmin = user.data?.admin;
      if (cryptoPaymentMethods.initial && this.merchantId && !this.isAdmin) {
        this.refresh({});
      }
    });

    this.actions.pipe(ofType(getCryptoPaymentMethodsMerchantActionError)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, `Merchant Crypto Payment Method List Error`);
      this.isError = true;
      this.previousErrors = errors;
    });
  }

  public refresh(params: {
    merchantId?: string;
    invoicingEnabled?: boolean;
    settlementEnabled?: boolean;
    payoutEnabled?: boolean;
  }): void {
    const merchantId = params.merchantId ?? this.merchantId;
    const invoicingEnabled = params.invoicingEnabled ?? this.invoicingEnabled;
    const settlementEnabled = params.settlementEnabled ?? this.settlementEnabled;
    const payoutEnabled = params.payoutEnabled ?? this.payoutEnabled;

    if (!merchantId) {
      console.error(
        new Error('[Payment methods]: merchantId is undefined, previous errors: ' + JSON.stringify(this.previousErrors))
      );
    }

    this.isError = false;
    this.previousErrors = null;

    this.store.dispatch(
      getCryptoPaymentMethodsMerchantAction({
        merchantId: merchantId,
        invoicingEnabled: invoicingEnabled,
        settlementEnabled: settlementEnabled,
        payoutEnabled: payoutEnabled,
      })
    );
  }

  /**
   * Returns both types of methods: those, which are enabled by admin, and those, which were disabled by admin (not private!)
   */
  public getVisibleAndInvisiblePaymentMethods(): Observable<MerchantCryptoPaymentMethodTo[]> {
    if (this.isError) {
      this.refresh({});
    }

    return this.store.pipe(
      select(selectMerchantCryptoPaymentMethods),
      filter((state) => state != null && state.data != null),
      map((state) => state.data)
    );
  }

  public getOnlyVisiblePaymentMethods(): Observable<MerchantCryptoPaymentMethodTo[]> {
    return this.getVisibleAndInvisiblePaymentMethods().pipe(
      map((paymentMethods) => paymentMethods.filter((paymentMethod) => paymentMethod.visible))
    );
  }

  public getPaymentMethodsWithSettlementEnabled(): Observable<MerchantCryptoPaymentMethodTo[]> {
    return this.getOnlyVisiblePaymentMethods().pipe(
      map((paymentMethods) => paymentMethods.filter((paymentMethod) => paymentMethod.settlementEnabled))
    );
  }

  public getPaymentMethodsWithPayoutEnabled(): Observable<MerchantCryptoPaymentMethodTo[]> {
    return this.getOnlyVisiblePaymentMethods().pipe(
      map((paymentMethods) => paymentMethods.filter((paymentMethod) => paymentMethod.payoutEnabled))
    );
  }

  public getPaymentMethodById(id: string): Observable<MerchantCryptoPaymentMethodTo> {
    return this.getVisibleAndInvisiblePaymentMethods().pipe(
      map((paymentMethods) => paymentMethods?.find((paymentMethod) => paymentMethod.id === id))
    );
  }

  public updatePaymentMethod(paymentMethod: MerchantCryptoPaymentMethodUpdateTo, paymentMethodId: string) {
    this.store.dispatch(
      updateCryptoPaymentMethodMerchantAction({
        paymentMethod: paymentMethod,
        paymentMethodId: paymentMethodId,
      })
    );
  }

  public updatePaymentMethodAsAdmin(
    merchantHashId: string,
    paymentMethod: MerchantCryptoPaymentMethodUpdateTo,
    paymentMethodId: string
  ) {
    this.store.dispatch(
      updateCryptoPaymentMethodMerchantAsAdminAction({
        merchantHashId: merchantHashId,
        paymentMethod: paymentMethod,
        paymentMethodId: paymentMethodId,
      })
    );
  }

  public getPaymentMethodToForOsTransaction(
    transaction: OtherSideTransaction
  ): Observable<MerchantCryptoPaymentMethodTo | null> {
    if (!transaction.paymentMethodId) {
      return this.getPaymentMethodForLegacyCurrency(transaction.currencyTo);
    }

    return this.getPaymentMethodById(transaction.paymentMethodId);
  }

  private getPaymentMethodForLegacyCurrency(currency: string | null): Observable<MerchantCryptoPaymentMethodTo | null> {
    if (!currency || (currency !== 'BTC' && currency !== 'LTC')) {
      // no currency or not legacy currency
      return null;
    }

    return this.getVisibleAndInvisiblePaymentMethods().pipe(
      map((paymentMethods) => paymentMethods?.find((paymentMethod) => paymentMethod.asset.id === currency))
    );
  }
}
