import { TodoItemModel } from '../../models/api/todo-item.model';
import { StateModel } from '../../models/auxiliary/state.model';
import { getErrorTodoListAction, getSuccessTodoListAction, getTodoListAction } from './todo-list.actions';
import { createReducer, on } from '@ngrx/store';

export type TodoState = StateModel<TodoItemModel[]>;

const INITIAL_STATE: TodoState = {
  errors: null,
  data: null,
  initial: true,
};

export const todoReducer = createReducer(
  INITIAL_STATE,
  on(getTodoListAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getSuccessTodoListAction, (state, { todoListResponse }) => ({
    errors: null,
    data: todoListResponse,
  })),
  on(getErrorTodoListAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
