/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';

enum TwoFaActionTypes {
  OpenTwoFaDialog = 'twoFa/OPEN_TWO_FA_DIALOG',
  CloseTwoFaDialog = 'twoFa/CLOSE_TWO_FA_DIALOG',
  UseTwoFaCode = 'twoFa/USE_TWO_FA_CODE',
}

export const openTwoFaDialogAction = createAction(TwoFaActionTypes.OpenTwoFaDialog);

export const closeTwoFaDialogAction = createAction(TwoFaActionTypes.CloseTwoFaDialog);

export const useTwoFaCodeAction = createAction(TwoFaActionTypes.UseTwoFaCode, props<{ code: string }>());
