/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { FinishUploadingFilesModel, MerchantDocument } from '../models/api/merchant-profile/merchant-document.model';

export const merchantDocumentActions = {
  GET_REQUEST: 'merchantDocument/GET_REQUEST',
  GET_SUCCESS: 'merchantDocument/GET_SUCCESS',
  GET_ERROR: 'merchantDocument/GET_ERROR',

  UPLOAD_REQUEST: 'merchantDocument/UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'merchantDocument/UPLOAD_SUCCESS',
  UPLOAD_ERROR: 'merchantDocument/UPLOAD_ERROR',

  FINISH_REQUEST: 'merchantDocument/FINISH_REQUEST',
  FINISH_SUCCESS: 'merchantDocument/FINISH_SUCCESS',
  FINISH_ERROR: 'merchantDocument/FINISH_ERROR',
};

export const getMerchantDocumentsAction = createAction(merchantDocumentActions.GET_REQUEST);

export const getMerchantDocumentsSuccessAction = createAction(
  merchantDocumentActions.GET_SUCCESS,
  props<{ files: MerchantDocument[] }>()
);

export const getMerchantDocumentsErrorAction = createAction(
  merchantDocumentActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const uploadMerchantDocumentsAction = createAction(
  merchantDocumentActions.UPLOAD_REQUEST,
  props<{ files: FormData }>()
);

export const uploadMerchantDocumentsSuccessAction = createAction(
  merchantDocumentActions.UPLOAD_SUCCESS,
  props<{ files: MerchantDocument[] }>()
);

export const uploadMerchantDocumentsErrorAction = createAction(
  merchantDocumentActions.UPLOAD_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const finishMerchantDocumentAction = createAction(
  merchantDocumentActions.FINISH_REQUEST,
  props<{ body: FinishUploadingFilesModel }>()
);

export const finishMerchantDocumentSuccessAction = createAction(merchantDocumentActions.FINISH_SUCCESS);

export const finishMerchantDocumentErrorAction = createAction(
  merchantDocumentActions.FINISH_ERROR,
  props<{ errors: ErrorModel[] }>()
);
