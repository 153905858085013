import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FireblocksVaultAccountApiService } from './fireblocks-vault-account-api.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  recoveryAction,
  recoveryErrorAction,
  recoverySuccessAction,
  setVisibleAction,
  setVisibleErrorAction,
  setVisibleSuccessAction,
} from './fireblocks-vault-account.actions';

@Injectable()
export class FireblocksVaultAccountEffect {
  setVisible$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setVisibleAction),
      switchMap(({ id, body }) =>
        this.fireblocksVaultAccountApiService.setVisible(id, body).pipe(
          map(() => setVisibleSuccessAction()),
          catchError((errors) => observableOf(setVisibleErrorAction(errors)))
        )
      )
    )
  );

  recover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recoveryAction),
      switchMap(({ id }) =>
        this.fireblocksVaultAccountApiService.recover(id).pipe(
          map((recoveryResult) => recoverySuccessAction({ recoveryResult })),
          catchError((errors) => observableOf(recoveryErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private fireblocksVaultAccountApiService: FireblocksVaultAccountApiService) {}
}
