import { StateModel } from '../models/auxiliary/state.model';
import { UserStatsModel } from '../models/api/user-stats.model';
import { getUserStatsErrorAction, getUserStatsSuccessAction } from '../actions/user-stats.actions';
import { createReducer, on } from '@ngrx/store';

export type UserStatsState = StateModel<UserStatsModel>;

const INITIAL_STATE: UserStatsState = {
  errors: null,
  data: null,
};

export const userStatsReducer = createReducer(
  INITIAL_STATE,
  on(getUserStatsSuccessAction, (state, { userStats }) => ({
    errors: null,
    data: userStats,
  })),
  on(getUserStatsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
