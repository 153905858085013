import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import {
  getPublicProfileAction,
  getPublicProfileByMerchantIdAction,
  getPublicProfileByMerchantIdErrorAction,
  getPublicProfileByMerchantIdSuccessAction,
  getPublicProfileErrorAction,
  getPublicProfileSuccessAction,
  updatePublicProfileAction,
  updatePublicProfileErrorAction,
  updatePublicProfileSuccessAction,
} from './public-profile.actions';

export const PUBLIC_PROFILE_ENDPOINT = '/public-profile';

@Injectable()
export class PublicProfileEffects {
  getForCurrentMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPublicProfileAction),
      mergeMap(() =>
        this.api.get(PUBLIC_PROFILE_ENDPOINT).pipe(
          map((publicProfile) => getPublicProfileSuccessAction({ publicProfile })),
          catchError((errors) => of(getPublicProfileErrorAction(errors)))
        )
      )
    )
  );

  getByMerchantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPublicProfileByMerchantIdAction),
      mergeMap(({ merchantId }) =>
        this.api.get(`${PUBLIC_PROFILE_ENDPOINT}/${merchantId}`).pipe(
          map((publicProfile) => getPublicProfileByMerchantIdSuccessAction({ publicProfile })),
          catchError((errors) => of(getPublicProfileByMerchantIdErrorAction(errors)))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePublicProfileAction),
      mergeMap(({ publicProfile }) =>
        this.api.put(PUBLIC_PROFILE_ENDPOINT, publicProfile).pipe(
          map((publicProfile) => updatePublicProfileSuccessAction({ publicProfile })),
          catchError((errors) => of(updatePublicProfileErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
