import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  createMerchantEmailInvoiceAction,
  emailInvoiceErrorAction,
  emailInvoiceSuccessAction,
  getEmailInvoiceAction,
  getMaxClickAction,
  maxClicksErrorAction,
  maxClicksSuccessAction,
} from '../actions/email-invoice.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService } from '../services/api.service';

export const EMAIL_INVOICE_ENDPOINT = `/email/{hash}/invoices`;
export const MERCHANT_EMAIL_INVOICE_ENDPOINT = '/email-invoices';
export const CREATE_MERCHANT_EMAIL_INVOICE_ENDPOINT = '/v3/email-invoices';
export const EMAIL_INVOICE_DETAIL_ENDPOINT = MERCHANT_EMAIL_INVOICE_ENDPOINT + '/{hashId}';
export const EMAIL_INVOICE_MAX_CLICK_ENDPOINT = '/email-invoices/max-clicks';

@Injectable()
export class EmailInvoiceEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmailInvoiceAction),
      mergeMap(({ emailInvoiceHashId, isAdmin }) =>
        this.api.get(this.detailUrl(emailInvoiceHashId, isAdmin)).pipe(
          map((emailInvoice) => emailInvoiceSuccessAction({ emailInvoice })),
          catchError((errors) => observableOf(emailInvoiceErrorAction(errors)))
        )
      )
    )
  );

  createMerchantEmailInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMerchantEmailInvoiceAction),
      switchMap(({ emailInvoice }) =>
        this.api.post(CREATE_MERCHANT_EMAIL_INVOICE_ENDPOINT, emailInvoice).pipe(
          map((emailInvoice) => emailInvoiceSuccessAction({ emailInvoice })),
          catchError((errors) => observableOf(emailInvoiceErrorAction(errors)))
        )
      )
    )
  );

  getMaxClicks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMaxClickAction),
      mergeMap(() =>
        this.api.get(EMAIL_INVOICE_MAX_CLICK_ENDPOINT).pipe(
          map((maxClicksParam) => maxClicksSuccessAction({ maxClicksParam })),
          catchError((errors) => observableOf(maxClicksErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  detailUrl(hash: string, isAdmin: boolean): string {
    if (isAdmin) {
      return `/${ADMIN_ENDPOINT_PREFIX}${MERCHANT_EMAIL_INVOICE_ENDPOINT}/` + hash;
    }
    return EMAIL_INVOICE_DETAIL_ENDPOINT.replace('{hashId}', hash);
  }

  url(hash: string): string {
    return EMAIL_INVOICE_ENDPOINT.replace('{hash}', hash);
  }
}
