/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { Google2FaActivationModel, TwoFAModel } from '../models/api/two-fa.model';
import { ErrorModel } from '../models/api/error.model';

export enum twoFactorAuthActions {
  GOOGLE_REGISTER_2FA_REQUEST = 'twoFactorAuth/GOOGLE_REGISTER_2FA_REQUEST',
  GOOGLE_REGISTER_2FA_SUCCESS = 'twoFactorAuth/GOOGLE_REGISTER_2FA_SUCCESS',
  GOOGLE_REGISTER_2FA_ERROR = 'twoFactorAuth/GOOGLE_REGISTER_2FA_ERROR',

  GOOGLE_ACTIVATE_2FA_REQUEST = 'twoFactorAuth/GOOGLE_ACTIVATE_2FA_REQUEST',
  GOOGLE_ACTIVATE_2FA_SUCCESS = 'twoFactorAuth/GOOGLE_ACTIVATE_2FA_SUCCESS',
  GOOGLE_ACTIVATE_2FA_ERROR = 'twoFactorAuth/GOOGLE_ACTIVATE_2FA_ERROR',

  GOOGLE_DISABLE_2FA_REQUEST = 'twoFactorAuth/GOOGLE_DISABLE_2FA_REQUEST',
  GOOGLE_DISABLE_2FA_SUCCESS = 'twoFactorAuth/GOOGLE_DISABLE_2FA_SUCCESS',
  GOOGLE_DISABLE_2FA_ERROR = 'twoFactorAuth/GOOGLE_DISABLE_2FA_ERROR',

  GOOGLE_DISABLE_2FA_AS_ADMIN_REQUEST = 'twoFactorAuth/GOOGLE_DISABLE_2FA_AS_ADMIN_REQUEST',
  GOOGLE_DISABLE_2FA_AS_ADMIN_SUCCESS = 'twoFactorAuth/GOOGLE_DISABLE_2FA_AS_ADMIN_SUCCESS',
  GOOGLE_DISABLE_2FA_AS_ADMIN_ERROR = 'twoFactorAuth/GOOGLE_DISABLE_2FA_AS_ADMIN_ERROR',

  EMAIL_2FA_REQUEST = 'twoFactorAuth/EMAIL_2FA_REQUEST',
  EMAIL_2FA_SUCCESS = 'twoFactorAuth/EMAIL_2FA_SUCCESS',
  EMAIL_2FA_ERROR = 'twoFactorAuth/EMAIL_2FA_ERROR',

  CLEAR = 'twoFactorAuth/CLEAR',
}

export const googleRegisterTwoFAAction = createAction(
  twoFactorAuthActions.GOOGLE_REGISTER_2FA_REQUEST,
  props<{ twoFACode: string }>()
);

export const googleRegisterTwoFASuccessAction = createAction(
  twoFactorAuthActions.GOOGLE_REGISTER_2FA_SUCCESS,
  props<{ twoFACode: TwoFAModel }>()
);

export const googleRegisterTwoFAErrorAction = createAction(
  twoFactorAuthActions.GOOGLE_REGISTER_2FA_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const googleActivateTwoFAAction = createAction(
  twoFactorAuthActions.GOOGLE_ACTIVATE_2FA_REQUEST,
  props<{ google2faActivation: Google2FaActivationModel }>()
);

export const googleActivateTwoFASuccessAction = createAction(twoFactorAuthActions.GOOGLE_ACTIVATE_2FA_SUCCESS);

export const googleActivateTwoFAErrorAction = createAction(
  twoFactorAuthActions.GOOGLE_ACTIVATE_2FA_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const googleDisableTwoFAAction = createAction(
  twoFactorAuthActions.GOOGLE_DISABLE_2FA_REQUEST,
  props<{ twoFACode: string }>()
);

export const googleDisableTwoFASuccessAction = createAction(twoFactorAuthActions.GOOGLE_DISABLE_2FA_SUCCESS);

export const googleDisableTwoFAErrorAction = createAction(
  twoFactorAuthActions.GOOGLE_DISABLE_2FA_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const googleDisableTwoFAAsAdminAction = createAction(
  twoFactorAuthActions.GOOGLE_DISABLE_2FA_AS_ADMIN_REQUEST,
  props<{ userId: string }>()
);

export const googleDisableTwoFAAsAdminSuccessAction = createAction(
  twoFactorAuthActions.GOOGLE_DISABLE_2FA_AS_ADMIN_SUCCESS
);

export const googleDisableTwoFAAsAdminErrorAction = createAction(
  twoFactorAuthActions.GOOGLE_DISABLE_2FA_AS_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const emailTwoFAAction = createAction(twoFactorAuthActions.EMAIL_2FA_REQUEST);

export const emailTwoFASuccessAction = createAction(twoFactorAuthActions.EMAIL_2FA_SUCCESS);

export const emailTwoFAErrorAction = createAction(
  twoFactorAuthActions.EMAIL_2FA_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const clearTwoFAAction = createAction(twoFactorAuthActions.CLEAR);
