/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../../models/api/error.model';
import { PublicCryptoPaymentMethodTo } from '../../../models/api/crypto-payment-method.model';

export enum CryptoPaymentMethodsPublicActions {
  GET_CRYPTO_PAYMENT_METHODS_PUBLIC_REQUEST = 'cryptoPaymentMethodsPublic/GET_CRYPTO_PAYMENT_METHODS_PUBLIC_REQUEST',
  GET_CRYPTO_PAYMENT_METHODS_PUBLIC_SUCCESS = 'cryptoPaymentMethodsPublic/GET_CRYPTO_PAYMENT_METHODS_PUBLIC_SUCCESS',
  GET_CRYPTO_PAYMENT_METHODS_PUBLIC_ERROR = 'cryptoPaymentMethodsPublic/GET_CRYPTO_PAYMENT_METHODS_PUBLIC_ERROR',
}

export const getCryptoPaymentMethodsPublicAction = createAction(
  CryptoPaymentMethodsPublicActions.GET_CRYPTO_PAYMENT_METHODS_PUBLIC_REQUEST
);

export const getCryptoPaymentMethodsPublicActionSuccess = createAction(
  CryptoPaymentMethodsPublicActions.GET_CRYPTO_PAYMENT_METHODS_PUBLIC_SUCCESS,
  props<{ paymentMethods: PublicCryptoPaymentMethodTo[] }>()
);

export const getCryptoPaymentMethodsPublicActionError = createAction(
  CryptoPaymentMethodsPublicActions.GET_CRYPTO_PAYMENT_METHODS_PUBLIC_ERROR,
  props<{ errors: ErrorModel[] }>()
);
