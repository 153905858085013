import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import { getErrorParameterAction, getParameterAction, getSuccessParameterAction } from '../actions/parameter.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';

export const PARAMTER_ENDPOINT = '/parameter/verification/max-files';

@Injectable()
export class ParameterEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getParameterAction),
      mergeMap(({ param }) =>
        this.api.get(this.getUrl(param)).pipe(
          map((parameterResponse) => getSuccessParameterAction({ parameterResponse })),
          catchError((errors) => observableOf(getErrorParameterAction(errors)))
        )
      )
    )
  );

  /**
   * For possible future use, where you can specify what parameter value to get.
   */
  private getUrl(param: string): string {
    return PARAMTER_ENDPOINT;
  }

  constructor(private actions$: Actions, private api: ApiService) {}
}
