import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { getMerchantVerificationStatusErrorAction } from '../actions/merchant-verification-status.actions';
import { AmlAdminService } from '../../platform/services/aml-admin.service';
import {
  adminUploadMerchantVerificationDocumentsAction,
  adminUploadMerchantVerificationDocumentsErrorAction,
  adminUploadMerchantVerificationDocumentsSuccessAction,
  getMerchantVerificationDocumentsAction,
  getMerchantVerificationDocumentsErrorAction,
  getMerchantVerificationDocumentsSuccessAction,
  updateMerchantVerificationDocumentAction,
  updateMerchantVerificationDocumentStatusAction,
} from '../actions/merchant-verification-documents.actions';

@Injectable()
export class MerchantVerificationDocumentsEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantVerificationDocumentsAction),
      filter(({ merchant }) => merchant !== null),
      mergeMap(({ merchant }) =>
        this.amlService.getMerchantDocuments(merchant).pipe(
          map((documents) => getMerchantVerificationDocumentsSuccessAction({ documents })),
          catchError((errors) => of(getMerchantVerificationStatusErrorAction(errors)))
        )
      )
    )
  );

  changeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantVerificationDocumentStatusAction),
      concatMap(({ merchant, file, status }) =>
        this.amlService.changeFileStatus(file, status, merchant).pipe(
          map((file) => updateMerchantVerificationDocumentAction({ file })),
          catchError((errors) => of(getMerchantVerificationDocumentsErrorAction(errors)))
        )
      )
    )
  );

  adminUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUploadMerchantVerificationDocumentsAction),
      switchMap(({ merchant, file }) =>
        this.amlService.uploadFile(file, merchant).pipe(
          map((documents) => adminUploadMerchantVerificationDocumentsSuccessAction({ documents })),
          catchError((errors) => of(adminUploadMerchantVerificationDocumentsErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private amlService: AmlAdminService) {}
}
