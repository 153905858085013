/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { WebhookInfoModel } from '../models/api/webhook-info.model';

export enum webhookInfoListActions {
  GET_REQUEST = 'webhookInfoList/GET_REQUEST',
  GET_SUCCESS = 'webhookInfoList/GET_SUCCESS',
  GET_ERROR = 'webhookInfoList/GET_ERROR',
  CLEAR = 'webhookInfoList/CLEAR',
  RESEND_WEBHOOK_REQUEST = 'webhookInfoList/RESEND_WEBHOOK_REQUEST',
  RESEND_WEBHOOK_SUCCESS = 'webhookInfoList/RESEND_WEBHOOK_SUCCESS',
  RESEND_WEBHOOK_ERROR = 'webhookInfoList/RESEND_WEBHOOK_ERROR',
}

export const getWebhookInfoListAction = createAction(
  webhookInfoListActions.GET_REQUEST,
  props<{ invoiceId: string }>()
);

export const getWebhookInfoListSuccessAction = createAction(
  webhookInfoListActions.GET_SUCCESS,
  props<{ webhooks: WebhookInfoModel[] }>()
);

export const getWebhookInfoListErrorAction = createAction(
  webhookInfoListActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const resendWebhookRequestAction = createAction(
  webhookInfoListActions.RESEND_WEBHOOK_REQUEST,
  props<{ invoiceId: string }>()
);

export const resendWebhookRequestSuccessAction = createAction(
  webhookInfoListActions.RESEND_WEBHOOK_SUCCESS,
  props<{ webhook: WebhookInfoModel }>()
);

export const resendWebhookRequestErrorAction = createAction(
  webhookInfoListActions.RESEND_WEBHOOK_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const clearWebhookAction = createAction(webhookInfoListActions.CLEAR);
