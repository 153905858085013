import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  emailInvoicePageActionError,
  emailInvoicePageActionSuccess,
  getEmailInvoicePageAction,
} from '../actions/email-invoice-page.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';

export const EMAIL_INVOICE_PAGE_ENDPOINT = 'email-invoices';

@Injectable()
export class EmailInvoicePageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmailInvoicePageAction),
      mergeMap(({ isAdmin, paging }) =>
        this.api.get(this.url(paging, isAdmin)).pipe(
          map((emailInvoices) => emailInvoicePageActionSuccess({ emailInvoices })),
          catchError((errors) => observableOf(emailInvoicePageActionError(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(params: any, isAdmin: boolean): string {
    if (isAdmin) {
      return `/${ADMIN_ENDPOINT_PREFIX}/${EMAIL_INVOICE_PAGE_ENDPOINT}${queryParams(params)}`;
    }
    return `/${EMAIL_INVOICE_PAGE_ENDPOINT}${queryParams(params)}`;
  }
}
