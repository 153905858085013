import { Injectable } from '@angular/core';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { Store } from '@ngrx/store';
import { MerchantProfileType } from '../enums/merchant-profile-type.enum';
import {
  getWizardStepByComponent,
  merchantVerificationCompanyRoutes,
  merchantVerificationPersonRoutes,
} from '../../platform/wizard-routing';
import { MerchantProfileVerificationStatus } from '../enums/merchant-profile-verification-status.enum';
import { MerchantVerificationComponent } from '../../platform/common-components/aml-commons/merchant-verification/merchant-verification.component';
import { MerchantDocumentsComponent } from '../../platform/merchant/aml-wizard/merchant-documents/merchant-documents.component';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { IdenfyWrapperComponent } from '../../platform/merchant/aml-wizard/idenfy/idenfy-wrapper/idenfy-wrapper.component';
import { PersonType } from '../enums/person-type.enum';
import { DirectorSelectorComponent } from '../../platform/merchant/aml-wizard/idenfy/director-selector/director-selector.component';
import { MerchantDirectorsListComponent } from '../../platform/merchant/aml-wizard/merchant-directors-list/merchant-directors-list.component';
import { MerchantPersonsComponent } from '../../platform/merchant/aml-wizard/merchant-persons/merchant-persons.component';

const DEFAULT_MAX_ALLOWED_STEP = 1;

@Injectable({
  providedIn: 'root',
})
export class MerchantVerificationWizardService {
  constructor(protected store: Store<AppStateModel>) {}

  public getMaximalAllowedStepForMerchant(merchant: MerchantProfileUnionModel): number {
    if (merchant == null) {
      return DEFAULT_MAX_ALLOWED_STEP;
    }

    const wizardRoutes =
      merchant.type === MerchantProfileType.COMPANY
        ? merchantVerificationCompanyRoutes
        : merchantVerificationPersonRoutes;

    const verificationStatus = merchant.verificationStatus;
    let maxAllowedStep = DEFAULT_MAX_ALLOWED_STEP;

    if (merchant.type === MerchantProfileType.COMPANY) {
      const hasDirectorSelectedForIdCheck =
        merchant.persons.filter((person) => person.personType === PersonType.DIRECTOR && person.selectedToIdCheck)
          .length !== 0;
      switch (verificationStatus) {
        default:
        case MerchantProfileVerificationStatus.NOT_VERIFIED:
          maxAllowedStep = getWizardStepByComponent(wizardRoutes, MerchantVerificationComponent);
          break;
        case MerchantProfileVerificationStatus.BASIC_INFO_ENTERED:
          if (hasDirectorSelectedForIdCheck) {
            maxAllowedStep = getWizardStepByComponent(wizardRoutes, IdenfyWrapperComponent);
          } else {
            maxAllowedStep = getWizardStepByComponent(wizardRoutes, DirectorSelectorComponent);
          }
          break;
        case MerchantProfileVerificationStatus.IDENFY_VERIFICATION:
          maxAllowedStep = getWizardStepByComponent(wizardRoutes, IdenfyWrapperComponent);
          break;
        case MerchantProfileVerificationStatus.IDENFY_COMPLETED:
        case MerchantProfileVerificationStatus.DOCUMENTS_NEEDED:
          maxAllowedStep = getWizardStepByComponent(wizardRoutes, MerchantDocumentsComponent);
          break;
        case MerchantProfileVerificationStatus.VERIFIED:
        case MerchantProfileVerificationStatus.DOCUMENTS_CHECK:
        case MerchantProfileVerificationStatus.REJECTED:
          maxAllowedStep = -1;
          break;
      }
    } else {
      switch (verificationStatus) {
        default:
        case MerchantProfileVerificationStatus.NOT_VERIFIED:
          maxAllowedStep = getWizardStepByComponent(wizardRoutes, MerchantVerificationComponent);
          break;
        case MerchantProfileVerificationStatus.BASIC_INFO_ENTERED:
        case MerchantProfileVerificationStatus.IDENFY_VERIFICATION:
          maxAllowedStep = getWizardStepByComponent(wizardRoutes, IdenfyWrapperComponent);
          break;
        case MerchantProfileVerificationStatus.IDENFY_COMPLETED:
        case MerchantProfileVerificationStatus.DOCUMENTS_NEEDED:
          maxAllowedStep = getWizardStepByComponent(wizardRoutes, MerchantDocumentsComponent);
          break;
        case MerchantProfileVerificationStatus.VERIFIED:
        case MerchantProfileVerificationStatus.DOCUMENTS_CHECK:
        case MerchantProfileVerificationStatus.REJECTED:
          maxAllowedStep = -1;
          break;
      }
    }

    return maxAllowedStep;
  }

  public redirectByVerificationState(merchant: MerchantProfileUnionModel): string {
    let url = `/platform/wizard/type-selection`;

    if (merchant == null || merchant.verificationStatus === MerchantProfileVerificationStatus.NOT_VERIFIED) {
      return url;
    }

    const wizardRoutes =
      merchant.type === MerchantProfileType.COMPANY
        ? merchantVerificationCompanyRoutes
        : merchantVerificationPersonRoutes;

    const verificationStatus = merchant.verificationStatus;

    if (merchant.type === MerchantProfileType.COMPANY) {
      const directors = merchant.persons.filter((person) => person.personType === PersonType.DIRECTOR);
      const hasFilledDirector = directors.length !== 0;
      const hasDirectorSelectedToIdCheck = directors.filter((director) => director.selectedToIdCheck).length !== 0;
      const statesWithoutButton = [
        MerchantProfileVerificationStatus.VERIFIED,
        MerchantProfileVerificationStatus.DOCUMENTS_CHECK,
        MerchantProfileVerificationStatus.REJECTED,
      ].join(', ');

      switch (verificationStatus) {
        case MerchantProfileVerificationStatus.BASIC_INFO_ENTERED:
          if (hasFilledDirector) {
            if (hasDirectorSelectedToIdCheck) {
              url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
                wizardRoutes,
                IdenfyWrapperComponent
              )}`;
            } else {
              url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
                wizardRoutes,
                DirectorSelectorComponent
              )}`;
            }
          } else {
            url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
              wizardRoutes,
              MerchantDirectorsListComponent
            )}`;
          }
          break;
        case MerchantProfileVerificationStatus.IDENFY_VERIFICATION:
          url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
            wizardRoutes,
            IdenfyWrapperComponent
          )}`;
          break;
        case MerchantProfileVerificationStatus.IDENFY_COMPLETED:
          url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
            wizardRoutes,
            MerchantPersonsComponent
          )}`;
          break;
        case MerchantProfileVerificationStatus.DOCUMENTS_NEEDED:
          url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
            wizardRoutes,
            MerchantDocumentsComponent
          )}`;
          break;
        /* VERIFIED, DOCUMENTS_CHECK and REJECTED */
        default:
          url = '/platform/dashboard';
          console.error(`Verification is not available for states: "${statesWithoutButton}"`);
          break;
      }
    } else {
      const statesWithoutButton = [
        MerchantProfileVerificationStatus.VERIFIED,
        MerchantProfileVerificationStatus.DOCUMENTS_CHECK,
        MerchantProfileVerificationStatus.REJECTED,
      ].join(', ');

      switch (verificationStatus) {
        case MerchantProfileVerificationStatus.BASIC_INFO_ENTERED:
        case MerchantProfileVerificationStatus.IDENFY_VERIFICATION:
          url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
            wizardRoutes,
            IdenfyWrapperComponent
          )}`;
          break;
        case MerchantProfileVerificationStatus.IDENFY_COMPLETED:
        case MerchantProfileVerificationStatus.DOCUMENTS_NEEDED:
          url = `/platform/wizard/${wizardRoutes.prefix}/step/${getWizardStepByComponent(
            wizardRoutes,
            MerchantDocumentsComponent
          )}`;
          break;
        /* VERIFIED, DOCUMENTS_CHECK and REJECTED */
        default:
          url = '/platform/dashboard';
          console.error(`Verification is not available for states: "${statesWithoutButton}"`);
          break;
      }
    }

    return url;
  }
}
