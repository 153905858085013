import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { SelectItemModel } from '../../../models/auxiliary/select-item.model';
import { scrollToElement } from '../../../utils';
import { AbstractSelectComponent } from '../abstract-select.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'bp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss', '../abstract-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends AbstractSelectComponent<SelectItemModel> implements ControlValueAccessor {
  @Output() selectedChange = new EventEmitter<SelectItemModel>();
  @Input() options: SelectItemModel[] = [];
  @Input() enabledOptions: SelectItemModel[] = [];
  @Input() selected: SelectItemModel = { value: '', label: '' };
  @Input() isHigh = false;
  @Input() minWidth: number;
  @Input() centered = false;
  @Input() normalFontWeight = false;
  @Input() errorMessage: string;
  @Input() redesigned = false;
  focused = false;
  private onChange = (value: SelectItemModel['value']): void => {};
  private onTouched = (): void => {};

  setOptionByArrowButton(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Tab':
        this.listShown = false;
        break;
      case 'Enter':
        event.preventDefault();
        this.toggleOptionList();
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.enabledOptions.length > 0) {
          this.selected = this.getPrevOption(this.selected, this.enabledOptions);
          this.selectedChange.emit(this.selected);
          scrollToElement(this.optionsContainer, this.idPrefix + this.selected.value.toString().replace(/ /g, '-'));
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (this.enabledOptions.length > 0) {
          this.selected = this.getNextOption(this.selected, this.enabledOptions);
          this.selectedChange.emit(this.selected);
          scrollToElement(this.optionsContainer, this.idPrefix + this.selected.value.toString().replace(/ /g, '-'));
        }
        break;
      default:
        this.setOptionBySubstring(event.key);
    }
  }

  setOption(option: SelectItemModel): void {
    if (this.isOptionEnabled(option)) {
      this.listShown = false;
      this.selected = option;
      this.onChange(option.value);
      this.onTouched();
      this.selectedChange.emit(option);
    }
  }

  isOptionSelected(option: SelectItemModel): boolean {
    return (
      Object.is(this.selected, option) || (this.selected.value === option.value && this.selected.label === option.label)
    );
  }

  isOptionEnabled(option: SelectItemModel): boolean {
    return this.enabledOptions.some((item) => item === option);
  }

  setFocus(focused: boolean): void {
    if (focused) {
      this.touched = true;
    } else {
      this.onTouched();
    }
    this.focused = focused;
  }

  findOptionByStoredSubstring(): void {
    const option = this.enabledOptions.find((option) => option.label.toLowerCase().startsWith(this.searchString));
    if (option) {
      this.selected = option;
      this.selectedChange.emit(this.selected);
      scrollToElement(this.optionsContainer, this.idPrefix + this.selected.value.toString().replace(/ /g, '-'));
    }
  }

  get showError(): boolean {
    return this.touched && !this.focused && this.errorMessage && !this.selected.value && !this.listShown;
  }

  writeValue(obj: SelectItemModel['value']): void {
    this.selected = this.options.find((option) => option.value === obj) || this.selected;
  }
  registerOnChange(fn: SelectItemModel['value']): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
