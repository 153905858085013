import { StateModel } from '../models/auxiliary/state.model';
import {
  getPayoutFeesAction,
  getPayoutFeesErrorAction,
  getPayoutFeesSuccessAction,
} from '../actions/payout-fees.actions';
import { PayoutFeesWithCurrencyModel } from '../models/api/payout-fees.model';
import { createReducer, on } from '@ngrx/store';

export type PayoutFeesState = StateModel<PayoutFeesWithCurrencyModel[]>;

const INITIAL_STATE: PayoutFeesState = {
  errors: null,
  data: null,
};

export const payoutFeesReducer = createReducer(
  INITIAL_STATE,
  on(getPayoutFeesAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getPayoutFeesSuccessAction, (state, { payoutFees }) => ({
    ...state,
    data: payoutFees,
  })),
  on(getPayoutFeesErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  }))
);
