import { StateModel } from '../models/auxiliary/state.model';
import { ApiKeyModel } from '../models/api/api-key.model';
import { clearAction, createApiKeyErrorAction, createApiKeySuccessAction } from '../actions/api-key-detail.actions';
import { createReducer, on } from '@ngrx/store';

export type ApiKeyCreateState = StateModel<ApiKeyModel>;

const INITIAL_STATE: ApiKeyCreateState = {
  errors: null,
  data: null,
};

export const apiKeyCreateReducer = createReducer(
  INITIAL_STATE,
  on(createApiKeySuccessAction, (state, { apiKey }) => ({
    errors: null,
    data: apiKey,
  })),
  on(createApiKeyErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
  on(clearAction, () => ({ ...INITIAL_STATE }))
);
