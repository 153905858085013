/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { PageRequestFiltersModel, PageRequestModel } from '../models/auxiliary/page-request.model';

export enum merchantProfilePageActions {
  GET_REQUEST = 'merchantProfilePage/GET_REQUEST',
  GET_SUCCESS = 'merchantProfilePage/GET_SUCCESS',
  GET_ERROR = 'merchantProfilePage/GET_ERROR',
}

export const getMerchantProfilePageAction = createAction(
  merchantProfilePageActions.GET_REQUEST,
  props<{ paging: PageRequestModel; filters: PageRequestFiltersModel }>()
);

export const getMerchantProfilePageSuccessAction = createAction(
  merchantProfilePageActions.GET_SUCCESS,
  props<{ merchantProfiles: PagingModel<MerchantProfileUnionModel> }>()
);

export const getMerchantProfilePageErrorAction = createAction(
  merchantProfilePageActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);
