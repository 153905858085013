import { of } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import {
  createCryptoWalletAction,
  createCryptoWalletErrorAction,
  createCryptoWalletSuccessAction,
  getCryptoWalletsAction,
  getCryptoWalletsErrorAction,
  getCryptoWalletsSuccessAction,
  updateCryptoWalletAction,
  updateCryptoWalletErrorAction,
  updateCryptoWalletSuccessAction,
} from '../actions/crypto-wallets.actions';

export const ADDRESSES_ENDPOINT = '/admin/crypto-wallets';

@Injectable()
export class CryptoWalletsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoWalletsAction),
      mergeMap(() =>
        this.api.get(ADDRESSES_ENDPOINT).pipe(
          map((wallets) => getCryptoWalletsSuccessAction({ wallets })),
          catchError((errors) => of(getCryptoWalletsErrorAction(errors)))
        )
      )
    )
  );

  uploadAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCryptoWalletAction),
      switchMap(({ wallet }) =>
        this.api.post(ADDRESSES_ENDPOINT, wallet).pipe(
          map((wallet) => createCryptoWalletSuccessAction({ wallet })),
          catchError((errors) => of(createCryptoWalletErrorAction(errors)))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCryptoWalletAction),
      switchMap(({ walletId, wallet }) =>
        this.api.patch(`${ADDRESSES_ENDPOINT}/${walletId}`, wallet).pipe(
          map((wallet) => updateCryptoWalletSuccessAction({ wallet })),
          catchError((errors) => of(updateCryptoWalletErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
