import { InvoiceModel } from '../models/api/invoice.model';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';
import {
  createInvoiceFromButtonErrorAction,
  createInvoiceFromButtonSuccessAction,
  createInvoiceFromEmailInvoiceErrorAction,
  createInvoiceFromEmailInvoiceSuccessAction,
  getInvoiceAction,
  getInvoiceErrorAction,
  getInvoiceSuccessAction,
  setNullAmountInvoiceErrorAction,
  setNullAmountInvoiceSuccessAction,
} from '../actions/invoice.actions';

export type InvoiceState = StateModel<InvoiceModel>;

const INITIAL_STATE: InvoiceState = {
  errors: null,
  data: null,
};

export const invoiceReducer = createReducer(
  INITIAL_STATE,
  on(getInvoiceAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(
    getInvoiceSuccessAction,
    createInvoiceFromEmailInvoiceSuccessAction,
    createInvoiceFromButtonSuccessAction,
    (state, { invoice }) => ({
      errors: null,
      data: invoice,
    })
  ),
  on(setNullAmountInvoiceSuccessAction, (state) => ({
    errors: null,
  })),
  on(getInvoiceErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
  on(
    createInvoiceFromEmailInvoiceErrorAction,
    createInvoiceFromButtonErrorAction,
    setNullAmountInvoiceErrorAction,
    (state, { errors }) => ({
      ...state,
      errors: errors,
    })
  )
);
