import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  createUserAction,
  createUserAsAdminAction,
  createUserErrorAction,
  createUserSuccessAction,
  getUserDetailAction,
  getUserDetailErrorAction,
  getUserDetailSuccessAction,
  updateMerchantPopupFormAction,
  updateMerchantPopupFormErrorAction,
  updateMerchantPopupFormSuccessAction,
} from '../actions/user-detail.actions';
import { ApiService, get2FaHeaders } from '../services/api.service';
import { MERCHANT_USER_LIST_ENDPOINT } from '../store/user-list/user-list.effects';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';

export const USER_CREATE_ADMIN_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/{merchantHashId}/users`;

@Injectable()
export class UserDetailEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetailAction),
      mergeMap(({ id }) =>
        this.api.get(`${MERCHANT_USER_LIST_ENDPOINT}/${id}`).pipe(
          map((user) => getUserDetailSuccessAction({ user })),
          catchError((errors) => observableOf(getUserDetailErrorAction(errors)))
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserAction),
      switchMap(({ user, twoFaCode }) =>
        this.api.post(`${MERCHANT_USER_LIST_ENDPOINT}`, user, get2FaHeaders(twoFaCode)).pipe(
          map((user) => createUserSuccessAction({ user })),
          catchError((errors) => observableOf(createUserErrorAction(errors)))
        )
      )
    )
  );

  createAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserAsAdminAction),
      switchMap(({ user, merchantId }) =>
        this.api.post(`${USER_CREATE_ADMIN_ENDPOINT.replace('{merchantHashId}', merchantId)}`, user).pipe(
          map((user) => createUserSuccessAction({ user })),
          catchError((errors) => observableOf(createUserErrorAction(errors)))
        )
      )
    )
  );

  updateMerchantPopupForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantPopupFormAction),
      switchMap(({ popupFilled, user }) =>
        this.api.patch(`/merchants/${user.merchantId}/popup-form`, popupFilled).pipe(
          map(() => updateMerchantPopupFormSuccessAction()),
          catchError((errors) => observableOf(updateMerchantPopupFormErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
