import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../../services/api.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getCryptoPaymentMethodsPublicAction,
  getCryptoPaymentMethodsPublicActionError,
  getCryptoPaymentMethodsPublicActionSuccess,
} from './crypto-payment-methods-public.actions';

export const PUBLIC_PAYMENT_METHODS_ENDPOINT = '/public/payment-methods';

@Injectable()
export class CryptoPaymentMethodsPublicEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoPaymentMethodsPublicAction),
      mergeMap(() =>
        this.api.get(PUBLIC_PAYMENT_METHODS_ENDPOINT).pipe(
          map((paymentMethods) => getCryptoPaymentMethodsPublicActionSuccess({ paymentMethods })),
          catchError((errors) => of(getCryptoPaymentMethodsPublicActionError(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
