/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { ConfigurationItemModel } from '../models/api/configuration-item.model';

export enum configurationListActions {
  GET_REQUEST = 'configurationList/GET_REQUEST',
  GET_SUCCESS = 'configurationList/GET_SUCCESS',
  GET_ERROR = 'configurationList/GET_ERROR',
  RELOAD_DATA_REQUEST = 'configurationList/RELOAD_DATA_REQUEST',
  UPDATE_CONFIG_ITEM_REQUEST = 'configurationList/UPDATE_CONFIG_ITEM_REQUEST',
  UPDATE_CONFIG_ITEM_SUCCESS = 'configurationList/UPDATE_CONFIG_ITEM_SUCCESS',
  UPDATE_CONFIG_ITEM_ERROR = 'configurationList/UPDATE_CONFIG_ITEM_ERROR',
}

export const getConfigurationListAction = createAction(configurationListActions.GET_REQUEST);

export const getConfigurationListSuccessAction = createAction(
  configurationListActions.GET_SUCCESS,
  props<{ configurationList: ConfigurationItemModel[] }>()
);

export const getConfigurationListErrorAction = createAction(
  configurationListActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const updateConfigurationItemAction = createAction(
  configurationListActions.UPDATE_CONFIG_ITEM_REQUEST,
  props<{ configurationItem: ConfigurationItemModel }>()
);

export const updateConfigurationItemSuccessAction = createAction(configurationListActions.UPDATE_CONFIG_ITEM_SUCCESS);

export const updateConfigurationItemErrorAction = createAction(
  configurationListActions.UPDATE_CONFIG_ITEM_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const reloadConfigurationListAction = createAction(configurationListActions.RELOAD_DATA_REQUEST);
