import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { PaymentButtonService } from '../../platform/services/payment-button.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  createPaymentButtonAction,
  createPaymentButtonErrorAction,
  createPaymentButtonSuccessAction,
  deletePaymentButtonAction,
  deletePaymentButtonErrorAction,
  deletePaymentButtonSuccessAction,
  getPaymentButtonDetailAction,
  getPaymentButtonDetailErrorAction,
  getPaymentButtonDetailSuccessAction,
  getPaymentButtonPageAction,
  getPaymentButtonPageErrorAction,
  getPaymentButtonPageSuccessAction,
  getPaymentButtonPublicInfoAction,
  getPaymentButtonPublicInfoErrorAction,
  getPaymentButtonPublicInfoSuccessAction,
} from '../actions/payment-button.actions';

@Injectable()
export class PaymentButtonEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPaymentButtonAction),
      switchMap(({ button }) =>
        this.paymentButtonService.create(button).pipe(
          map((button) => createPaymentButtonSuccessAction({ button })),
          catchError((errors) => of(createPaymentButtonErrorAction(errors)))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePaymentButtonAction),
      mergeMap((action) =>
        this.paymentButtonService.delete(action.button).pipe(
          map(() => deletePaymentButtonSuccessAction({ button: action.button })),
          catchError((errors) => of(deletePaymentButtonErrorAction(errors)))
        )
      )
    )
  );

  getDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentButtonDetailAction),
      mergeMap(({ id }) =>
        this.paymentButtonService.get(id).pipe(
          map((button) => getPaymentButtonDetailSuccessAction({ button })),
          catchError((errors) => of(getPaymentButtonDetailErrorAction(errors)))
        )
      )
    )
  );

  getPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentButtonPageAction),
      mergeMap(({ filters, paging }) =>
        this.paymentButtonService.getList(paging, filters).pipe(
          map((paymentButtonPage) => getPaymentButtonPageSuccessAction({ paymentButtonPage })),
          catchError((errors) => of(getPaymentButtonPageErrorAction(errors)))
        )
      )
    )
  );

  getButtonPublicInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentButtonPublicInfoAction),
      mergeMap(({ hashCode }) =>
        this.paymentButtonService.getPublicInfo(hashCode).pipe(
          map((publicInfo) => getPaymentButtonPublicInfoSuccessAction({ publicInfo })),
          catchError((errors) => of(getPaymentButtonPublicInfoErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private paymentButtonService: PaymentButtonService) {}
}
