import { createReducer, on } from '@ngrx/store';
import { getMerchantPopupFormErrorAction, getMerchantPopupFormSuccessAction } from '../actions/merchant.actions';
import { MerchantModel } from '../models/api/merchant.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantState = StateModel<MerchantModel>;

const INITIAL_STATE: MerchantState = {
  errors: null,
  data: null,
};

export const merchantReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantPopupFormSuccessAction, (state, { popupFormFilled }) => ({
    errors: null,
    data: { ...state.data, popupFormFilled: popupFormFilled },
  })),
  on(getMerchantPopupFormErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
