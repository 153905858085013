import { createAction, props } from '@ngrx/store';
import { PosDeviceTo } from '../../models/api/pos-devices.model';
import { ErrorModel } from '../../models/api/error.model';

enum PosDevicesActionTypes {
  LOAD_REQUEST = 'posDevices/LOAD_REQUEST',
  LOAD_SUCCESS = 'posDevices/LOAD_SUCCESS',
  LOAD_ERROR = 'posDevices/LOAD_ERROR',

  UNPAIR_REQUEST = 'posDevices/UNPAIR_REQUEST',
  UNPAIR_SUCCESS = 'posDevices/UNPAIR_SUCCESS',
  UNPAIR_ERROR = 'posDevices/UNPAIR_ERROR',

  DELETE_REQUEST = 'posDevices/DELETE_REQUEST',
  DELETE_SUCCESS = 'posDevices/DELETE_SUCCESS',
  DELETE_ERROR = 'posDevices/DELETE_ERROR',

  CREATE_REQUEST = 'posDevices/CREATE_REQUEST',
  CREATE_SUCCESS = 'posDevices/CREATE_SUCCESS',
  CREATE_ERROR = 'posDevices/CREATE_ERROR',

  GET_REQUEST = 'posDevices/GET_REQUEST',
  GET_SUCCESS = 'posDevices/GET_SUCCESS',
  GET_ERROR = 'posDevices/GET_ERROR',

  REFRESH_ACTIVATION_CODE_REQUEST = 'posDevices/REFRESH_ACTIVATION_CODE_REQUEST',
  REFRESH_ACTIVATION_CODE_SUCCESS = 'posDevices/REFRESH_ACTIVATION_CODE_SUCCESS',
  REFRESH_ACTIVATION_CODE_ERROR = 'posDevices/REFRESH_ACTIVATION_CODE_ERROR',
}

export const getPosDevicesAction = createAction(PosDevicesActionTypes.LOAD_REQUEST);

export const getPosDevicesSuccessAction = createAction(
  PosDevicesActionTypes.LOAD_SUCCESS,
  props<{ posDevices: PosDeviceTo[] }>()
);

export const getPosDevicesErrorAction = createAction(
  PosDevicesActionTypes.LOAD_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const unpairPosDeviceAction = createAction(
  PosDevicesActionTypes.UNPAIR_REQUEST,
  props<{ posDeviceId: string }>()
);
export const unpairPosDeviceSuccessAction = createAction(
  PosDevicesActionTypes.UNPAIR_SUCCESS,
  props<{ posDevice: PosDeviceTo }>()
);

export const unpairPosDeviceErrorAction = createAction(
  PosDevicesActionTypes.UNPAIR_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const deletePosDeviceAction = createAction(
  PosDevicesActionTypes.DELETE_REQUEST,
  props<{ posDeviceId: string }>()
);
export const deletePosDeviceSuccessAction = createAction(
  PosDevicesActionTypes.DELETE_SUCCESS,
  props<{ posDevice: PosDeviceTo }>()
);

export const deletePosDeviceErrorAction = createAction(
  PosDevicesActionTypes.DELETE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const createPosDeviceAction = createAction(
  PosDevicesActionTypes.CREATE_REQUEST,
  props<{ posDevice: PosDeviceTo }>()
);

export const createPosDeviceSuccessAction = createAction(
  PosDevicesActionTypes.CREATE_SUCCESS,
  props<{ posDevice: PosDeviceTo }>()
);

export const createPosDeviceErrorAction = createAction(
  PosDevicesActionTypes.CREATE_ERROR,
  props<{ errors: ErrorModel[] }>()
);

export const getPosDeviceAction = createAction(PosDevicesActionTypes.GET_REQUEST, props<{ posDeviceId: string }>());

export const getPosDeviceSuccessAction = createAction(
  PosDevicesActionTypes.GET_SUCCESS,
  props<{ posDevice: PosDeviceTo }>()
);

export const getPosDeviceErrorAction = createAction(PosDevicesActionTypes.GET_ERROR, props<{ errors: ErrorModel[] }>());

export const refreshPosDeviceActivationCodeAction = createAction(
  PosDevicesActionTypes.REFRESH_ACTIVATION_CODE_REQUEST,
  props<{ posDeviceId: string }>()
);

export const refreshPosDeviceActivationCodeSuccessAction = createAction(
  PosDevicesActionTypes.REFRESH_ACTIVATION_CODE_SUCCESS,
  props<{ posDevice: PosDeviceTo }>()
);

export const refreshPosDeviceActivationCodeErrorAction = createAction(
  PosDevicesActionTypes.REFRESH_ACTIVATION_CODE_ERROR,
  props<{ errors: ErrorModel[] }>()
);
