import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { Store } from '@ngrx/store';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { TwoFaType } from '../models/api/auth.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { invalidTokenUsedAction, twoFactorInvalidCodeAction } from '../actions/auth.actions';
import { selectAuth } from '../selectors/auth.selector';

enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

const PUBLIC_ENDPOINTS_REGEXPS = [
  '^/v3/currencies',
  '^/websocket/.*',
  '^/actuator/info',
  '^/countries',
  '^/countries/ip-info',
  '^/user/register',
  '^/user/activate/[a-zA-Z0-9]*',
  '^/user/password/recovery',
  '^/user/password/recovery/[a-zA-Z0-9]*',
  '^/invoices/[a-zA-Z0-9]*/refund',
  '^/invoices/[a-zA-Z0-9]*/prices',
  '^/invoices/[a-zA-Z0-9]*/currency',
  '^/email/[a-zA-Z0-9]*/invoices',
  '^/button/[a-zA-Z0-9]*/invoices',
  '^/button-invoices/[a-zA-Z0-9]*/public-information',
  '^/public-profile/[a-zA-Z0-9]*',
  '^/invoice/[a-zA-Z0-9]*/customer-email',
  '^/invoice/[a-zA-Z0-9]*/customer-email/is-settable',
  '^/public/payment-methods',
  '^/crypto-addresses/verify',
  '^/merchants/[a-zA-Z0-9]*/payment-methods', // because it is used not only on platform pages, but also on public invoice
];

interface HttpHeadersObject {
  [header: string]: string;
}

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private store: Store<AppStateModel>,
    private appConfig: AppConfigService,
    private recaptchaService: ReCaptchaV3Service
  ) {}

  downloadFile(url: string, customHeaders?: HttpHeadersObject): Observable<HttpResponse<Blob>> {
    return this.request(HttpMethod.GET, url, null, customHeaders, 'blob', {
      observe: 'response',
    });
  }

  getAsText(url: string, customHeaders?: HttpHeadersObject): Observable<string> {
    return this.request(HttpMethod.GET, url, null, customHeaders, 'text');
  }

  get(url: string, customHeaders?: HttpHeadersObject): Observable<any> {
    return this.request(HttpMethod.GET, url, null, customHeaders);
  }

  post(url: string, body?: any, customHeaders?: HttpHeadersObject): Observable<any> {
    return this.request(HttpMethod.POST, url, body, customHeaders);
  }

  put(url: string, body?: any, customHeaders?: HttpHeadersObject): Observable<any> {
    return this.request(HttpMethod.PUT, url, body, customHeaders);
  }

  patch(url: string, body?: any, customHeaders?: HttpHeadersObject): Observable<any> {
    return this.request(HttpMethod.PATCH, url, body, customHeaders);
  }

  delete(url: string, customHeaders?: HttpHeadersObject): Observable<any> {
    return this.request(HttpMethod.DELETE, url, null, customHeaders);
  }

  private requestWithoutHandlingErrors(
    method: HttpMethod,
    url: string,
    body?: any,
    customHeaders?: HttpHeadersObject,
    responseType: string = 'json',
    customOptions?: any
  ): Observable<any> {
    return this.store.select(selectAuth).pipe(
      take(1),
      mergeMap((state) => {
        const token = state.data != null ? state.data.token : null;

        this.deleteHeadersWithNullOrUndefinedValue(customHeaders);
        let httpHeaders = new HttpHeaders(customHeaders);
        if (token != null) {
          if (!this.isRequestToPublicEndpoint(url)) {
            httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + token);
          }
        }

        if (!/^https?:\/\//i.test(url)) {
          url = `${this.appConfig.config.apiUrl}${url}`;
        }

        const options = {
          headers: httpHeaders,
          body,
          responseType: (responseType || 'json') as any,
          ...customOptions,
        };

        return this.http.request(method, url, options);
      })
    );
  }

  private request(
    method: HttpMethod,
    url: string,
    body?: any,
    customHeaders?: HttpHeadersObject,
    responseType: string = 'json',
    customOptions?: any
  ): Observable<any> {
    return this.requestWithoutHandlingErrors(method, url, body, customHeaders, responseType, customOptions).pipe(
      catchError(
        (response: HttpErrorResponse) =>
          new Observable((subscriber) => {
            switch (response.status) {
              case 401:
                if (this.isInvalid2faErrorResponse(response.error)) {
                  this.store.dispatch(twoFactorInvalidCodeAction({ error: response.error }));
                  return;
                } else if (this.isInvalidAuthTokenErrorResponse(response.error)) {
                  this.store.dispatch(invalidTokenUsedAction({ error: response.error }));
                  return;
                }
            }

            subscriber.error(response.error);
          })
      )
    );
  }

  private deleteHeadersWithNullOrUndefinedValue(customHeaders?: HttpHeadersObject): void {
    if (customHeaders) {
      Object.keys(customHeaders).forEach((key) => {
        if (!customHeaders[key]) {
          delete customHeaders[key];
        }
      });
    }
  }

  post_token(email: string, password: string, google2fa?: string): Observable<any> {
    return this.recaptchaService.execute('login').pipe(
      switchMap((token) => {
        const params = ApiService.tokenFormParams(email, password, google2fa);
        const customHeaders = {
          'Content-Type': 'application/x-www-form-urlencoded',
          recaptcha: token,
        };

        return this.requestWithoutHandlingErrors(
          HttpMethod.POST,
          '/oauth/login',
          params.toString(),
          customHeaders
        ).pipe(catchError((response) => throwError(response.error)));
      })
    );
  }

  isInvalid2faErrorResponse(response: any): boolean {
    if (response.errors == null || response.errors[0] == null) {
      return false;
    }
    return (
      response.errors[0].type === 'invalid_2fa' ||
      response.errors[0].type === 'invalid_2fa_email' ||
      response.errors[0].type === 'invalid_2fa_google'
    );
  }

  get2faTypeFromErrorCode(response: any): TwoFaType {
    if (response.errors[0].type === 'invalid_2fa_email') {
      return TwoFaType.EMAIL_2FA;
    } else if (response.errors[0].type === 'invalid_2fa_google') {
      return TwoFaType.GOOGLE_2FA;
    } else {
      return null;
    }
  }

  isInvalidAuthTokenErrorResponse(response: any): boolean {
    if (response.errors == null || response.errors[0] == null) {
      return false;
    }
    return response.errors[0].type === 'invalid_token';
  }

  isRequestToPublicEndpoint(url: string): boolean {
    if (/^https?:\/\//i.test(url)) {
      return true;
    } else {
      return PUBLIC_ENDPOINTS_REGEXPS.some((regexp) => !!url.match(regexp));
    }
  }

  private static tokenFormParams(username: string, password: string, google2fa?: string): URLSearchParams {
    const params = new URLSearchParams();
    params.set('username', username);
    params.set('password', password);
    if (google2fa != null) {
      params.set('google_2fa', google2fa);
    }
    return params;
  }
}

export function get2FaHeaders(token: string): HttpHeadersObject {
  return {
    'X-BitcoinPay-OTP': token,
  };
}
