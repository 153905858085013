import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import moment from 'moment';

import { ApiService } from '../services/api.service';
import { getIsoDateString } from '../utils';
import {
  createMerchantPersonAction,
  createMerchantPersonAdminAction,
  createMerchantPersonAdminErrorAction,
  createMerchantPersonAdminSuccessAction,
  createMerchantPersonErrorAction,
  createMerchantPersonSuccessAction,
  deleteMerchantPersonAction,
  deleteMerchantPersonAdminAction,
  deleteMerchantPersonAdminErrorAction,
  deleteMerchantPersonAdminSuccessAction,
  deleteMerchantPersonErrorAction,
  deleteMerchantPersonSuccessAction,
  promoteDemoteMerchantPersonAdminAction,
  promoteDemoteMerchantPersonAdminErrorAction,
  promoteDemoteMerchantPersonAdminSuccessAction,
  updateFlagsAction,
  updateFlagsErrorAction,
  updateFlagsSuccessAction,
  updateMerchantPersonAdminAction,
  updateMerchantPersonAdminErrorAction,
  updateMerchantPersonAdminSuccessAction,
} from '../actions/merchant-person.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';

export const MERCHANT_PERSONS_ENDPOINT = '/merchant-profile/persons';
export const MERCHANT_PROFILE_ENDPOINT = '/merchant-profile';

export const MERCHANT_PERSON_ENDPOINT = '/merchant-profile/person';

export const MERCHANT_ADMIN_ENDPOINT = '/admin/merchants/';
export const MERCHANT_OTHER_COMPANY_PERSONS_ENDPOINT = '/merchant-profile/other-company-persons';
export const PROMOTE_DEMOTE_ENDPOINT = 'promote-demote/';

@Injectable()
export class MerchantPersonEffect {
  setFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFlagsAction),
      switchMap(({ flags, personId }) =>
        this.api.post(`${MERCHANT_PERSONS_ENDPOINT}/${personId}/flags`, flags).pipe(
          map(() => updateFlagsSuccessAction({ flags: flags, personId: personId })),
          catchError((errors) => observableOf(updateFlagsErrorAction(errors)))
        )
      )
    )
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMerchantPersonAction),
      switchMap(({ person }) => {
        const payload = {
          ...person,
          birthDate: getIsoDateString(moment(person.birthDate)) as any,
        };

        return this.api.post(`${MERCHANT_PROFILE_ENDPOINT}/other-company-persons`, payload).pipe(
          map((addedPerson) => createMerchantPersonSuccessAction({ addedPerson })),
          catchError((errors) => observableOf(createMerchantPersonErrorAction(errors)))
        );
      })
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMerchantPersonAction),
      mergeMap(({ person }) =>
        this.api.delete(`${MERCHANT_PERSONS_ENDPOINT}/${person.id}`).pipe(
          map(() => deleteMerchantPersonSuccessAction({ person })),
          catchError((errors) => observableOf(deleteMerchantPersonErrorAction(errors)))
        )
      )
    )
  );

  updatePersonAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantPersonAdminAction),
      mergeMap(({ merchantId, person }) =>
        this.api.put(`${MERCHANT_ADMIN_ENDPOINT}${merchantId}${MERCHANT_OTHER_COMPANY_PERSONS_ENDPOINT}`, person).pipe(
          map((person) => updateMerchantPersonAdminSuccessAction({ person })),
          catchError((errors) => observableOf(updateMerchantPersonAdminErrorAction(errors)))
        )
      )
    )
  );

  deleteAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMerchantPersonAdminAction),
      mergeMap(({ person }) =>
        this.api.delete(`/${ADMIN_ENDPOINT_PREFIX}${MERCHANT_PERSONS_ENDPOINT}/${person.id}`).pipe(
          map(() => deleteMerchantPersonAdminSuccessAction()),
          catchError((errors) => observableOf(deleteMerchantPersonAdminErrorAction(errors)))
        )
      )
    )
  );

  createAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMerchantPersonAdminAction),
      switchMap(({ merchantId, person }) =>
        this.api.post(`/${ADMIN_ENDPOINT_PREFIX}${MERCHANT_PERSON_ENDPOINT}/${merchantId}`, person).pipe(
          map(() => createMerchantPersonAdminSuccessAction()),
          catchError((errors) => observableOf(createMerchantPersonAdminErrorAction(errors)))
        )
      )
    )
  );

  promoteDemoteAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(promoteDemoteMerchantPersonAdminAction),
      mergeMap(({ merchantId, personId }) =>
        this.api
          .put(
            `/${ADMIN_ENDPOINT_PREFIX}${MERCHANT_PERSON_ENDPOINT}/${PROMOTE_DEMOTE_ENDPOINT}${merchantId}/${personId}`
          )
          .pipe(
            map(() => promoteDemoteMerchantPersonAdminSuccessAction()),
            catchError((errors) => observableOf(promoteDemoteMerchantPersonAdminErrorAction(errors)))
          )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
