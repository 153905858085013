import {
  emailInvoicePageActionError,
  emailInvoicePageActionSuccess,
  getEmailInvoicePageAction,
} from '../actions/email-invoice-page.actions';
import { AdminEmailInvoiceModel } from '../models/api/email-invoice.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';

export type EmailInvoicePageState = StateModel<PagingModel<AdminEmailInvoiceModel>>;

const INITIAL_STATE: EmailInvoicePageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
  loading: true,
};

export const emailInvoicePageReducer = createReducer(
  INITIAL_STATE,
  on(getEmailInvoicePageAction, () => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(emailInvoicePageActionSuccess, (state, { emailInvoices }) => ({
    data: emailInvoices,
    errors: null,
    loading: false,
  })),
  on(emailInvoicePageActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
