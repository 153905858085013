/* eslint-disable max-classes-per-file */

import { InvoiceExpirationFeeModel } from '../models/api/invoice-expiration-fee.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';

export enum InvoiceExpirationFeeActionTypes {
  GetInvoiceExpiration = 'invoiceExpirationFee/GET_REQUEST',
  GetInvoiceExpirationAdmin = 'invoiceExpirationFeeAdmin/GET_Admin_REQUEST',
  GetInvoiceExpirationSuccess = 'invoiceExpirationFee/GET_SUCCESS',
  GetInvoiceExpirationError = 'invoiceExpirationFee/GET_ERROR',
}

export const getInvoiceExpirationFeeAction = createAction(
  InvoiceExpirationFeeActionTypes.GetInvoiceExpiration,
  props<{ fiatMinutes: number }>()
);

export const getInvoiceExpirationFeeAdminAction = createAction(
  InvoiceExpirationFeeActionTypes.GetInvoiceExpirationAdmin,
  props<{ fiatMinutes: number; merchantHashId: string }>()
);

export const getInvoiceExpirationFeeSuccessAction = createAction(
  InvoiceExpirationFeeActionTypes.GetInvoiceExpirationSuccess,
  props<{ invoiceFee: InvoiceExpirationFeeModel }>()
);

export const getInvoiceExpirationFeeErrorAction = createAction(
  InvoiceExpirationFeeActionTypes.GetInvoiceExpirationError,
  props<{ errors: ErrorModel[] }>()
);
