import { createReducer, on } from '@ngrx/store';
import { ANY_FILTER_VALUE } from '../../constants';
import { getSettlementPageFiltersAction, updateSettlementPageFiltersAction } from './settlement-page-filters.actions';
import { SettlementPageFiltersModel } from './settlement-page-filters.model';
import { Time } from '../../enums/time.enum';

export type SettlementPageFiltersState = SettlementPageFiltersModel;

export const SETTLEMENT_PAGE_FILTERS_ANY_STATE: SettlementPageFiltersState = {
  currency: ANY_FILTER_VALUE,
  status: ANY_FILTER_VALUE,
  createdAt: {
    type: ANY_FILTER_VALUE,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const SETTLEMENT_PAGE_FILTERS_INITIAL_STATE: SettlementPageFiltersState = {
  currency: ANY_FILTER_VALUE,
  status: ANY_FILTER_VALUE,
  createdAt: {
    type: Time.MONTH,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const settlementPageFiltersReducer = createReducer(
  SETTLEMENT_PAGE_FILTERS_INITIAL_STATE,
  on(getSettlementPageFiltersAction, (state) => ({
    ...state,
  })),
  on(updateSettlementPageFiltersAction, (state, { filters }) => ({
    ...filters,
  }))
);
