import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import {
  getSettlementMethodWizardAction,
  getSettlementMethodWizardErrorAction,
  getSettlementMethodWizardSuccessAction,
} from './settlement-method-wizard.actions';
import { SETTLEMENT_METHODS_ENDPOINT } from '../settlement-method/settlement-method-api.service';
import { ADMIN_ENDPOINT_PREFIX } from '../../constants';

@Injectable()
export class SettlementMethodWizardEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSettlementMethodWizardAction),
      mergeMap(({ id, isAdmin }) =>
        this.api.get(this.apiUrl(id, isAdmin)).pipe(
          map((settlementMethod) => getSettlementMethodWizardSuccessAction({ settlementMethod: settlementMethod })),
          catchError((errors) => observableOf(getSettlementMethodWizardErrorAction(errors)))
        )
      )
    )
  );

  apiUrl(id: string, isAdmin: boolean): string {
    if (isAdmin) {
      return `/${ADMIN_ENDPOINT_PREFIX}${SETTLEMENT_METHODS_ENDPOINT}/${id}`;
    }
    return `${SETTLEMENT_METHODS_ENDPOINT}/${id}`;
  }

  constructor(private actions$: Actions, private api: ApiService) {}
}
