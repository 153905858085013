import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../services/api.service';
import {
  getConfigurationListAction,
  getConfigurationListErrorAction,
  getConfigurationListSuccessAction,
  reloadConfigurationListAction,
  updateConfigurationItemAction,
  updateConfigurationItemErrorAction,
  updateConfigurationItemSuccessAction,
} from '../actions/configuration-list.actions';

export const CONFIGURATION_ENDPOINT = '/admin/configuration';

@Injectable()
export class ConfigurationListEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConfigurationListAction, reloadConfigurationListAction),
      mergeMap((action) =>
        this.api.get(CONFIGURATION_ENDPOINT).pipe(
          map((configurationList) => getConfigurationListSuccessAction({ configurationList })),
          catchError((errors) => observableOf(getConfigurationListErrorAction(errors)))
        )
      )
    )
  );

  updateConfigItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConfigurationItemAction),
      mergeMap(({ configurationItem }) =>
        this.api.put(CONFIGURATION_ENDPOINT, configurationItem).pipe(
          map((response) => updateConfigurationItemSuccessAction()),
          catchError((errors) => observableOf(updateConfigurationItemErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
