import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService, get2FaHeaders } from '../../services/api.service';
import { of as observableOf } from 'rxjs';
import {
  deleteUserAction,
  deleteUserErrorAction,
  deleteUserSuccessAction,
  getUserListAction,
  getUserListErrorAction,
  getUserListSuccessAction,
  resendActivationEmailAction,
  resendActivationEmailErrorAction,
  resendActivationEmailSuccessAction,
  updateUserRoleAction,
  updateUserRoleErrorAction,
  updateUserRoleSuccessAction,
} from './user-list.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ADMIN_ENDPOINT_PREFIX } from '../../constants';

export const MERCHANT_USER_LIST_ENDPOINT = `/users`;
export const USER_LIST_ADMIN_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/users`;

@Injectable()
export class UserListEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserListAction),
      mergeMap(() =>
        this.api.get(MERCHANT_USER_LIST_ENDPOINT).pipe(
          map((users) => getUserListSuccessAction({ users })),
          catchError((errors) => observableOf(getUserListErrorAction(errors)))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserAction),
      mergeMap(({ user }) =>
        this.api.delete(`${MERCHANT_USER_LIST_ENDPOINT}/${user.id}`).pipe(
          map(() => deleteUserSuccessAction({ user: user })),
          catchError((errors) => observableOf(deleteUserErrorAction(errors)))
        )
      )
    )
  );

  resendActivationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendActivationEmailAction),
      switchMap(({ user }) =>
        this.api.post(`${MERCHANT_USER_LIST_ENDPOINT}/${user.id}/activation-resend`).pipe(
          map(() => resendActivationEmailSuccessAction({ user: user })),
          catchError((errors) => observableOf(resendActivationEmailErrorAction(errors)))
        )
      )
    )
  );

  updateUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserRoleAction),
      switchMap(({ role, id, twoFaCode }) =>
        this.api.patch(`${MERCHANT_USER_LIST_ENDPOINT}/${id}/role`, { role: role }, get2FaHeaders(twoFaCode)).pipe(
          map((user) => updateUserRoleSuccessAction({ user })),
          catchError((errors) => observableOf(updateUserRoleErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
