import { SettlementMethodTo } from '../../models/api/settlement-method.model';
import { StateModel } from '../../models/auxiliary/state.model';
import { WizardModel } from '../../models/auxiliary/wizard.model';
import {
  clearDataSettlementMethodWizardAction,
  getSettlementMethodWizardSuccessAction,
  patchDataSettlementMethodWizardAction,
  setStepSettlementMethodWizardAction,
} from './settlement-method-wizard.actions';
import { createReducer, on } from '@ngrx/store';

export type SettlementMethodWizardState = StateModel<WizardModel<SettlementMethodTo>>;

const INITIAL_STATE: SettlementMethodWizardState = {
  errors: null,
  data: {
    step: 0,
    data: {} as SettlementMethodTo,
  },
};

export const settlementMethodWizardReducer = createReducer(
  INITIAL_STATE,
  on(getSettlementMethodWizardSuccessAction, (state, { settlementMethod }) => ({
    errors: null,
    data: {
      data: settlementMethod,
      step: 1,
    },
  })),
  on(setStepSettlementMethodWizardAction, (state, { step }) => ({
    errors: null,
    data: {
      data: state.data.data,
      step: step,
    },
  })),
  on(clearDataSettlementMethodWizardAction, () => ({
    ...INITIAL_STATE,
  })),
  on(patchDataSettlementMethodWizardAction, (state, { settlementMethod }) => ({
    errors: null,
    data: {
      ...state.data,
      data: settlementMethod,
    },
  }))
);
