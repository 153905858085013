<div class="wizard-step__header">
  <h2 class="bp-heading">Merchant verification</h2>
</div>
<div class="verification-card">
  <bp-personal-account-verification-form
    *ngIf="selectedType === 'PERSON'"
    [countries]="countries"
    (nextStep)="this.performNextStep()"
  ></bp-personal-account-verification-form>
  <bp-company-account-verification-form
    *ngIf="selectedType === 'COMPANY'"
    [countries]="countries"
    (nextStep)="this.performNextStep()"
  ></bp-company-account-verification-form>
</div>
