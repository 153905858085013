<div class="back-arrow__container">
  <mat-icon class="back-arrow__button" (click)="stepBack()">keyboard_backspace</mat-icon>
</div>
<div class="wizard-step__header">
  <h1 class="bp-heading method-selector-heading">Select verification method</h1>
  <p class="wizard-step__legend">
    Dear {{ nameToDisplay }}, we recommend you to use the mobile App for the best result
  </p>
</div>

<div class="method-selector-buttons">
  <div class="method-selector-button" (click)="setMethodApp()">
    <img src="assets/idenfy/app.svg" alt="Download app icon" class="method-selector-icon" />
    <div class="method-selector-icon-text">iDenfy App</div>
  </div>
  <div class="method-selector-button" (click)="setMethodBrowser()">
    <img src="assets/idenfy/web.svg" alt="Open in browser icon" class="method-selector-icon" />
    <div class="method-selector-icon-text">Browser</div>
  </div>
</div>
