/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { CryptoAddressTo } from '../models/api/crypto-address.model';
import { ErrorModel } from '../models/api/error.model';

export enum CryptoAddressActionTypes {
  CryptoAddressVerifyRequest = 'addressStats/POST_VERIFY_REQUEST',
  CryptoAddressVerifySuccess = 'addressStats/POST_VERIFY_SUCCESS',
  CryptoAddressVerifyError = 'addressStats/POST_VERIFY_ERROR',
}

export const cryptoAddressVerifyRequestAction = createAction(
  CryptoAddressActionTypes.CryptoAddressVerifyRequest,
  props<{ cryptoAddressModel: CryptoAddressTo }>()
);

export const cryptoAddressVerifySuccessAction = createAction(
  CryptoAddressActionTypes.CryptoAddressVerifySuccess,
  props<{ isValidCryptoAddress: boolean }>()
);

export const cryptoAddressVerifyErrorAction = createAction(
  CryptoAddressActionTypes.CryptoAddressVerifyError,
  props<{ errors: ErrorModel[] }>()
);
