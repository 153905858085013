import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { API_VERSION } from '../../constants';
import { Observable } from 'rxjs';
import { SubscriptionModel } from './subscription.model';
import { map } from 'rxjs/operators';
import { PageRequestModel } from '../../models/auxiliary/page-request.model';
import { PagingModel } from '../../models/auxiliary/paging.model';
import { queryParams } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  public readonly baseUrl: string;
  public readonly subscriptionDetailUrl: string;
  public readonly subscriptionEnabledInfoUrl: string = '/merchant/subscriptions-enabled';

  private readonly api = inject(ApiService);

  constructor() {
    this.baseUrl = `/${API_VERSION}/subscriptions`;
    this.subscriptionDetailUrl = `${this.baseUrl}/{subscriptionId}`;
  }

  public getDetail(id: string): Observable<SubscriptionModel> {
    const url = this.subscriptionDetailUrl.replace('{subscriptionId}', id);
    return this.api.get(url).pipe(map((subscription: SubscriptionModel) => subscription));
  }

  public getList(pagination: PageRequestModel, filters: any): Observable<PagingModel<SubscriptionModel>> {
    const params: any = { ...pagination, ...filters };
    const url = this.baseUrl + queryParams(params);

    return this.api.get(url);
  }

  public getSubscriptionEnabledInfo() {
    return this.api.get(this.subscriptionEnabledInfoUrl);
  }
}
