import { StateModel } from '../../models/auxiliary/state.model';
import { SettlementModel } from '../../models/api/settlement.model';
import { PagingModel } from '../../models/auxiliary/paging.model';
import { createReducer, on } from '@ngrx/store';
import {
  getSettlementPageErrorAction,
  getSettlementPageSuccessAction,
  putSettlementsErrorAction,
} from './settlement-page.actions';

export type SettlementPageState = StateModel<PagingModel<SettlementModel>>;

const INITIAL_STATE: SettlementPageState = {
  errors: null,
};

export const settlementPageReducer = createReducer(
  INITIAL_STATE,
  on(getSettlementPageSuccessAction, (state, { settlements }) => ({
    errors: null,
    data: settlements,
  })),
  on(getSettlementPageErrorAction, putSettlementsErrorAction, (state, { errors }) => ({
    errors: errors,
  }))
);
