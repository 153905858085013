import {
  updateSettlementMethodNotificationAction,
  updateSettlementMethodNotificationErrorAction,
  updateSettlementMethodNotificationSuccessAction,
} from './settlement-method-notification.actions';
import { SettlementMethodTo } from '../../models/api/settlement-method.model';
import { StateModel } from '../../models/auxiliary/state.model';
import { createReducer, on } from '@ngrx/store';

export type SettlementMethodState = StateModel<SettlementMethodTo>;

export const initialState: SettlementMethodState = {
  errors: null,
  data: null,
  loading: false,
};

export const settlementMethodNotificationsReducer = createReducer(
  initialState,
  on(updateSettlementMethodNotificationAction, (state) => ({
    ...initialState,
    loading: true,
  })),
  on(updateSettlementMethodNotificationSuccessAction, (state, { updatedMethod }) => ({
    ...state,
    errors: null,
    data: updatedMethod,
    loading: false,
  })),
  on(updateSettlementMethodNotificationErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
