/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { AuditLogEventModel } from '../../../models/api/audit-log-event.model';
import { ErrorModel } from '../../../models/api/error.model';

export enum AuditLogEventsTypes {
  GetAuditLogEvent = 'auditLogEvents/GET_REQUEST',
  GetAuditLogEventSuccess = 'auditLogEvents/GET_SUCCESS',
  GetAuditLogEventError = 'auditLogEvents/GET_ERROR',
}

export const getAuditLogEventsAction = createAction(AuditLogEventsTypes.GetAuditLogEvent);

export const getAuditLogEventsSuccessAction = createAction(
  AuditLogEventsTypes.GetAuditLogEventSuccess,
  props<{ auditLogEventsResponse: AuditLogEventModel[] }>()
);

export const getAuditLogEventsErrorAction = createAction(
  AuditLogEventsTypes.GetAuditLogEventError,
  props<{ errors: ErrorModel[] }>()
);
