import { LedgerModel } from '../models/api/ledger.model';
import { StateModel } from '../models/auxiliary/state.model';
import {
  getLedgerAction,
  getLedgerAsAdminAction,
  getLedgerErrorAction,
  getLedgerForSettlementAction,
  getLedgerSuccessAction,
} from '../actions/ledger.actions';
import { PagingModel } from '../models/auxiliary/paging.model';
import { createReducer, on } from '@ngrx/store';

export type LedgerState = StateModel<PagingModel<LedgerModel>>;

const INITIAL_STATE: LedgerState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
  loading: true,
};

export const ledgerReducer = createReducer(
  INITIAL_STATE,
  on(getLedgerAction, getLedgerForSettlementAction, getLedgerAsAdminAction, (state) => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(getLedgerSuccessAction, (state, { ledger }) => ({
    ...state,
    data: ledger,
    loading: false,
  })),
  on(getLedgerErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
