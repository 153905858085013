import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import { queryParams } from '../../utils';
import { SETTLEMENT_ENDPOINT } from '../settlement/settlement.effect';
import { ADMIN_ENDPOINT_PREFIX, API_VERSION } from '../../constants';
import {
  getSettlementPageAction,
  getSettlementPageErrorAction,
  getSettlementPageSuccessAction,
  putSettlementsAction,
  putSettlementsErrorAction,
  putSettlementsSuccessAction,
  performSettlementAction,
  performSettlementActionError,
  performSettlementActionSuccess,
} from './settlement-page.actions';

@Injectable()
export class SettlementPageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSettlementPageAction),
      mergeMap(({ paging, filters, isAdmin }) => {
        return this.api.get(this.url({ ...paging, ...filters }, isAdmin)).pipe(
          map((settlements) => getSettlementPageSuccessAction({ settlements })),
          catchError((errors) => observableOf(getSettlementPageErrorAction(errors)))
        );
      })
    )
  );

  performSettlementAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performSettlementAction),
      switchMap(({ settlementId, actionId }) =>
        this.api.patch(`/${ADMIN_ENDPOINT_PREFIX}${SETTLEMENT_ENDPOINT}/${settlementId}/action/${actionId}`).pipe(
          map((response) => performSettlementActionSuccess({ settlementId: settlementId })),
          catchError((errors) =>
            observableOf(
              performSettlementActionError({
                ...errors,
                settlementId: settlementId,
              })
            )
          )
        )
      )
    )
  );

  putSettlements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putSettlementsAction),
      mergeMap(({ settlementsSend }) =>
        this.api.put(`/${ADMIN_ENDPOINT_PREFIX}${SETTLEMENT_ENDPOINT}/send`, settlementsSend).pipe(
          map((response) => putSettlementsSuccessAction()),
          catchError((errors) => observableOf(putSettlementsErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}

  url(params: any, isAdmin: boolean): string {
    if (isAdmin) {
      return `/${ADMIN_ENDPOINT_PREFIX}${SETTLEMENT_ENDPOINT}${queryParams(params)}`;
    }
    return `/${API_VERSION}${SETTLEMENT_ENDPOINT}${queryParams(params)}`;
  }
}
