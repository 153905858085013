import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';
import {
  getMerchantPageAction,
  getMerchantPageErrorAction,
  getMerchantPageSuccessAction,
} from '../actions/merchant-page.actions';

export const MERCHANT_ENDPOINT = '/admin/merchants';

@Injectable()
export class MerchantPageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantPageAction),
      mergeMap(({ paging }) =>
        this.api.get(`${MERCHANT_ENDPOINT}${queryParams({ ...paging })}`).pipe(
          map((merchants) => getMerchantPageSuccessAction({ merchants })),
          catchError((errors) => observableOf(getMerchantPageErrorAction(errors)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
