import { createReducer, on } from '@ngrx/store';
import {
  getPaymentButtonListFiltersAction,
  updatePaymentButtonListFiltersAction,
} from './payment-button-list-filters.actions';
import { PaymentButtonListFiltersModel } from './payment-button-list-filters.model';
import { ANY_FILTER_VALUE } from '../../constants';
import { Time } from '../../enums/time.enum';

export type PaymentButtonListFiltersState = PaymentButtonListFiltersModel;

export const PAYMENT_BUTTON_LIST_FILTERS_ANY_STATE: PaymentButtonListFiltersState = {
  createdAt: {
    type: ANY_FILTER_VALUE,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const PAYMENT_BUTTON_LIST_FILTERS_INITIAL_STATE: PaymentButtonListFiltersState = {
  createdAt: {
    type: Time.MONTH,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const paymentButtonListFiltersReducer = createReducer(
  PAYMENT_BUTTON_LIST_FILTERS_INITIAL_STATE,
  on(getPaymentButtonListFiltersAction, (state) => ({
    ...state,
  })),
  on(updatePaymentButtonListFiltersAction, (state, { filters }) => ({
    ...filters,
  }))
);
