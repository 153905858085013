import { CustomerEmailModel } from '../models/api/customer-email.model';
import { StateModel } from '../models/auxiliary/state.model';
import {
  getCustomerEmailAction,
  getCustomerEmailErrorAction,
  getCustomerEmailSuccessAction,
  postCustomerEmailAction,
  postCustomerEmailErrorAction,
  postCustomerEmailSuccessAction,
} from '../actions/customer-email.actions';
import { createReducer, on } from '@ngrx/store';

export type CustomerEmailState = StateModel<CustomerEmailModel>;

const INITIAL_STATE: CustomerEmailState = {
  errors: null,
  data: null,
  initial: true,
};

export const customerEmailReducer = createReducer(
  INITIAL_STATE,
  on(postCustomerEmailSuccessAction, (state) => ({
    errors: null,
    data: null,
    initial: false,
  })),
  on(postCustomerEmailAction, getCustomerEmailAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getCustomerEmailSuccessAction, (state, { customerEmailResponse }) => ({
    errors: null,
    data: {
      customerEmail: null,
      canSave: customerEmailResponse,
    },
    initial: false,
  })),
  on(postCustomerEmailErrorAction, getCustomerEmailErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    initial: false,
  }))
);
