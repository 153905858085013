import { StateModel } from '../models/auxiliary/state.model';
import { CallbackPasswordModel } from '../models/api/callback-password.model';
import {
  createCallbackPasswordClearAction,
  createCallbackPasswordErrorAction,
  createCallbackPasswordSuccessAction,
} from '../actions/callback-password-create.actions';
import { createReducer, on } from '@ngrx/store';

export type CallbackPasswordCreateState = StateModel<CallbackPasswordModel>;

const INITIAL_STATE: CallbackPasswordCreateState = {
  errors: null,
};

export const callbackPasswordCreateReducer = createReducer(
  INITIAL_STATE,
  on(createCallbackPasswordSuccessAction, (state, { callbackPassword }) => ({
    errors: null,
    data: callbackPassword,
  })),
  on(createCallbackPasswordErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
  on(createCallbackPasswordClearAction, (state) => ({
    ...INITIAL_STATE,
  }))
);
