/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { NotificationSettingsModel } from '../models/api/notification-settings.model';
import { ErrorModel } from '../models/api/error.model';

export enum NotificationSettingsActionTypes {
  GetNotificationSettings = 'notificationSettings/GET_REQUEST',
  GetNotificationSettingsSuccess = 'notificationSettings/GET_SUCCESS',
  GetNotificationSettingsError = 'notificationSettings/GET_ERROR',
  UpdateNotificationSettings = 'notificationSettings/UPDATE_REQUEST',
  UpdateNotificationSettingsSuccess = 'notificationSettings/UPDATE_SUCCESS',
  UpdateNotificationSettingsError = 'notificationSettings/UPDATE_ERROR',
}

export const getNotificationSettingsAction = createAction(
  NotificationSettingsActionTypes.GetNotificationSettings,
  props<{ merchantId?: string }>()
);

export const getNotificationSettingsSuccessAction = createAction(
  NotificationSettingsActionTypes.GetNotificationSettingsSuccess,
  props<{ notificationSettings: NotificationSettingsModel }>()
);

export const getNotificationSettingsErrorAction = createAction(
  NotificationSettingsActionTypes.GetNotificationSettingsError,
  props<{ errors: ErrorModel[] }>()
);

export const updateNotificationSettingsAction = createAction(
  NotificationSettingsActionTypes.UpdateNotificationSettings,
  props<{ merchantId?: string; notificationSettings: NotificationSettingsModel }>()
);

export const updateNotificationSettingsSuccessAction = createAction(
  NotificationSettingsActionTypes.UpdateNotificationSettingsSuccess,
  props<{ notificationSettings: NotificationSettingsModel }>()
);

export const updateNotificationSettingsErrorAction = createAction(
  NotificationSettingsActionTypes.UpdateNotificationSettingsError,
  props<{ errors: ErrorModel[] }>()
);
